import { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { FaApple } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi'

import { useBackLogin } from '../_components/useBackLogin'
import Container from '@/components/container'
import { Input } from '@/components/input'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'

import { useLogin } from '../../../../hooks/API/autenticação/useLogin'

import {
  CadastroAlunoSchema,
  useAlunoCadastroPrimeiroAcesso,
} from '@/hooks/API/aluno/useCadastroPrimeiroAcesso'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { Loader2 } from 'lucide-react'
import Swal from 'sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

import './SwallCustom.css'
interface loginprops {
  email: string
  senha: string
}
export const PrimeiroAcessoCadastroPágina = () => {
  const [seePassword, setSeePassword] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false)
  const { backLogin } = useBackLogin()
  const [dataLogin, setDataLogin] = useState<loginprops>()
  const {
    cadastroAluno,
    cadastroAlunoContext,
    cadastroAlunoIsLoading,
    cadastroAlunoStatus,
  } = useAlunoCadastroPrimeiroAcesso()
  const { handleSubmit, control, watch, setValue } = cadastroAlunoContext
  const { login } = useLogin()
  const storage = new LocalStorage()

  const callCadastrar: SubmitHandler<CadastroAlunoSchema> = (data) => {
    Swal.fire({
      title: ' Você tem certeza que os dados estão corretos ?',
      showDenyButton: true,
      width: 400,
      text: 'Ao confirmar os dados enviados não será possível muda-los, o que pode te impedir de prosseguir com seu cadastro, sendo assim, se não tiver certeza que os dados de e-mail e senha foram corretamente preenchidos, corrija-os e prossiga.',
      confirmButtonText: 'Confirmar e enviar',
      denyButtonText: `Revisar dados`,
      color: '#fff',
      background: '#171717',
      confirmButtonColor: '#BBCF00',
      customClass: {
        denyButton: 'btn-color',
        title: 'title-color',
        confirmButton: 'text-color',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        cadastroAluno(data)
        setDataLogin({ email: data.email, senha: data.senha })
      } else if (result.isDenied) return null
    })
  }

  useEffect(() => {
    setValue('matricula', storage.get('matricula') || ' ')
    setValue('instituicaoId', storage.get('instituicoes') || ' ')
  }, [])

  useEffect(() => {
    if (cadastroAlunoStatus === 'success' && dataLogin) {
      login(dataLogin)
    }
  }, [cadastroAlunoStatus])

  return (
    <>
      <Container className='flex min-h-svh flex-col items-center justify-center gap-10 bg-esforce-black-200 px-5 py-8'>
        <hgroup className='flex flex-col items-center justify-center gap-5'>
          <img
            alt='logo da esforce na cor verde'
            src='../logo-colour.svg'
            className='h-9 w-40'
          />
          <p className='font-archivo text-2xl font-semibold text-esforce-pink-100'>
            Primeiro acesso
          </p>
          <p className='flex flex-col text-start font-inter text-sm font-normal leading-6'>
            * Por favor, cadastre um e-mail válido e uma senha segura. O e-mail
            será utilizado para acessar sua conta posteriormente e também para
            recuperação de senha, caso você a perca.
          </p>
        </hgroup>

        <Form {...cadastroAlunoContext}>
          <form onSubmit={handleSubmit(callCadastrar)} className='w-full'>
            <fieldset className='flex flex-col gap-4'>
              <Input
                control={control}
                registerName='email'
                textlabel='Email'
                placeholder='Digite seu email'
                type='text'
              />

              <div className='relative'>
                <Input
                  control={control}
                  registerName='senha'
                  textlabel='Senha'
                  placeholder='******'
                  type={!seePassword ? 'password' : 'text'}
                />

                <button
                  type='button'
                  className='absolute right-5 top-11 cursor-pointer'
                  onClick={() => setSeePassword((prev) => !prev)}
                >
                  {!seePassword ? (
                    <PiEyeLight size={24} />
                  ) : (
                    <PiEyeSlash size={24} />
                  )}
                </button>
              </div>

              <div className='relative'>
                <Input
                  disabled={!watch('senha')}
                  control={control}
                  registerName='confirmacaoSenha'
                  textlabel='Confirmar senha'
                  placeholder='******'
                  type={!confirmPassword ? 'password' : 'text'}
                />

                <button
                  type='button'
                  className='absolute right-5 top-11 cursor-pointer'
                  onClick={() => setConfirmPassword((prev) => !prev)}
                  disabled={!watch('senha')}
                >
                  {!confirmPassword ? (
                    <PiEyeLight size={24} />
                  ) : (
                    <PiEyeSlash size={24} />
                  )}
                </button>
              </div>
            </fieldset>

            <Button
              size='xl'
              type='submit'
              disabled={cadastroAlunoIsLoading}
              className='mt-7 w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
            >
              {cadastroAlunoIsLoading ? (
                <>
                  <Loader2 className='animate-spin' />
                </>
              ) : (
                'Verificar matrícula'
              )}
            </Button>
          </form>

          <div className='mt-8 hidden w-full items-center justify-center font-rubik'>
            <Separator className='flex-1 bg-esforce-grey-100' />
            <p className='flex-1 text-center text-sm font-normal text-esforce-grey-100'>
              {' '}
              ou{' '}
            </p>
            <Separator className='flex-1 bg-esforce-grey-100' />
          </div>
          <div className='hidden w-full gap-4 font-rubik'>
            <Button
              size='xl'
              className='flex w-full items-center gap-2 bg-esforce-black-350 text-base text-esforce-white-100'
            >
              <FcGoogle size={32} /> | <span> Google </span>
            </Button>
            <Button
              size='xl'
              className='text-esforce-white-10 flex w-full items-center gap-2 bg-esforce-black-350 text-base'
            >
              <FaApple size={32} /> | <span> Apple </span>
            </Button>
          </div>
        </Form>

        <div className='flex w-full flex-col items-center justify-center gap-4 text-center font-archivo text-sm font-normal text-esforce-grey-100'>
          <p>
            {' '}
            Já possui uma conta?{' '}
            <button
              type='button'
              onClick={backLogin}
              className='text-esforce-green-200'
            >
              {' '}
              Realizar login{' '}
            </button>
          </p>
        </div>
      </Container>
    </>
  )
}
