import { useForm } from 'react-hook-form'

import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useAtualizarSenha = () => {
  const {
    mutate: atualizarSenha,
    isLoading: atualizarSenhaIsLoading,
    status: atualizarSenhaStatus,
  } = useMutation(endPoint, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['me'])
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const atualizarSenhaContext = useForm<AtualizarSenhaSchemaType>({
    resolver: zodResolver(atualizarSenhaSchema),
    reValidateMode: 'onBlur',
  })

  return {
    atualizarSenhaContext,
    atualizarSenha,
    atualizarSenhaIsLoading,
    atualizarSenhaStatus,
  }
}

async function endPoint(data: AtualizarSenhaSchemaType): Promise<void> {
  const result = await api().put(rotasApi.alunoAtualizarSenha, data)
  return result.data.data
}

const atualizarSenhaSchema = z
  .object({
    senhaAtual: z
      .string({ message: 'campo obrigatório' })
      .min(1, { message: 'Campo obrigatório' }),
    senhaNova: z
      .string({ message: 'campo obrigatório' })
      .min(6, { message: 'Minimo 6 caracters' }),
    confirmarSenha: z.string().optional(),
  })
  .refine((data) => data.senhaNova === data.confirmarSenha, {
    message: 'Senhas não conferem',
    path: ['confirmarSenha'],
  })

export type AtualizarSenhaSchemaType = z.infer<typeof atualizarSenhaSchema>
