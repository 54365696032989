import { api } from '@/api/api'
import { useQuery } from '@tanstack/react-query'

export const useVerifyQuizResposta = ({
  quizId,
}: useVerifyQuizRespostaAtributos) => {
  const { data: verifyQuizResposta } = useQuery({
    queryKey: ['quiz-acerto-resposta'],
    queryFn: async () => endPoint({ quizId }),
  })

  return { verifyQuizResposta }
}

async function endPoint({
  quizId,
}: useVerifyQuizRespostaAtributos): Promise<ResposeProps> {
  const result = await api().get(
    `/autenticado/verificar-respostas-quiz/${quizId}`,
  )
  return result.data.data
}

interface useVerifyQuizRespostaAtributos {
  quizId?: string
}
interface ResposeProps {
  respostasCorretas: number
  respostasIncorretas: number
  respostasNulas: number
}
