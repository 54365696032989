/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { RiTimerLine } from 'react-icons/ri'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { UseModalFinishQuiz } from '../../../../components/modal-ui/UseModalFinishQuiz'
import { Button } from '../../../../components/ui/button'
import { Label } from '../../../../components/ui/label'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Progress } from '@/components/ui/progress'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

import { useQuiz } from '../../../../hooks/API/quiz/useQuiz'
import { QuizLoadingPergunta } from './QuizLoadingPergunta'
import { useTimeResposta } from './useTimeResposta'
import { useVerifyQuizResposta } from './useVerifyQuizRespostas'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { useQuizRespostaCriar } from '@/hooks/API/quizResposta/useQuizRespostaCriar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { X } from '@phosphor-icons/react'
import { Loader2 } from 'lucide-react'

export const QuizPágina = () => {
  const [searchBarParams] = useSearchParams()
  const quizId = searchBarParams.get('quizId') || undefined
  const { quiz } = useQuiz({ id: quizId || ' ' })
  useContextoOutletLayoutComNavegação({
    título: quiz ? quiz.tema : '',
    isBotãoEsquerdo: true,
    íconeDireita: X,
  })
  const { UiModal, onOpen } = UseModalFinishQuiz()

  const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const navigate = useNavigate()

  const {
    tempoResposta,
    quizTimeResposta,
    isLoadingQuizTimeResposta,
    tempoRespostaInterval,
    setTempoResposta,
  } = useTimeResposta({ quizId })

  const {
    quizRespostasContext,
    enviarRespostasQuiz,
    isLoadingEnviarRespostasQuiz,
    statusEnviarRespostasQuiz,
  } = useQuizRespostaCriar()

  const { setValue, handleSubmit, control } = quizRespostasContext

  const onSubmit: SubmitHandler<any> = (data) => {
    enviarRespostasQuiz(data)
  }

  useEffect(() => {
    if (!quizTimeResposta) return
    setValue('perguntaId', quizTimeResposta?.pergunta.id)
  }, [JSON.stringify(quizTimeResposta)])

  const { verifyQuizResposta } = useVerifyQuizResposta({ quizId })

  useEffect(() => {
    if (statusEnviarRespostasQuiz === 'success') {
      if (
        quizTimeResposta?.pergunta.indice === quizTimeResposta?.pergunta.total
      ) {
        tempoRespostaInterval.current &&
          clearInterval(
            tempoRespostaInterval.current as unknown as NodeJS.Timeout,
          )
        setTempoResposta(-1)
        if (verifyQuizResposta === undefined) {
          navigate(0)
        }
      } else {
        navigate(0)
      }
    }
  }, [statusEnviarRespostasQuiz])

  useEffect(() => {
    function callModal() {
      if (verifyQuizResposta !== undefined) {
        if (quiz) {
          return onOpen()
        }
      }
    }
    callModal()
  }, [verifyQuizResposta])

  if (
    verifyQuizResposta !== undefined &&
    (!quizTimeResposta || !quiz?.Perguntas)
  ) {
    return (
      <UiModal callbackBotão={() => navigate(rotasApp.início)}>
        <hgroup className='flex flex-col items-center text-center'>
          <h2 className='font-dm-sans text-xl font-semibold text-esforce-white-100'>
            Quiz Finalizado!
          </h2>
          <p className='font-dm-sans text-xs font-normal text-esforce-white-100'>
            Você acertou{' '}
            <span className='font-bold text-esforce-green-200'>
              {verifyQuizResposta?.respostasCorretas}/
              {quiz?.Perguntas?.length || 0}
            </span>
          </p>
          <p className='font-dm-sans text-xs font-normal text-esforce-white-100'>
            Em breve você poderá acompanhar o resultado final na aba{' '}
            <span className='font-bold text-esforce-green-200'>
              finalizados
            </span>
          </p>
        </hgroup>
      </UiModal>
    )
  }

  if (!quizTimeResposta) return <QuizLoadingPergunta hidden={false} />

  return (
    <>
      <ContainerAppUi>
        <QuizLoadingPergunta
          hidden={isLoadingQuizTimeResposta || tempoResposta! !== -1}
        />

        <div className='flex flex-col items-start gap-4'>
          <hgroup className='flex w-full flex-col gap-2.5'>
            <div className='flex w-full items-center justify-between'>
              <p className='font-dm-sans text-xs font-normal text-esforce-white-100'>{`${quizTimeResposta.pergunta.indice}/${quizTimeResposta.pergunta.total}`}</p>
              <div className='flex h-7 w-14 items-center justify-center gap-1 rounded-lg bg-esforce-green-200/10'>
                <RiTimerLine size={16} className='text-esforce-green-200' />
                <p className='font-dm-sans text-sm font-semibold text-esforce-green-200'>
                  {tempoResposta! > 0 ? tempoResposta : 0}s
                </p>
              </div>
            </div>
            <Progress
              value={
                (quizTimeResposta.pergunta.indice /
                  quizTimeResposta.pergunta.total) *
                100
              }
              className='h-2.5 w-full'
            />

            {tempoResposta === 0 && (
              <div className='flex items-center justify-center'>
                <p className='mt-1 text-center font-dm-sans font-medium text-esforce-green-200'>
                  Tempo esgotado para essa pergunta, clique em continuar!
                </p>
              </div>
            )}
          </hgroup>
          <Form {...quizRespostasContext}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='flex w-full flex-col gap-6'
            >
              <Label>
                <h2 className='font-dm-sans text-xl font-semibold leading-[30px] text-esforce-white-100'>
                  Pergunta 0{quizTimeResposta.pergunta.indice}
                </h2>
                <p className='mt-1 font-dm-sans text-sm font-normal leading-[21px] text-esforce-white-100'>
                  {quizTimeResposta.pergunta.pergunta}
                </p>
              </Label>

              <fieldset
                className={`${tempoResposta === 0 && 'pointer-events-none opacity-50'} w-full rounded-xl bg-esforce-black-300 p-4`}
              >
                <FormField
                  control={control}
                  name='alternativaId'
                  render={({ field }) => (
                    <FormItem className=''>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          className=''
                        >
                          {quizTimeResposta.pergunta.alternativas
                            .sort()
                            .map((alternativa, index) => (
                              <FormItem
                                className='flex w-full items-start justify-start gap-1.5 rounded-xl p-3'
                                key={alternativa.id}
                              >
                                <FormControl className='relative'>
                                  <RadioGroupItem
                                    value={alternativa.id}
                                    className='size-5 border border-esforce-white-100 data-[state=checked]:bg-esforce-green-200 data-[state=checked]:text-esforce-green-200 [&_svg]:fill-esforce-green-200'
                                  />
                                </FormControl>
                                <FormLabel className='!mt-0 font-dm-sans text-sm font-normal capitalize text-esforce-white-100'>
                                  <strong>{letras.charAt(index)}.</strong>{' '}
                                  {alternativa.alternativa}
                                </FormLabel>
                              </FormItem>
                            ))}
                        </RadioGroup>
                      </FormControl>
                      <div></div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </fieldset>
              <Button
                size='xl'
                type='submit'
                disabled={
                  isLoadingEnviarRespostasQuiz ||
                  statusEnviarRespostasQuiz === 'success'
                }
                className='w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
              >
                {isLoadingEnviarRespostasQuiz ? (
                  <>
                    <Loader2 className='animate-spin' />
                  </>
                ) : quizTimeResposta?.pergunta.indice ===
                  quizTimeResposta?.pergunta.total ? (
                  'Finalizar'
                ) : (
                  'Continuar'
                )}
              </Button>
            </form>
          </Form>
        </div>
      </ContainerAppUi>
    </>
  )
}
