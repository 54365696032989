import { forwardRef } from 'react'

import { EffectCoverflow, Pagination } from 'swiper/modules'

import { gallery } from './data'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore } from 'swiper/types'

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import './styles.css'

type CarouselProps = {
  onSlideChange: (index: number) => void
}

const Carousel = forwardRef<SwiperCore, CarouselProps>(
  ({ onSlideChange }, ref) => {
    const updateBlurEffect = (swiper: any) => {
      swiper.slides.forEach((slide: HTMLElement, index: number) => {
        if (index === swiper.activeIndex) {
          slide.style.filter = 'none' // Remove o blur se necessário
          slide.style.boxShadow = '0 10px 35px rgba(234,57,157,1)'
        } else {
          slide.style.filter = 'blur(4px)'
          slide.style.boxShadow = 'none'
        }
      })
    }

    const handleSwiper = (swiper: SwiperCore) => {
      if (ref && typeof ref === 'object' && ref.current !== undefined) {
        ref.current = swiper
      }
      updateBlurEffect(swiper)
    }

    const handleSlideChange = (swiper: SwiperCore) => {
      onSlideChange(swiper.activeIndex)
      updateBlurEffect(swiper)
    }

    return (
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          scale: 1,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        navigation={false}
        onSwiper={handleSwiper}
        onSlideChange={handleSlideChange}
        modules={[EffectCoverflow, Pagination]}
        spaceBetween={100}
        className='wellcome-carousel'
      >
        {gallery.map((item, index) => (
          <SwiperSlide
            key={index}
            className='rounded-[44px] border border-[rgb(234,57,157)] bg-white'
          >
            <img src={item.url} className='rounded-[44px] shadow-xl' />
          </SwiperSlide>
        ))}
      </Swiper>
    )
  },
)

Carousel.displayName = 'Carousel'
export default Carousel
