import { Link } from 'react-router-dom'

import { PlanoPagamentoModel, PontezaAtributosDTO } from '@/@types/Models'
import moment from 'moment'

export const CardPlanoAdquirido = (props: Props) => {
  return (
    <div
      className='flex h-full w-full flex-col justify-between gap-2 rounded-lg bg-esforce-black-200 p-3'
      key={props.plano.id}
    >
      <div className='flex w-full items-center justify-between self-center'>
        <p className='text-sm font-bold'>
          {props.plano?.InstituicaoPeriodo?.nome}
        </p>
        <p className='ml-2 text-xs font-bold'>
          {moment(props.plano?.PlanoPagamento?.criadoEm)
            .utc()
            .format('DD/MM/YY [ás] HH:mm')}
        </p>
      </div>
      <p
        className='self-center text-sm italic text-esforce-green-200'
        hidden={
          !(
            ['Aguardando pagamento'] as PlanoPagamentoModel['status'][]
          ).includes(
            props.plano?.PlanoPagamento
              ?.status as PlanoPagamentoModel['status'],
          )
        }
      >
        {props.plano?.PlanoPagamento?.status}
      </p>
      <p
        className='self-center text-sm italic text-esforce-white-200'
        hidden={
          !(
            ['Aguardando pagamento'] as PlanoPagamentoModel['status'][]
          ).includes(
            props.plano?.PlanoPagamento
              ?.status as PlanoPagamentoModel['status'],
          )
        }
      >
        Expira em{' '}
        {moment(props.plano?.PlanoPagamento?.expiraEm)
          .utc()
          .format('DD/MM/YY [ás] HH:mm')}
      </p>
      <Link
        to={props.plano.PlanoPagamento?.urlPagamento as string}
        hidden={
          !(
            ['Aguardando pagamento'] as PlanoPagamentoModel['status'][]
          ).includes(
            props.plano?.PlanoPagamento
              ?.status as PlanoPagamentoModel['status'],
          )
        }
      >
        Realizar pagamento
      </Link>
    </div>
  )
}

interface Props {
  plano: PontezaAtributosDTO
  i: number
}
