import { useReforceFavoritar } from '@/hooks/API/reforce/useReforceFavoritar'
import { plural } from '@/utilitários/plural'
import { IoMdHeartEmpty, IoMdHeart } from 'react-icons/io'

export const _BarraInferior = ({ data }: BarraInferiorProps) => {
  const { mutate} = useReforceFavoritar()

  const onSubmit = (reforceId: string) => {
    mutate({ reforceId })
  }
  return (
    <div className='flex items-center gap-2 px-4 font-dm-sans'>
      <div className='cursor-pointer' onClick={() => onSubmit(data.reforceId)}>
        {data.isCurtida ? (
          <IoMdHeart size={24} className='text-red-600' />
        ) : (
          <IoMdHeartEmpty size={24} />
        )}
      </div>
      <p className='text-base font-medium'>
        {data.reforceFavoritos} curtida
        {plural({ total: data.reforceFavoritos, plural: 's' })}
      </p>
    </div>
  )
}
interface BarraInferiorProps {
  data: {
    reforceId: string
    reforceFavoritos: number
    isCurtida: boolean
  }
}
