import { useNavigate } from 'react-router-dom'

import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { rotasApp } from '@/configurações/rotasApp'
import { UseActiveUser } from '@/store/user.store'
import { responseError } from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useLogout = () => {
  const { setUserActive } = UseActiveUser()

  const storage = new LocalStorage()
  const navigate = useNavigate()

  const { refetch } = useQuery({
    queryKey: ['logout'],
    queryFn: logout,
    onSuccess: async () => {
      setUserActive(null)
      storage.remove('token')
      navigate(rotasApp.login)
    },
    onError: (erro: AxiosError) => responseError(erro),
    enabled: false,
  })

  return { logout: refetch }
}

async function logout(): Promise<void> {
  const result = await api().get(rotasApi.logout)
  return result.data.data
}
