import { PiBellBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

import goldCoin from '../../assets/gold-coin.svg'
import { rotasApp } from '../../configurações/rotasApp'
import { useMe } from '../../hooks/API/autenticação/useMe'
import { UseActiveUser } from '../../store/user.store'
import { ContainerAppUi } from '../ContainerAppUi'

import { useNotificacaoCount } from '@/hooks/API/notificacao/useNotificacaoCount'

export const Cabeçalho = () => {
  const { notificacoesCount } = useNotificacaoCount()
  const { userActive } = UseActiveUser()
  const { dataMe } = useMe()
  const navigate = useNavigate()

  const goToPofile = () => {
    navigate(rotasApp.meuPerfil)
  }

  const goToNotifications = () => {
    navigate(rotasApp.notificação)
  }


  return (
    <ContainerAppUi as='header' className='flex bg-esforce-black-100 py-3'>
      <div className='flex flex-1 items-center justify-start gap-2'>
        <Avatar onClick={goToPofile}>
          <AvatarImage src='' alt='perfil usuario' />
          <AvatarFallback className='bg-black'>
            {userActive?.Perfil?.Aluno?.nome?.substring(0, 2)}
          </AvatarFallback>
        </Avatar>
        <div>
          <p className='line-clamp-1 font-dm-sans text-sm font-medium capitalize'>
            {userActive?.Perfil?.Aluno?.nome}
          </p>
          <p className='mt-0.5 font-rubik text-xs font-normal italic'>
            Matricula: {userActive?.Perfil?.Aluno?.matricula}
          </p>
        </div>
      </div>
      <div className='flex flex-1 items-center justify-end gap-2'>
        <div
          className='relative flex size-10 cursor-pointer items-center justify-center rounded-full bg-esforce-black-300'
          onClick={goToNotifications}
        >
          <PiBellBold size={24} className='text-esforce-white-200' />
          {!!notificacoesCount && notificacoesCount > 0 && (
            <span className='absolute right-0 top-0 size-3 rounded-full bg-esforce-pink-100' />
          )}
        </div>
        <div className='flex h-10 items-center justify-center gap-1 rounded-[10px] border border-esforce-green-200 bg-esforce-green-700 px-2 py-4'>
          <img src={goldCoin} alt='gold coin icon' className='size-3' />
          <p className='text-xs font-semibold text-esforce-white-100'>
            {userActive?.Perfil?.Aluno?.saldoEmPontos}
          </p>
        </div>
      </div>
    </ContainerAppUi>
  )
}
