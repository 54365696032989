import { FiPieChart } from 'react-icons/fi'
import { GoArrowRight } from 'react-icons/go'
import { HiOutlineChartBar } from 'react-icons/hi'
import { RiMapPinUserLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import Container from '../../../components/container'
import IconSelector from '../../../components/map-icon'
import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import { Skeleton } from '../../../components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { NotaModel } from '../../../@types/Models'
import { SvgSemDados } from '../../../componentes/SvgSemDados'
import { useContextoOutletLayoutComNavegação } from '../../../hooks/useLayoutComNavegação'
import Metrica from './metrica'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { rotasApp } from '@/configurações/rotasApp'
import { useNotas } from '@/hooks/API/nota/useNotas'
import { UseMenuActive } from '@/hooks/useMenuAtivo'
import { UseActiveUser } from '@/store/user.store'
import { Trophy } from '@phosphor-icons/react'

export interface CardSujectsItemsProps {
  nota: NotaModel
}

export const Materias = () => {
  UseMenuActive('Ranking')
  useContextoOutletLayoutComNavegação({
    título: 'Matérias',
    isBotãoEsquerdo: true,
    íconeDireita: Trophy,
    rotaBotãoDireito: rotasApp.pontos,
  })
  const navigate = useNavigate()

  const { UiModal, onClose } = UseModalUi()

  const { userActive } = UseActiveUser()

  const { notas, isLoading } = useNotas({
    alunoId: userActive?.Perfil?.alunoId ?? '-',
  })

  return (
    <Container className='h-full items-center bg-esforce-black-100'>
      <UiModal
        callbackBotão={() => {
          onClose()
          navigate(rotasApp.início)
        }}
        título='Ops!'
        descrição='Você ainda não possui pontos atribuídos no sistema.'
        textoBotão='Entendido'
      />
      <ContainerAppUi className='flex h-full flex-col gap-8'>
        <Tabs defaultValue='materias' className='w-full'>
          <TabsList className='grid h-auto w-full grid-cols-2 gap-3 rounded-xl bg-esforce-black-300 p-2.5'>
            <TabsTrigger
              className='borde h-10 rounded-xl border border-[#262626] bg-esforce-black-300/70 font-dm-sans text-sm font-semibold text-esforce-white-100 data-[state=active]:border-esforce-green-200 data-[state=active]:bg-esforce-green-200/20 data-[state=active]:text-esforce-green-200'
              value='materias'
            >
              Matérias
            </TabsTrigger>
            <TabsTrigger
              className='borde h-10 rounded-xl border border-[#262626] bg-esforce-black-300/70 font-dm-sans text-sm font-semibold text-esforce-white-100 data-[state=active]:border-esforce-green-200 data-[state=active]:bg-esforce-green-200/20 data-[state=active]:text-esforce-green-200'
              value='desempenho'
            >
              Desempenho
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value='materias'
            className='hidden overflow-y-auto data-[state=active]:block'
          >
            <div className='w-full rounded-xl bg-gradient-to-tr from-esforce-green-200 via-transparent via-50% to-esforce-green-200 p-0.5'>
              <div className='h-full w-full rounded-xl bg-esforce-black-200'>
                <div className='flex w-full flex-col items-center justify-center gap-4 p-5'>
                  {notas?.length === 0 ? (
                    <SvgSemDados />
                  ) : isLoading ? (
                    Array.from({ length: 5 }).map((_, i) => (
                      <CardSkeleton key={`skeleton-${i}`} />
                    ))
                  ) : (
                    notas?.map((nota) => (
                      <CardSubjectsItems nota={nota} key={nota.id} />
                    ))
                  )}
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent
            value='desempenho'
            className='hidden h-[540px] flex-col overflow-y-auto data-[state=active]:block'
          >
            <Metrica />
          </TabsContent>
        </Tabs>
      </ContainerAppUi>
      <Popup />
    </Container>
  )
}

function CardSkeleton() {
  return (
    <div className='flex h-auto w-full flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl p-2.5'>
          <Skeleton className='flex size-11 items-center justify-center rounded-xl bg-esforce-black-300' />
          <div>
            <Skeleton className='mb-1 h-4 w-24 bg-esforce-black-300' />
            <Skeleton className='h-3 w-20 bg-esforce-black-300' />
          </div>
        </div>
        <div className='flex items-end justify-end gap-2 rounded-xl p-3'>
          <Skeleton className='size-10 rounded-full bg-esforce-black-300' />
        </div>
      </div>

      <ul className='flex w-full items-center justify-between gap-1 border-t border-t-esforce-black-300 p-3 font-dm-sans text-sm font-normal text-esforce-white-200'>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-5 w-28 bg-esforce-black-300' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-5 w-28 bg-esforce-black-300' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-5 w-28 bg-esforce-black-300' />
        </li>
      </ul>
    </div>
  )
}

function CardSubjectsItems({ nota }: CardSujectsItemsProps) {
  const navigate = useNavigate()

  return (
    <div className='flex h-auto w-full flex-col items-center justify-center gap-2 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'>
      <div
        className='flex w-full items-center justify-between gap-2'
        onClick={() => {
          navigate(
            rotasApp.materiaVisualizar(nota.materiaId, nota.Materia?.nome),
          )
        }}
      >
        <div className='flex flex-1 items-center gap-2 rounded-xl'>
          <div className='flex size-11 items-center justify-center rounded-xl bg-esforce-pink-100'>
            <IconSelector name={nota.Materia?.nome || ''} />
          </div>
          <div>
            <p className='font-dm-sans text-sm font-semibold text-white'>
              {nota.Materia?.nome}
            </p>
            <p className='font-dm-sans text-xs font-normal text-[#737373]'>
              atividade
            </p>
          </div>
        </div>
        <div className='flex items-end justify-end gap-2 rounded-xl'>
          <button
            type='button'
            className='flex size-10 items-center justify-center rounded-full bg-[#262626]'
            onClick={() => {
              navigate(
                rotasApp.materiaVisualizar(nota.materiaId, nota.Materia?.nome),
              )
            }}
          >
            <GoArrowRight size={20} />
          </button>
        </div>
      </div>

      <ul className='flex w-full items-center justify-between gap-0.5 border-t border-t-esforce-black-300 pt-2.5 font-dm-sans text-xs font-normal text-esforce-white-200'>
        <li className='flex items-center justify-center gap-0.5'>
          <FiPieChart size={16} />
          <span className=''> {1}° Bimestre</span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <HiOutlineChartBar size={16} />
          <span className=''>
            Nota: <strong>{nota.nota}</strong>
          </span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <RiMapPinUserLine size={16} />
          <span className=''>
            Presença: <strong>{nota.presenca}%</strong>
          </span>
        </li>
      </ul>
    </div>
  )
}
