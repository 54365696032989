import { useForm } from 'react-hook-form'

import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useReforceCriarComentario = () => {
  const { mutate, status, isLoading } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
    onSuccess: () => {
      queryClient.invalidateQueries(['premios'])
      queryClient.invalidateQueries(['reforce-visualizar'])
      queryClient.invalidateQueries(['reForce'])
    },
  })

  const criarComentarioContext = useForm<CriarComentatioSchema>({
    resolver: zodResolver(criarComentatioSchema),
    reValidateMode: 'onChange',
  })

  return { mutate, status, isLoading, criarComentarioContext }
}

async function endPoint(data: CriarComentatioSchema): Promise<boolean> {
  const result = await api().post(rotasApi.reforceCriarComentario, data)
  return result.data.data
}

const criarComentatioSchema = z.object({
  reforceId: z.string().optional(),
  comentario: z.string({ message: 'Nao e possivel comentarios em branco' }),
})

export type CriarComentatioSchema = z.infer<typeof criarComentatioSchema>
