import { PrimeiroAcessoCadastroPágina } from './cadastro/PrimeiroAcessoCadastro.página'
import { VerificarMatriculaPagina } from './VerificarMatricula/VerificarMatricula.pagina'

import { RotasPublicRouterAppAtributos } from '@/@types/rotasRouterApp'

export const primeiroAcessoRotasApp = {
  buscarMatricula: `/primeiro-acesso/buscar-matricula`,
  cadastro: `/primeiro-acesso/cadastro`,
}

export const primeiroAcessoRoutes: RotasPublicRouterAppAtributos[] = [
  {
    path: primeiroAcessoRotasApp.buscarMatricula,
    Element: VerificarMatriculaPagina,
  },
  {
    path: primeiroAcessoRotasApp.cadastro,
    Element: PrimeiroAcessoCadastroPágina,
  },
]
