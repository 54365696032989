import { NotaWhereType } from '@/@types/whereModels/NotaWhereType'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useRanking = (condição?: NotaWhereType) => {
  const { data: rankingAluno } = useQuery({
    queryKey: ['rankingAluno'],
    queryFn: async () => rankingAlunoVisualizar(condição),
  })

  return { rankingAluno }
}

async function rankingAlunoVisualizar(
  condição?: NotaWhereType,
): Promise<RankingAlunoProps> {
  const result = await api().get(rotasApi.rankingAlunoVisualizar, {
    headers: { where: JSON.stringify(condição) || '{}' },
  })
  return result.data.data
}

interface RankingAlunoProps {
  turmaPosicao: number
  turmaPorcentagem: number
  escolaPosicao: number
  escolaPorcentagem: number
  cidadePosicao: number
  cidadePorcentagem: number
  todosAlunosPosicao: number
  todosAlunosPorcentagem: number
}
