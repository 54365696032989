import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { VERSAO_BUILD } from '@/configurações/configuraçõesApi'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'

export const SobrePágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Sobre',
    isBotãoEsquerdo: true,
  })

  return (
    <ContainerAppUi>
      <div className='flex flex-col items-center gap-6 font-rubik text-sm text-esforce-white-200'>
        <img src='../logo-colour.svg' alt='Logo' className='w-52' />
        <div>
          <p>Versão atual: {VERSAO_BUILD}</p>
        </div>
        <span>
          © Copyright {new Date().getFullYear()} - Todos os direitos reservados
        </span>
      </div>
    </ContainerAppUi>
  )
}
