import { useEffect } from 'react'

import { ReforceModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useReforceBuscarId = (props: Props) => {
  const {
    data,
    status,
    error,
    hasNextPage,
    fetchNextPage,
    refetch,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['reforce-visualizar'],
    queryFn: async ({ pageParam = '' }) => {
      return await endPoint({
        take: props?.take,
        cursorAtual: pageParam,
        id: props?.id,
      })
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.ultimoCursor : undefined,
  })

  useEffect(() => {
    if (status === 'error') responseError(error as AxiosError)
  }, [status, error, JSON.stringify(data)])

  return { data, status, hasNextPage, fetchNextPage, refetch, isLoading }
}

async function endPoint(params: Props): Promise<EndPointResponse> {
  const result = await api().get(rotasApi.reforceBuscarId(params.id), {
    params: { ...params },
  })
  return result.data.data
}

type PickReforce = Pick<
  ReforceModel,
  | 'id'
  | 'Aluno'
  | 'Instituicao'
  | 'Premio'
  | 'ReforceComentarios'
  | 'descricao'
  | 'criadoEm'
>
type Atributos = {
  data: PickReforce
  isCurtida: boolean
  totalCurtidas: number
  hasNextPage: boolean
  ultimoCursor: string
}

interface Props {
  id?: string
  cursorAtual?: string
  take?: number
}

type EndPointResponse = Atributos
