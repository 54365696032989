import { NotaModel } from '@/@types/Models'
import { NotaWhereType } from '@/@types/whereModels/NotaWhereType'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useNotas = (condição?: NotaWhereType) => {
  const { data: notas, isLoading } = useQuery({
    queryKey: ['notas', condição],
    queryFn: async () => notaListar(condição),
  })

  return { notas, isLoading }
}

async function notaListar(condição?: NotaWhereType): Promise<NotaModel[]> {
  const result = await api().get(rotasApi.notasListar, {
    headers: { where: JSON.stringify(condição) || '{}' },
  })
  return result.data.data
}
