import { useState } from 'react'
import { BiJoystick } from 'react-icons/bi'
import { LiaDotCircleSolid } from 'react-icons/lia'
import { RiTimerLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { UseModalConferirQuizPesquisa } from '../../../components/modal-ui/UseModalConferirQuizPesquisa'
import { Skeleton } from '../../../components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { QuizPesquisaModel } from '../../../@types/Models'
import { SvgSemDados } from '../../../componentes/SvgSemDados'
import { UseMenuActive } from '../../../hooks/useMenuAtivo'
import { useVerifyQuizResposta } from './responderQuiz/useVerifyQuizRespostas'
import {
  ResponseQuizPesquisaListar,
  useQuizPesquisaListar,
} from './useQuizPesquisaListar'

import goldquestion from '@/assets/gold-question.svg'
import sword from '@/assets/sword.svg'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { rotasApp } from '@/configurações/rotasApp'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { ClockCountdown } from '@phosphor-icons/react'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  status?: string
  quizPesquisa: QuizPesquisaModel
}

function CardItems({ status, quizPesquisa, ...rest }: CardProps) {
  const { verifyQuizResposta } = useVerifyQuizResposta({
    quizId: quizPesquisa.id,
  })
  return (
    <div
      className='flex w-full cursor-pointer flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-300 p-3 font-dm-sans'
      {...rest}
    >
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl'>
          <div className='flex size-11 items-center justify-center gap-1 rounded-xl border-2 border-esforce-green-200 bg-esforce-green-700'>
            <img
              src={quizPesquisa.tipo === 'quiz' ? sword : goldquestion}
              alt='iconde de uma espada'
              className='size-5'
            />
          </div>
          <div>
            <p className='font-dm-sans text-sm font-semibold text-white'>
              {quizPesquisa.tema}
            </p>
            <p className='font-dm-sans text-xs font-normal lowercase text-[#737373]'>
              {status}
            </p>
          </div>
        </div>
        <div className='flex items-end justify-end gap-2 rounded-xl'>
          <div className='rounded-2xl border border-esforce-green-200 bg-esforce-green-200/20 px-3 py-1 font-medium text-esforce-green-200'>
            {status === 'DISPONÍVEL' && 'Novo'}
            {status === 'EM_ANDAMENTO' && (
              <>
                ??/
                {quizPesquisa.Perguntas?.length || '0'}
              </>
            )}
            {status === 'FINALIZADO' && (
              <>
                {verifyQuizResposta?.respostasCorretas || '0'}/
                {quizPesquisa.Perguntas?.length || '0'}
              </>
            )}
          </div>
        </div>
      </div>

      <ul className='border-esforce-black-400 flex w-full items-center justify-between gap-0.5 border-t pt-2.5 font-dm-sans text-xs font-normal text-esforce-grey-200'>
      {quizPesquisa.tipo === 'quiz' && (
          <li className='flex items-center justify-center gap-0.5'>
            <RiTimerLine size={16} />
            <span className=''>15 Sec</span>
          </li>
        )}
        <li className='flex items-center justify-center gap-0.5'>
          <BiJoystick size={16} />
          <span className=''>{quizPesquisa.Perguntas?.length} perguntas</span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <LiaDotCircleSolid size={16} />
          <span className=''>{quizPesquisa.pontuacao} Pontos</span>
        </li>
      </ul>
    </div>
  )
}

function CardSkeleton() {
  return (
    <div className='flex w-full cursor-pointer flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-300 p-3 font-dm-sans'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl'>
          <Skeleton className='size-11 rounded-xl bg-esforce-black-200' />
          <div>
            <Skeleton className='mb-1 h-4 w-24 rounded-3xl bg-esforce-black-200' />
            <Skeleton className='h-4 w-20 rounded-3xl bg-esforce-black-200' />
          </div>
        </div>
        <div className='flex items-end justify-end gap-2 rounded-xl'>
          <Skeleton className='h-9 w-16 rounded-2xl bg-esforce-black-200' />
        </div>
      </div>

      <ul className='border- flex w-full items-center justify-between gap-1 border-esforce-black-300 pt-2.5 font-dm-sans text-sm font-normal text-esforce-grey-200'>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='mb-1 h-4 w-20 rounded-3xl bg-esforce-black-200' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='mb-1 h-4 w-20 rounded-3xl bg-esforce-black-200' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='mb-1 h-4 w-20 rounded-3xl bg-esforce-black-200' />
        </li>
      </ul>
    </div>
  )
}

export const QuizPesquisasPágina = () => {
  UseMenuActive('Quiz')
  useContextoOutletLayoutComNavegação({
    título: 'Quiz/Pesquisas',
    isBotãoEsquerdo: true,
    íconeDireita: ClockCountdown,
    rotaBotãoDireito: rotasApp.histórico,
  })

  const { quizPesquisaListar } = useQuizPesquisaListar()
  const navigate = useNavigate()

  const [quizPesquisaSelecionada, setQuizPesquisaSelecionada] = useState<
    ResponseQuizPesquisaListar | undefined
  >()

  const { ConferirModal, onOpen } = UseModalConferirQuizPesquisa()

  const handleClickCard = (quizPesquisa: ResponseQuizPesquisaListar) => {
    setQuizPesquisaSelecionada(quizPesquisa)
    onOpen()
  }
  return (
    <ContainerAppUi className='h-full'>
      <ConferirModal quizPesquisaSelecionada={quizPesquisaSelecionada} />
      <div className='flex h-full flex-col'>
        <Tabs defaultValue='available' className='h-full w-full'>
          <TabsList className='grid h-auto w-full grid-cols-3 gap-3 rounded-xl bg-esforce-black-300 p-2.5'>
            <TabsTrigger
              className='borde h-10 rounded-xl border border-[#262626] bg-esforce-black-300/70 font-dm-sans text-sm font-semibold text-esforce-white-100 data-[state=active]:border-esforce-green-200 data-[state=active]:bg-esforce-green-200/20 data-[state=active]:text-esforce-green-200'
              value='available'
            >
              Disponíveis
            </TabsTrigger>
            <TabsTrigger
              className='borde h-10 rounded-xl border border-[#262626] bg-esforce-black-300/70 font-dm-sans text-sm font-semibold text-esforce-white-100 data-[state=active]:border-esforce-green-200 data-[state=active]:bg-esforce-green-200/20 data-[state=active]:text-esforce-green-200'
              value='progress'
            >
              Andamento
            </TabsTrigger>
            <TabsTrigger
              className='borde h-10 rounded-xl border border-[#262626] bg-esforce-black-300/70 font-dm-sans text-sm font-semibold text-esforce-white-100 data-[state=active]:border-esforce-green-200 data-[state=active]:bg-esforce-green-200/20 data-[state=active]:text-esforce-green-200'
              value='done'
            >
              Finalizado
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value='available'
            className='hidden max-h-[calc(100%-60px)] flex-col items-center gap-3 overflow-y-auto data-[state=active]:flex'
          >
            {quizPesquisaListar ? (
              quizPesquisaListar.some((quizPesquisa) =>
                quizPesquisa.classificacao?.includes('DISPONÍVEL'),
              ) ? (
                quizPesquisaListar
                  .filter((quizPesquisa) =>
                    quizPesquisa.classificacao?.includes('DISPONÍVEL'),
                  )
                  .map((quizPesquisa) => {
                    const tipoQuiz = quizPesquisa?.tipo === 'quiz'
                    const timeAtual = new Date().getTime()
                    const timeQuiz = new Date(
                      quizPesquisa.expiraEm || '',
                    ).getTime()
                    const isExpirado = tipoQuiz && timeAtual > timeQuiz

                    return (
                      <CardItems
                        key={quizPesquisa.id}
                        quizPesquisa={quizPesquisa}
                        status={quizPesquisa.classificacao}
                        onClick={() =>
                          isExpirado
                            ? navigate(rotasApp.historicoQuiz(quizPesquisa.id))
                            : handleClickCard(quizPesquisa)
                        }
                      />
                    )
                  })
              ) : (
                <SvgSemDados />
              )
            ) : (
              Array.from({ length: 6 }).map((_, i) => (
                <CardSkeleton key={`skeleton-${i}`} />
              ))
            )}
          </TabsContent>
          <TabsContent
            value='progress'
            className='hidden max-h-[calc(100%-60px)] flex-col items-center gap-3 overflow-y-auto data-[state=active]:flex'
          >
            {quizPesquisaListar ? (
              quizPesquisaListar.some((quizPesquisa) =>
                quizPesquisa.classificacao?.includes('EM_ANDAMENTO'),
              ) ? (
                quizPesquisaListar
                  .filter((quizPesquisa) =>
                    quizPesquisa.classificacao?.includes('EM_ANDAMENTO'),
                  )
                  .map((quizPesquisa) => {
                    const tipoQuiz = quizPesquisa?.tipo === 'quiz'
                    const timeAtual = new Date().getTime()
                    const timeQuiz = new Date(
                      quizPesquisa.expiraEm || '',
                    ).getTime()
                    const isExpirado = tipoQuiz && timeAtual > timeQuiz

                    return (
                      <CardItems
                        key={quizPesquisa.id}
                        quizPesquisa={quizPesquisa}
                        status={quizPesquisa.classificacao}
                        onClick={() =>
                          isExpirado
                            ? navigate(rotasApp.historicoQuiz(quizPesquisa.id))
                            : handleClickCard(quizPesquisa)
                        }
                      />
                    )
                  })
              ) : (
                <SvgSemDados />
              )
            ) : (
              Array.from({ length: 6 }).map((_, i) => (
                <CardSkeleton key={`skeleton-${i}`} />
              ))
            )}
          </TabsContent>
          <TabsContent
            value='done'
            className='hidden max-h-[calc(100%-60px)] flex-col items-center gap-3 overflow-y-auto data-[state=active]:flex'
          >
            {quizPesquisaListar ? (
              quizPesquisaListar.some((quizPesquisa) =>
                quizPesquisa.classificacao?.includes('FINALIZADO'),
              ) ? (
                quizPesquisaListar
                  .filter((quizPesquisa) =>
                    quizPesquisa.classificacao?.includes('FINALIZADO'),
                  )
                  .map((quizPesquisa) => {
                    const tipoQuiz = quizPesquisa?.tipo === 'quiz'
                    const timeAtual = new Date().getTime()
                    const timeQuiz = new Date(
                      quizPesquisa.expiraEm || '',
                    ).getTime()
                    const isExpirado = tipoQuiz && timeAtual > timeQuiz

                    return (
                      <CardItems
                        key={quizPesquisa.id}
                        quizPesquisa={quizPesquisa}
                        status={quizPesquisa.classificacao}
                        onClick={() =>
                          isExpirado
                            ? navigate(rotasApp.historicoQuiz(quizPesquisa.id))
                            : handleClickCard(quizPesquisa)
                        }
                      />
                    )
                  })
              ) : (
                <SvgSemDados />
              )
            ) : (
              Array.from({ length: 6 }).map((_, i) => (
                <CardSkeleton key={`skeleton-${i}`} />
              ))
            )}
          </TabsContent>
        </Tabs>
      </div>
      <Popup />
    </ContainerAppUi>
  )
}
