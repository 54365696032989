import { IconType } from 'react-icons'
import { BiJoystick } from 'react-icons/bi'
import { FiShoppingCart } from 'react-icons/fi'
import { PiHouseFill, PiTrophy } from 'react-icons/pi'
import { RiMegaphoneLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { MenuType, UseActiveMenuStore } from '../../store/menu-type-store'

import { rotasApp } from '@/configurações/rotasApp'

interface IMenuItem {
  itemAtivo: MenuType
  Icon: IconType
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

export const BarraMenu = () => {
  const { menuActive } = UseActiveMenuStore()
  const navigate = useNavigate()

  const MenuItem = ({ itemAtivo, Icon, ...rest }: IMenuItem) => {
    return (
      <div className='flex h-[69px] w-10 flex-col items-center justify-center gap-2 p-1'>
        <div
          className={`flex size-10 cursor-pointer items-center justify-center rounded-xl p-2 ${menuActive === itemAtivo ? 'bg-esforce-green-100/10' : 'bg-esforce-black-200'}`}
          {...rest}
        >
          <Icon
            size={24}
            className={` ${menuActive === itemAtivo ? 'text-esforce-green-100/70' : 'text-esforce-white-200'}`}
          />
        </div>
        <p
          className={`font-dm-sans text-sm ${menuActive === itemAtivo ? 'font-bold text-esforce-white-100' : 'font-medium text-esforce-white-100/80'}`}
        >
          {itemAtivo}
        </p>
      </div>
    )
  }

  return (
    <div className='flex w-full items-center justify-between gap-6 border-t border-t-esforce-black-300 bg-esforce-black-100/80 px-6 py-3'>
      <MenuItem
        itemAtivo='Home'
        Icon={PiHouseFill}
        onClick={() => navigate(rotasApp.início)}
      />
      <MenuItem
        itemAtivo='Quiz'
        Icon={BiJoystick}
        onClick={() => navigate(rotasApp.quizPesquisas)}
      />
      <MenuItem
        itemAtivo='Loja'
        Icon={FiShoppingCart}
        onClick={() => navigate(rotasApp.prêmio)}
      />
      <MenuItem
        itemAtivo='Ranking'
        Icon={PiTrophy}
        onClick={() => navigate(rotasApp.pontos)}
      />
      <MenuItem
        itemAtivo='reForce'
        Icon={RiMegaphoneLine}
        onClick={() => navigate(rotasApp.reforce)}
      />
    </div>
  )
}
