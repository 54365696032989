import React, { useState, useEffect } from 'react'

type LazyImageProps = {
  src: any
  alt: string
  className?: string
  placeholder?: string
}

const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  className,
  placeholder,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [imageSrc, setImageSrc] = useState<string>(placeholder || '')

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1, // Ativado quando 10% do componente é visível
    })

    const imgElement = document.querySelector(`img[data-src="${src}"]`)
    if (imgElement) {
      observer.observe(imgElement)
    }

    return () => {
      if (imgElement) observer.unobserve(imgElement)
    }
  }, [src])

  useEffect(() => {
    if (isVisible) {
      setImageSrc(src) // Define a imagem original quando visível
    }
  }, [isVisible, src])

  return (
    <img
      src={imageSrc} // Sempre será uma string, graças ao fallback
      alt={alt}
      className={className}
      data-src={src}
      loading='lazy' // Adiciona suporte nativo, se disponível
    />
  )
}

export default LazyImage
