import { useEffect } from 'react'
import { GiScrollUnfurled } from 'react-icons/gi'
import { IoClose } from 'react-icons/io5'
import { PiArrowSquareOutLight } from 'react-icons/pi'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

import Container from '../../../components/container'
import { Button } from '../../../components/ui/button'

import { NotificacaoModel } from '@/@types/Models'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { useNotificacaoCount } from '@/hooks/API/notificacao/useNotificacaoCount'
import { useNotificacaoDeletar } from '@/hooks/API/notificacao/useNotificacaoDeletar'
import { useNotificacaoMarcarComoLida } from '@/hooks/API/notificacao/useNotificacaoMarcarComoLida'
import { useNotificacaoMarcarTodasComoLida } from '@/hooks/API/notificacao/useNotificacaoMarcarTodasComoLida'
import { useNotificacoes } from '@/hooks/API/notificacao/useNotificacoes'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { DateBR } from '@/utilitários/DateBR'
import moment from 'moment'

export const NotificaçãoPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Notificações',
    isBotãoEsquerdo: true,
  })
  const { ref, inView } = useInView()

  const { notificacoes, fetchNextPage, hasNextPage, refetch } = useNotificacoes(
    { take: 10 },
  )
  const { notificacoesCount } = useNotificacaoCount()
  const { marcarTodasComoLida } = useNotificacaoMarcarTodasComoLida()

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [hasNextPage, inView])

  return (
    <Container className='gap-2 px-5'>
      {!!notificacoesCount && notificacoesCount > 1 && (
        <div
          className='flex cursor-pointer gap-1 self-end rounded-md py-3'
          onClick={() => marcarTodasComoLida()}
        >
          <p className='text-base font-thin text-esforce-green-200'>
            Marcar todas como lidas
          </p>
        </div>
      )}
      {notificacoes?.pages[0].data?.length === 0 && <SvgSemDados />}
      {notificacoes?.pages.map((page) =>
        page?.data?.map((notificacao, index) => {
          if (page.data.length === index + 1) {
            return (
              <div key={notificacao.id} ref={ref}>
                <CardNotificacao notificacao={notificacao} />
              </div>
            )
          } else {
            return (
              <CardNotificacao key={notificacao.id} notificacao={notificacao} />
            )
          }
        }),
      )}
      {!hasNextPage && !!notificacoes?.pages[0].data.length && (
        <div className='flex h-fit w-full flex-col items-center gap-3 py-5'>
          <p
            className='cursor-pointer text-esforce-green-200'
            onClick={() => refetch()}
          >
            Fim das notificações
          </p>
        </div>
      )}
    </Container>
  )
}

const CardNotificacao = ({
  notificacao,
}: {
  notificacao: NotificacaoModel
}) => {
  const { marcarComoLida } = useNotificacaoMarcarComoLida()
  const { deletarNotificacao } = useNotificacaoDeletar()

  return (
    <>
      <div
        className='bg-esforce-black-250 relative flex min-h-28 w-full flex-col justify-between rounded-lg px-6 py-3.5'
        {...(!notificacao.lido && {
          cursor: 'pointer',
          onClick: () => marcarComoLida({ id: notificacao.id }),
        })}
      >
        <div className='flex h-full w-full flex-col items-start gap-4'>
          <div
            className={`bg-esforce-black-250 flex items-center gap-2 rounded-xl ${notificacao.lido ? 'opacity-30' : 'opacity-100'}`}
          >
            <div className='flex size-11 items-center justify-center gap-1 rounded-xl border-2 border-esforce-green-200/60 bg-esforce-green-700'>
              <GiScrollUnfurled
                size={24}
                className='text-esforce-green-200/90'
              />
            </div>
            <div>
              <p className='font-dm-sans text-sm font-semibold text-white'>
                {notificacao.titulo}
              </p>
              <p className='font-dm-sans text-xs font-normal text-[#737373]'>
                {notificacao.descricao}
              </p>
            </div>
          </div>
          <div className='flex'>
            {notificacao.link && notificacao.link?.length > 0 && (
              <Button
                {...(!notificacao.lido && {
                  onClick: () => marcarComoLida({ id: notificacao.id }),
                })}
                size='xs'
                className={`cursor-pointer bg-esforce-green-200 font-archivo font-medium text-esforce-black-200 hover:bg-esforce-green-100 ${notificacao.lido ? 'opacity-30' : 'opacity-100'}`}
              >
                <Link
                  to={notificacao.link}
                  className='flex items-center justify-center'
                >
                  <PiArrowSquareOutLight size={11} />
                  {notificacao.textoLink ?? 'Acessar'}
                </Link>
              </Button>
            )}
          </div>
        </div>
        <p
          className={`mt-2 text-sm text-esforce-green-200 ${notificacao.lido ? 'opacity-30' : 'opacity-100'}`}
        >
          {moment(notificacao.criadoEm).from(DateBR.dataNova())}
        </p>
        <div
          className='absolute right-1 top-0 flex cursor-pointer items-center justify-center p-1'
          onClick={() => deletarNotificacao({ id: notificacao.id })}
        >
          <IoClose size={24} className='text-red-600' />
        </div>
      </div>
    </>
  )
}
