import { useForm } from 'react-hook-form'

import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const usePesquisaRespostaCriar = () => {
  const pesquisaRespostasContext = useForm<SchemaPesquisa>({
    resolver: zodResolver(schemaPesquisa),
    reValidateMode: 'onChange',
  })

  const {
    mutate: enviarRespostasPesquisa,
    isLoading: isLoadingEnviarRespostasPesquisa,
    status: statusEnviarRespostasPesquisa,
  } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  return {
    enviarRespostasPesquisa,
    isLoadingEnviarRespostasPesquisa,
    pesquisaRespostasContext,
    statusEnviarRespostasPesquisa,
  }
}

async function endPoint(data: SchemaPesquisa): Promise<void> {
  const result = await api().post(rotasApi.pesquisaRepostaCriar, data)
  return result.data.data
}

const schemaPesquisa = z.object({
  alternativasIds: z
    .array(z.string(), {
      message: 'É necessário selecionar pelo menos uma alternativa.',
    })
    .min(1, { message: 'É necessário selecionar pelo menos uma alternativa.' }),
  perguntaId: z.string().min(1, { message: 'Pergunta Id é obrigatório.' }),
})

export default schemaPesquisa

export type SchemaPesquisa = z.infer<typeof schemaPesquisa>
