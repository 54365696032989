import { PlanoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApiPlano } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const usePlanoListar = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['plano-listar'],
    queryFn: async () => await endPoint(),
  })

  return { data, isLoading }
}

async function endPoint(): Promise<PlanoModel[]> {
  const result = await api().get(rotasApiPlano.listar)
  return result.data.data
}
