import { useForm } from 'react-hook-form'

import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useRecuperarSenha = () => {
  const {
    mutate: recuperarSenha,
    isLoading: recuperarSenhaIsLoading,
    status: recuperarSenhaStatus,
    data: recuperarSenhaData,
  } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  const recuperarSenhaContext = useForm<RecuperarSenhaSchema>({
    resolver: zodResolver(recuperarSenhaSchema),
    reValidateMode: 'onChange',
  })

  return {
    recuperarSenha,
    recuperarSenhaIsLoading,
    recuperarSenhaContext,
    recuperarSenhaStatus,
    recuperarSenhaData,
  }
}

async function endPoint(
  data: RecuperarSenhaSchema,
): Promise<{ email: string }> {
  const result = await api().post(rotasApi.recuperarSenha, data)
  return result.data.data
}

const recuperarSenhaSchema = z.object({
  email: z
    .string({ message: 'Campo obrigatório' })
    .email({ message: 'E-mail inválido!' })
    .min(1, { message: 'Campo obrigatório' }),
})

export type RecuperarSenhaSchema = z.infer<typeof recuperarSenhaSchema>
