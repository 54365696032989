import React from 'react'
import { BiMath } from 'react-icons/bi'
import { GiMaterialsScience, GiBookshelf, GiGreekTemple } from 'react-icons/gi'
import { IoEarthOutline, IoBookmarksOutline } from 'react-icons/io5'
import { IconType } from 'react-icons/lib'
import { RiEnglishInput } from 'react-icons/ri'

const iconMap: Record<string, IconType> = {
  ciencia: GiMaterialsScience,
  educacao: GiBookshelf,
  geografia: IoEarthOutline,
  matematica: BiMath,
  historia: GiGreekTemple,
  ingles: RiEnglishInput,
}

interface IconProps {
  name: string
}

const normalizeString = (str: string): string => {
  return str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase()
}

const IconSelector = ({ name }: IconProps) => {
  const normalizedKey = normalizeString(name)
  const SelectedIcon = iconMap[normalizedKey]

  return (
    <div>
      {SelectedIcon ? (
        <SelectedIcon size={20} className='text-esforce-white-200' />
      ) : (
        <IoBookmarksOutline size={20} className='text-esforce-white-200' />
      )}
    </div>
  )
}

export default IconSelector
