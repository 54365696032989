import { ReforceComentarioModel } from '@/@types/Models'
import { DateBR } from '@/utilitários/DateBR'
import moment from 'moment'
import Container from '../../../../../components/container'

export const _Comentario = (props: ComentariosProps) => {
  return (
    <Container className='h-fit items-start justify-between px-4 py-1'>
      <div className='flex gap-1'>
        <p className='text-sm font-bold text-esforce-white-100'>
          {props.Comentario.Aluno?.nome}
        </p>
        <p className='text-sm font-thin text-esforce-white-100'>
          {props.Comentario.comentario}
        </p>
      </div>
      <span className='text-[10px] font-bold text-esforce-green-100'>
        {moment(props.Comentario.criadoEm).from(DateBR.dataNova())}
      </span>
    </Container>
  )
}

interface ComentariosProps {
  Comentario: ReforceComentarioModel
}
