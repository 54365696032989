import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useParams } from 'react-router-dom'

import { ReforceCardPatternsUi } from './components/CardPatterns/ReforceCardPatternsUi'

import { CardReforceSkeleton } from './Reforce.página'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { useReforceBuscarId } from '@/hooks/API/reforce/useReforceBuscarId'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'

export const ReforceVisualizarPagina = () => {
  const { id } = useParams()
  const { data, fetchNextPage, hasNextPage, refetch, isLoading } =
    useReforceBuscarId({
      id,
      take: 8,
    })
  useContextoOutletLayoutComNavegação({
    título: 'Visualizar comentários',
    isBotãoEsquerdo: true,
  })

  const flexRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [hasNextPage, inView])

  return (
    <ContainerAppUi>
      {isLoading && <CardReforceSkeleton />}
      {data && (
        <>
          <ReforceCardPatternsUi.Root>
            <ReforceCardPatternsUi.Header
              data={{
                alunoNome: data.pages[0].data.Aluno?.nome as string,
                alunoAvatarUrl: data.pages[0].data.Aluno
                  ?.avatarArquivoLink as string,
                instituicaoNome:
                  `${data.pages[0].data.Instituicao?.nome} - ${data.pages[0].data.Aluno?.Turma?.nome}` as string,
                criadoEm: data.pages[0].data?.criadoEm,
              }}
            />
            <ReforceCardPatternsUi.Imagem
              data={{
                premioNome: data.pages[0].data.Premio?.nome as string,
                premioFotoUrl: data.pages[0].data.Premio?.Fotos?.[0]
                  ?.arquivoLink as string,
              }}
            />
            <ReforceCardPatternsUi.BarraInferior
              data={{
                isCurtida: data.pages[0].isCurtida,
                reforceFavoritos: data.pages[0].totalCurtidas,
                reforceId: data.pages[0].data.id,
              }}
            />
            <p className='px-4 text-sm font-thin text-esforce-green-200'>
              {data.pages[0].data.descricao}
            </p>

            <div>
              <ReforceCardPatternsUi.InputEmoji
                data={{ reforceId: data.pages[0].data.id }}
              />
            </div>
            <div ref={flexRef} className='flex w-full flex-col overflow-scroll'>
              {data?.pages.map((page) =>
                page.data.ReforceComentarios?.map((comentario, index) => {
                  if (page.data.ReforceComentarios?.length === index + 1) {
                    return (
                      <div key={comentario.id} ref={ref}>
                        <ReforceCardPatternsUi.ComentarioFoto
                          Comentario={comentario}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <ReforceCardPatternsUi.ComentarioFoto
                        Comentario={comentario}
                        key={comentario.id}
                      />
                    )
                  }
                }),
              )}

              {!hasNextPage &&
                !!data.pages[0].data.ReforceComentarios?.length && (
                  <div className='flex h-fit w-full flex-col items-center gap-3'>
                    <p
                      className='cursor-pointer text-sm text-esforce-green-200'
                      onClick={() => refetch()}
                    >
                      Fim dos comentários
                    </p>
                  </div>
                )}
            </div>
          </ReforceCardPatternsUi.Root>
        </>
      )}
    </ContainerAppUi>
  )
}
