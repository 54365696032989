import { FaApple } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { Link, useSearchParams } from 'react-router-dom'

import { useBackLogin } from '../_components/useBackLogin'
import Container from '@/components/container'
import { Input } from '@/components/input'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'

import {
  SchemaMatricula,
  useAuthConfirmarMatriculaV5,
} from '@/hooks/API/autenticacaoV5/useAuthConfirmarMatriculaV5'
import { useAuthPegarInstituicao } from '@/hooks/API/autenticacaoV5/useAuthPegarInsituicao'
import { Loader2 } from 'lucide-react'

export const VerificarMatriculaPagina = () => {
  const {
    mutateAuthBuscarMatricula,
    isLoadingAuthBuscarMatricula,
    contextAuthBuscarMatricula,
  } = useAuthConfirmarMatriculaV5()
  const { handleSubmit, control } = contextAuthBuscarMatricula
  const { instituicoes } = useAuthPegarInstituicao({
    matricula: contextAuthBuscarMatricula.watch('matricula'),
  })
  const [searchBarParams, setSearchParams] = useSearchParams()
  const instituicaoId = searchBarParams.get('instituicaoId')

  const { backLogin } = useBackLogin()

  const onSubmit = (data: SchemaMatricula) => {
    if (!instituicaoId) return
    localStorage.setItem('instituicoes', instituicaoId)
    mutateAuthBuscarMatricula(data)
  }
  return (
    <Container className='flex min-h-svh flex-col items-center justify-center gap-10 bg-esforce-black-200 px-5 py-8'>
      <hgroup className='flex max-w-60 flex-col items-center justify-center gap-5'>
        <img
          alt='logo da esforce na cor verde'
          src='../logo-colour.svg'
          className='h-9 w-40'
        />
        <p className='font-archivo text-2xl font-semibold text-esforce-green-200'>
          Primeiro acesso
        </p>
        <p className='flex flex-col text-center font-inter text-sm font-normal leading-6'>
          Bem vindo(a) ao primeiro acesso! Para continuar, informe sua
          matrícula.
        </p>
      </hgroup>

      <Form {...contextAuthBuscarMatricula}>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
          <fieldset className='flex flex-col gap-4'>
            <Input
              control={control}
              registerName='matricula'
              textlabel='Matricula'
              placeholder='Digite sua matricula'
              type='text'
            />
            <div className='flex w-full flex-col gap-1 font-archivo'>
              <Label className='mb-2 text-sm font-normal leading-5 text-esforce-white-100'>
                instituição
              </Label>
              <Select
                disabled={!instituicoes}
                onValueChange={(valorCampo) => {
                  if (valorCampo === 'undefined') {
                    setSearchParams((params) => {
                      params.delete('instituicaoId')
                      return params
                    })
                  } else {
                    setSearchParams((params) => {
                      params.set('instituicaoId', valorCampo)
                      return params
                    })
                  }
                }}
              >
                <SelectTrigger
                  className={`h-12 w-full rounded-xl bg-esforce-black-350 text-sm text-esforce-white-100`}
                >
                  <SelectValue placeholder='Selecione uma instituição' />
                </SelectTrigger>
                <SelectContent
                  className={`bg-esforce-black-350 text-sm text-esforce-white-100`}
                >
                  {instituicoes &&
                    instituicoes.map((instituicao) => (
                      <SelectItem key={instituicao.id} value={instituicao.id}>
                        {instituicao.nome}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
          </fieldset>

          <Button
            size='xl'
            type='submit'
            disabled={isLoadingAuthBuscarMatricula}
            className='mt-7 w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
          >
            {isLoadingAuthBuscarMatricula ? (
              <>
                <Loader2 className='animate-spin' />
              </>
            ) : (
              'Verificar matrícula'
            )}
          </Button>
        </form>
        <div className='mt-8 hidden w-full items-center justify-center font-rubik'>
          <Separator className='flex-1 bg-esforce-grey-100' />
          <p className='flex-1 text-center text-sm font-normal text-esforce-grey-100'>
            {' '}
            ou{' '}
          </p>
          <Separator className='flex-1 bg-esforce-grey-100' />
        </div>
        <div className='hidden w-full gap-4 font-rubik'>
          <Button
            size='xl'
            className='flex w-full items-center gap-2 bg-esforce-black-350 text-base text-esforce-white-100'
          >
            <FcGoogle size={32} /> | <span> Google </span>
          </Button>
          <Button
            size='xl'
            className='text-esforce-white-10 flex w-full items-center gap-2 bg-esforce-black-350 text-base'
          >
            <FaApple size={32} /> | <span> Apple </span>
          </Button>
        </div>
      </Form>

      <div className='flex w-full flex-col items-center justify-center gap-4 text-center font-archivo text-sm font-normal text-esforce-grey-100'>
        <p>
          {' '}
          Já possui uma conta?{' '}
          <button
            type='button'
            onClick={backLogin}
            className='text-esforce-green-200'
          >
            {' '}
            Realizar login{' '}
          </button>
        </p>
        <p>
          {' '}
          Não sabe sua matrícula?{' '}
          <Link
            target='_blank'
            rel='noreferrer'
            to='https://api.whatsapp.com/send?phone=5511945665619&text=Ol%C3%A1,%20preciso%20de%20ajuda%20para%20recuperar%20minha%20matr%C3%ADcula%20!'
            className='text-esforce-green-200'
          >
            {' '}
            Clique aqui para descobrir{' '}
          </Link>{' '}
        </p>
      </div>
    </Container>
  )
}
