import { IconType } from 'react-icons/lib'
import { TiArrowLeft, TiArrowRight } from 'react-icons/ti'
import { useNavigate } from 'react-router-dom'

import { Button } from '../components/ui/button'

export const NavegaçãoUi = (props: INavegaçãoProps) => {
  const { callbackBotãoDireito, isBotãoEsquerdo, título, íconeDireito } = props
  const navigate = useNavigate()
  return (
    <div className='grid w-full grid-cols-3 items-center justify-items-center bg-transparent pb-0 text-esforce-white-100'>
      <div className='w-full'>
        {isBotãoEsquerdo && (
          <Button
            onClick={() => navigate(-1)}
            size='icon'
            className='size-10 rounded-full bg-esforce-black-300'
          >
            <TiArrowLeft />
          </Button>
        )}
      </div>

      <h4 className='min-w-max items-center font-rubik text-base font-bold'>
        {título}
      </h4>

      <div className='flex w-full items-center justify-end'>
        {íconeDireito && (
          <Button
            onClick={callbackBotãoDireito}
            size='icon'
            className='size-10 self-end rounded-full bg-esforce-black-300'
          >
            {íconeDireito ? <íconeDireito /> : <TiArrowRight />}
          </Button>
        )}
      </div>
    </div>
  )
}

interface INavegaçãoProps {
  isBotãoEsquerdo?: boolean
  callbackBotãoDireito?: () => void
  título?: string
  íconeDireito?: IconType | any
}
