import React from 'react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './styles.css'

import { Autoplay, Pagination } from 'swiper/modules'

import { PremioArquivoModel } from '../../@types/Models'

import { Swiper, SwiperSlide } from 'swiper/react'

export default function Carousel({ photos }: CarouselProps) {
  return (
    <div className='px-5'>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className='prodruct-visualization-carousel'
      >
        {photos.map((foto, index) => (
          <SwiperSlide
            key={index}
            className='rounded-xl bg-gradient-to-tr from-esforce-pink-200 from-10% via-transparent via-50% to-esforce-pink-200 to-100% p-1.5'
          >
            <img
              src={foto.arquivoLink}
              alt={foto.id}
              className='h-60 w-full rounded-xl'
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

interface CarouselProps {
  photos: PremioArquivoModel[]
}
