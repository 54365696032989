import { PropsWithChildren, useEffect, useRef, useState } from 'react'

export const SplashPágina = ({ children }: PropsWithChildren) => {
  const [deg, setDeg] = useState(-50)

  const direction = useRef<'GO' | 'BACK'>('GO')

  useEffect(() => {
    const interval = setInterval(() => {
      setDeg((deg) => {
        if (deg === -60) direction.current = 'GO'
        if (deg === 100) direction.current = 'BACK'
        if (direction.current === 'GO') {
          return deg + 1
        } else return deg - 1
      })
    }, 20)

    return () => {
      clearInterval(interval)
    }
  }, [direction])

  return (
    <div className='fixed left-0 top-0 z-[99] flex h-screen w-screen items-center justify-center overflow-hidden bg-esforce-black-100'>
      <div
        className='radial-gradient-green absolute top-0 size-[550px] rounded-full blur-xl'
        style={{ left: `${deg}%` }}
      />
      <div
        className='radial-gradient-pink absolute bottom-0 size-[550px] rounded-full blur-xl'
        style={{ right: `${deg}%` }}
      />
      {children || (
        <img
          src='/logo.svg'
          alt='Logo da empresa'
          className='size absolute z-[999] size-[clamp(12rem,35vw,16rem)]'
        />
      )}
    </div>
  )
}
