// import { useEffect, useState } from 'react'

// import { AlertDilogPopup } from '../AlertDilog/AlertDilogPopup'

// import { PopupModel } from '@/@types/Models'
// import { FlexColumnUi } from '@/componentes/FlexColumnUi'
// import { LinkUi } from '@/componentes/LinkUi'
// import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
// import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
// import { corPrimariaAtivoUi, coresUi } from '@/configurações/estilização/cores'
// import { gapUi } from '@/configurações/estilização/medidas'
// import { lineHeightUi } from '@/configurações/estilização/tipografia'
// import { usePopupMarcarComoLido } from '@/hooks/API/popup/usePopupMarcarComoLido'
// import { usePopupMostrar } from '@/hooks/API/popup/usePopupMostrar'
// import { DateBR } from '@/utilitários/DateBR'
// import {
//   Flex,
//   Icon,
//   Modal,
//   ModalContent,
//   ModalOverlay,
//   useDisclosure,
// } from '@chakra-ui/react'
// import { ArrowSquareOut } from '@phosphor-icons/react'
// import moment from 'moment'

export const Popup: React.FC = () => {
  // const { marcarComoLida } = usePopupMarcarComoLido()
  // const { popupsList } = usePopupMostrar()
  // const { isOpen, onOpen, onClose } = useDisclosure()

  // const [popup, setPopup] = useState<PopupModel | undefined>(undefined)

  // const showPopup = () => {
  //   setPopup(popupsList[0])
  //   setshowFullDescription(false)
  // }
  // const handleClosePopups = () => {
  //   if (popup === undefined) return
  //   marcarComoLida({ id: popup.id })
  //   popupsList.shift()
  //   showPopup()
  //   if (!popupsList.length) {
  //     onClose()
  //   }
  // }
  // const [showFullDescription, setshowFullDescription] = useState(false)
  // const toggleDescricao = () => {
  //   setshowFullDescription(!showFullDescription)
  // }
  // useEffect(() => {
  //   if (popupsList.length > 0) {
  //     showPopup()
  //     onOpen()
  //   } else {
  //     onClose()
  //   }
  // }, [popupsList])
  // if (!popupsList.length) {
  //   return null
  // }
  // if (!popup) return <></>
  // const descricaoResumida = popup.descricao?.slice(0, 150)

  return (
    <></>
    // <Modal
    //   isOpen={isOpen}
    //   onClose={() => {}}
    //   isCentered={false}
    //   size={'md'}
    //   motionPreset='scale'
    //   scrollBehavior='outside'
    //   closeOnEsc={false}
    //   closeOnOverlayClick={false}
    // >
    //   <ModalOverlay
    //     backdropFilter='auto'
    //     backdropBlur={`10px`}
    //     filter={`grayscale(0%)`}
    //     id='overlay-close-modal'
    //   />
    //   <ModalContent
    //     bg={coresUi.fundo.secundaria}
    //     shadow='xs'
    //     textAlign='center'
    //     borderTopRadius='10px'
    //   >
    //     <FlexColumnUi background={corPrimariaAtivoUi} borderRadius='12px'>
    //       <Flex
    //         alignItems='center'
    //         padding='15px'
    //         minW='100%'
    //         background='#1c1c1c'
    //         borderTopRadius='10px'
    //         position='relative'
    //       >
    //         <FlexColumnUi gap='15px'>
    //           <FlexColumnUi gap='10px'>
    //             <TextoSmUi width='fit-content' zIndex={99}>
    //               {popup.titulo}
    //             </TextoSmUi>
    //             <TextoSmUi
    //               textAlign='justify'
    //               color={coresUi.texto.secundaria}
    //               lineHeight={lineHeightUi.lg}
    //             >
    //               {popup.descricao &&
    //               (popup.descricao.length < 150 || showFullDescription)
    //                 ? popup.descricao
    //                 : descricaoResumida}
    //               {popup.descricao &&
    //               !showFullDescription &&
    //               popup.descricao.length > 150 ? (
    //                 <span
    //                   style={{
    //                     fontWeight: 'bold',
    //                     color: corPrimariaAtivoUi,
    //                     cursor: 'pointer',
    //                   }}
    //                   onClick={toggleDescricao}
    //                 >
    //                   ... Ler mais
    //                 </span>
    //               ) : (
    //                 ''
    //               )}
    //             </TextoSmUi>
    //           </FlexColumnUi>
    //           <Flex justifyContent={'space-between'}>
    //             <TextoXsUi width='fit-content' color={corPrimariaAtivoUi}>
    //               {moment(popup.criadoEm).from(DateBR.dataNova())}
    //             </TextoXsUi>
    //             {popup.link && popup.link?.length > 0 && (
    //               <LinkUi
    //                 rota={popup.link}
    //                 target={popup.link.includes('http') ? '_blank' : '_self'}
    //                 flexDirection='row'
    //                 display='flex'
    //                 alignItems='center'
    //                 gap='5px'
    //                 background={coresUi['primaria-ativo']}
    //                 width='fit-content'
    //                 paddingX={gapUi.lg}
    //                 paddingY={gapUi.sm}
    //                 borderRadius='10px'
    //                 cursor='pointer'
    //               >
    //                 <Icon
    //                   as={ArrowSquareOut}
    //                   weight='duotone'
    //                   fontSize='15px'
    //                   color={coresUi.texto['com-fundo-primário']}
    //                 />
    //                 <TextoXsUi
    //                   textTransform='uppercase'
    //                   fontWeight='bold'
    //                   style={{ fontSize: '10px' }}
    //                   color={coresUi.texto['com-fundo-primário']}
    //                 >
    //                   {popup.textoLink ?? 'Acessar'}
    //                 </TextoXsUi>
    //               </LinkUi>
    //             )}
    //           </Flex>
    //         </FlexColumnUi>
    //       </Flex>
    //       <AlertDilogPopup
    //         titulo='Marcar como lido'
    //         descricao='Você tem certeza que deseja marcar esse pop-up como lido?'
    //         marcarComoLida={handleClosePopups}
    //       >
    //         Marcar como lido
    //       </AlertDilogPopup>
    //     </FlexColumnUi>
    //   </ModalContent>
    // </Modal>
  )
}
