import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { FaApple } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import Container from '@/components/container'
import { Input } from '@/components/input'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'

import { primeiroAcessoRotasApp } from '../primeiroAcesso/primeiroAcesso.routes'

import { rotasApp } from '@/configurações/rotasApp'
import { LoginSchema, useLogin } from '@/hooks/API/autenticação/useLogin'
import { Loader2 } from 'lucide-react'

export const Login = () => {
  const [seePassword, setSeePassword] = useState<boolean>(false)
  const { loginContext, login, isLoadingLogin } = useLogin()

  const { handleSubmit, control } = loginContext

  const onSubmit: SubmitHandler<LoginSchema> = (data) => {
    login(data)
  }

  return (
    <Container className='flex min-h-svh flex-col items-center justify-center gap-10 bg-esforce-black-200 px-5 py-8'>
      <hgroup className='flex max-w-56 flex-col items-center justify-center gap-5'>
        <img
          alt='logo da esforce na cor verde'
          src='./logo-colour.svg'
          className='h-9 w-40'
        />
        <p className='flex flex-col text-center font-inter text-sm font-normal leading-6'>
          Entre para a maior comunidade de games e divirta-se
        </p>
      </hgroup>

      <Form {...loginContext}>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
          <fieldset className='flex flex-col gap-4'>
            <Input
              control={control}
              registerName='email'
              textlabel='Email'
              placeholder=''
              type='text'
            />

            <div className='relative'>
              <Input
                control={control}
                registerName='senha'
                textlabel='Senha'
                placeholder=''
                type={!seePassword ? 'password' : 'text'}
              />

              <div
                className='absolute right-5 top-11 z-[99] cursor-pointer'
                onClick={() => setSeePassword((prev) => !prev)}
              >
                {!seePassword ? (
                  <PiEyeLight size={24} />
                ) : (
                  <PiEyeSlash size={24} />
                )}
              </div>
            </div>

            <Link
              to={rotasApp.recuperarSenhaMatrícula}
              className='cursor-pointer text-end font-inter text-sm font-medium text-esforce-grey-100 hover:text-esforce-white-100'
            >
              Esqueci minha senha
            </Link>
          </fieldset>

          <Button
            size='xl'
            type='submit'
            disabled={isLoadingLogin}
            className='mt-7 w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
          >
            {isLoadingLogin ? (
              <>
                <Loader2 className='animate-spin' />
              </>
            ) : (
              'Entrar'
            )}
          </Button>
        </form>

        <div className='mt-8 hidden w-full items-center justify-center font-rubik'>
          <Separator className='flex-1 bg-esforce-grey-100' />
          <p className='flex-1 text-center text-sm font-normal text-esforce-grey-100'>
            Ou entre com
          </p>
          <Separator className='flex-1 bg-esforce-grey-100' />
        </div>

        <div className='hidden w-full gap-4 font-rubik'>
          <Button
            size='xl'
            className='flex w-full items-center gap-2 bg-esforce-black-350 text-base text-esforce-white-100'
          >
            <FcGoogle size={32} /> | <span> Google </span>
          </Button>
          <Button
            size='xl'
            className='text-esforce-white-10 flex w-full items-center gap-2 bg-esforce-black-350 text-base'
          >
            <FaApple size={32} /> | <span> Apple </span>
          </Button>
        </div>
      </Form>
      <p className='mt-16 text-center font-archivo text-sm font-normal text-esforce-grey-100'>
        Novo na esfoce/se/?{' '}
        <Link
          to={primeiroAcessoRotasApp.buscarMatricula}
          className='text-esforce-green-200'
        >
          Realizar cadastro
        </Link>
      </p>
    </Container>
  )
}
