import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'

import { UseModalUi } from '../../../../components/modal-ui/UseModalUi'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import { CardPlanoAdquirido } from './CardPlanoAdquirido'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { configuraçõesApi } from '@/configurações/configuraçõesApi'
import { useInstituicao } from '@/hooks/API/instituicao/useInstituicao'
import { usePlanoBuscarId } from '@/hooks/API/plano/usePlanoBuscarId'
import { usePlanoPagamentoPlanoId } from '@/hooks/API/plano/usePlanoPagamentoPlanoId'
import { usePontezaComprar } from '@/hooks/API/plano/usePontezaComprar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Loader2 } from 'lucide-react'

export const PlanoVisualizarPagina = () => {
  const { id } = useParams()
  const [searchBarParams, setSearchParams] = useSearchParams()
  const { data: plano } = usePlanoBuscarId({ id })
  const { data: instituicao } = useInstituicao()
  const { data: planoPagamento } = usePlanoPagamentoPlanoId({ planoId: id })
  const {
    mutate,
    isLoading,
    status,
    data: pedidoPagamento,
  } = usePontezaComprar()
  const { UiModal, onOpen, isOpen, onClose } = UseModalUi()
  const context = useForm()
  const { control, handleSubmit } = context
  const PeriodoId = searchBarParams.get('periodoId') || ''

  useContextoOutletLayoutComNavegação({
    título: `${plano?.nome ?? 'Plano'}`,
    isBotãoEsquerdo: true,
  })

  useEffect(() => {
    if (status === 'success' && pedidoPagamento) {
      context.reset()
      onOpen()
    }
  }, [status])

  const RenderModal = useCallback(
    () => (
      <UiModal
        callbackBotão={() => {
          window.open(pedidoPagamento?.urlPagamento as string, '_blank')
          onClose()
        }}
        textoBotão='Ir para pagamento'
        título='Pedido realizado com sucesso'
        descrição='O pedido da compra foi realizado com sucesso, agora você deve realizar o pagamento para que o plano seja ativado.'
      >
        <p className='text-xs italic leading-6 text-esforce-grey-200'>
          O pedido vai expirar em 15 minutos, caso o pagamento não seja
          realizado o pedido será cancelado.
        </p>
      </UiModal>
    ),
    [isOpen, pedidoPagamento],
  )

  const pedidosAguardandoPagamento = planoPagamento?.filter(
    (plano) => plano.PlanoPagamento?.status === 'Aguardando pagamento',
  )

  const onSubmit = () => {
    mutate({
      instituicaoPeriodoId: PeriodoId,
    })
  }

  return (
    <ContainerAppUi className='flex-col gap-5'>
      <RenderModal />
      {plano && (
        <>
          <div className='flex flex-col gap-2'>
            <div className='relative flex h-0 w-full overflow-hidden rounded-xl pb-[56%]'>
              <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
                <img
                  className='h-full max-h-full w-full max-w-full object-cover'
                  src={configuraçõesApi.baseArquivo(plano?.fotoUrl as string)}
                  alt='imagem do plano'
                />
              </div>
            </div>
            <h2 className='text-center text-3xl font-bold text-esforce-green-200'>
              {plano.nome}
            </h2>
            <div
              className='termAndPrivacy flex w-full flex-col'
              dangerouslySetInnerHTML={{
                __html: substituirPropriedades(plano?.descricao as string, {
                  nome: plano.nome,
                  valor: plano.valor.toFixed(2),
                }),
              }}
            />
          </div>

          {planoPagamento?.length !==
            instituicao?.InstituicaoPeriodos?.length && (
            <Form {...context}>
              <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
                <div className='flex w-full flex-col gap-1 font-archivo'>
                  <Label className='mb-2 text-xl font-normal leading-5 text-esforce-white-100'>
                    Período
                  </Label>
                  <Controller
                    control={control}
                    name='periodo'
                    render={({ field: { onChange, value, ...field } }) => (
                      <Select
                        onValueChange={(valorCampo) => {
                          if (valorCampo === 'undefined') {
                            setSearchParams((params) => {
                              params.delete('periodoId')
                              return params
                            })
                          } else {
                            setSearchParams((params) => {
                              params.set('periodoId', valorCampo)
                              return params
                            })
                          }
                          onChange(valorCampo)
                        }}
                      >
                        <SelectTrigger
                          className={`h-12 w-full rounded-xl bg-esforce-black-350 text-sm text-esforce-white-100`}
                        >
                          <SelectValue placeholder='Selecione um período' />
                        </SelectTrigger>
                        <SelectContent
                          className={`bg-esforce-black-350 text-sm text-esforce-white-100`}
                        >
                          {instituicao?.InstituicaoPeriodos?.map((periodo) => {
                            const planoPagamentoPeriodo = planoPagamento?.find(
                              (plano) =>
                                plano.instituicaoPeriodoId === periodo.id,
                            )
                            const valor = planoPagamentoPeriodo
                              ? 'Adquirido'
                              : `R$ ${plano.valor.toFixed(2)}`

                            return (
                              <SelectItem
                                key={periodo.id}
                                value={periodo.id}
                                disabled={!!planoPagamentoPeriodo}
                              >
                                {`${periodo.nome} - ${valor}`}
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
                <Button
                  disabled={context.watch('periodo') === ''}
                  size='xl'
                  type='submit'
                  className='mt-5 w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
                >
                  {isLoading ? (
                    <>
                      <Loader2 className='animate-spin' />
                    </>
                  ) : (
                    'Comprar'
                  )}
                </Button>
              </form>
            </Form>
          )}
          <div
            className='flex flex-col gap-2'
            hidden={planoPagamento?.length === 0}
          >
            <p className='text-lg font-bold'>Períodos adquiridos</p>
            <div className='flex flex-col items-start gap-1'>
              {planoPagamento?.map((plano, i) => {
                if (plano.PlanoPagamento?.status === 'Cancelado') return null
                else if (plano.PlanoPagamento?.status === 'Expirado')
                  return null
                return <CardPlanoAdquirido key={plano.id} plano={plano} i={i} />
              })}
            </div>
          </div>
          {!!pedidosAguardandoPagamento?.length && (
            <div className='flex flex-col gap-2'>
              <p className='text-center text-sm font-bold italic text-red-500'>
                Você possui {pedidosAguardandoPagamento.length} período
                aguardando pagamento.
              </p>
              <p className='text-center text-sm italic'>
                Você será redirecionado para um ambiente seguro do PagSeguro
                para realizar o pagamento.
              </p>
            </div>
          )}
        </>
      )}
    </ContainerAppUi>
  )
}

function substituirPropriedades(texto: string, dados: Record<string, string>) {
  return texto?.replace(
    /{{(.*?)}}/g,
    (match, propriedade) => dados[propriedade.trim()] || match,
  )
}
