import { useEffect, useRef, useState } from 'react'

import { useQuizTimeResposta } from '@/hooks/API/quiz-pesquisa/useQuizTimeResposta'

export const useTimeResposta = (props: UseTimeRespostaAtributos) => {
  const { quizTimeResposta, isLoadingQuizTimeResposta } = useQuizTimeResposta({
    quizId: props.quizId,
  })

  const [tempoResposta, setTempoResposta] = useState<number>(-1)
  const tempoRespostaInterval = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (!props.quizId) return
    clearInterval(tempoRespostaInterval.current as unknown as NodeJS.Timeout)
    setTempoResposta(-1)
  }, [props.quizId])

  useEffect(() => {
    if (!quizTimeResposta) return

    const startTime = Date.now()
    const duration = quizTimeResposta.unixTimeEnd - quizTimeResposta.serverTime

    const atualizarDiferencaEmSegundos = () => {
      const elapsedTime = Date.now() - startTime
      const diferencaEmMilissegundos = duration - elapsedTime
      const diferencaEmSegundos = Math.ceil(diferencaEmMilissegundos / 1000)

      if (diferencaEmMilissegundos > 0) {
        setTempoResposta(diferencaEmSegundos)
      } else {
        setTempoResposta(0)
        clearInterval(
          tempoRespostaInterval.current as unknown as NodeJS.Timeout,
        )
      }
    }

    atualizarDiferencaEmSegundos() // Atualiza imediatamente para evitar delay de 1 segundo.
    tempoRespostaInterval.current = setInterval(
      atualizarDiferencaEmSegundos,
      1000,
    )

    return () => {
      clearInterval(tempoRespostaInterval.current as unknown as NodeJS.Timeout)
    }
  }, [quizTimeResposta])

  return {
    tempoResposta,
    quizTimeResposta,
    isLoadingQuizTimeResposta,
    tempoRespostaInterval,
    setTempoResposta,
  }
}

interface UseTimeRespostaAtributos {
  quizId?: string
}
