import React from 'react'

import { Button } from '../ui/button'

interface IModalUiProps {
  callbackBotão: () => void
  children?: React.ReactNode
}

export const UseModalFinishQuiz = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const UiModal: React.FC<IModalUiProps> = ({ callbackBotão, children }) => (
    <div
      className={`fixed inset-0 z-[999] flex items-center justify-center ${isOpen ? 'block' : 'hidden'}`}
    >
      <div className='absolute inset-0 bg-opacity-40 backdrop-blur-lg' />
      <div className='relative mx-1.5 flex h-[305px] w-full max-w-[335px] flex-col rounded-xl bg-gradient-to-tr from-esforce-pink-100 via-transparent via-50% to-esforce-pink-100 p-0.5'>
        <div className='background-finish-quiz flex h-full w-full flex-col items-center justify-end gap-6 rounded-xl'>
          <div className='flex w-full flex-col items-center justify-between gap-6 rounded-b-xl bg-esforce-black-100 p-4'>
            {children}
            <Button
              size='xl'
              type='submit'
              className='w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
              onClick={callbackBotão}
            >
              Continuar
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  return { UiModal, isOpen, onOpen, onClose }
}
