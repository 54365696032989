import { useForm } from 'react-hook-form'

import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useRedefinirSenha = () => {
  const {
    mutate: redefinirSenha,
    isLoading: isLoadingRedefinirSenha,
    status: statusRedefinirSenha,
  } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  const redefinirSenhaSenhaContext = useForm<RedefinirSenhaSenhaSchema>({
    resolver: zodResolver(redefinirSenhaSenhaSchema),
    reValidateMode: 'onBlur',
  })

  return {
    redefinirSenha,
    isLoadingRedefinirSenha,
    redefinirSenhaSenhaContext,
    statusRedefinirSenha,
  }
}

async function endPoint(data: RedefinirSenhaSenhaSchema): Promise<boolean> {
  const result = await api().post(rotasApi.redefinirSenha, data)
  return result.data.data
}

export const redefinirSenhaSenhaSchema = z
  .object({
    matricula: z.string().min(1),
    codigoAcesso: z.string().min(1),
    senha: z
      .string({ message: 'Campo obrigatório' })
      .min(6, { message: 'Minimo 6 caracters' }),
    confirmarSenha: z.string().optional(),
  })
  .refine((data) => data.senha === data.confirmarSenha, {
    message: 'Senhas não conferem',
    path: ['confirmarSenha'],
  })

export type RedefinirSenhaSenhaSchema = z.infer<
  typeof redefinirSenhaSenhaSchema
>
