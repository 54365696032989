import { IconType } from 'react-icons'
import { FiSmartphone, FiInfo, FiTrash2 } from 'react-icons/fi'
import { GoArrowRight } from 'react-icons/go'
import { HiOutlineLogout } from 'react-icons/hi'
import { LuFileQuestion } from 'react-icons/lu'
import { PiListMagnifyingGlassBold, PiLockKeyBold } from 'react-icons/pi'
import { RiFileShield2Line } from 'react-icons/ri'
import { TbUserPentagon, TbFileDescription } from 'react-icons/tb'
import { TfiReceipt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'

import { UseModalWithChildrenUi } from '../../../../components/modal-ui/UseModalChildrenUI'
import ProfileCard from '../../../../components/profile-card'
import { Button } from '../../../../components/ui/button'
import Container from '@/components/container'
import { UseModalUi } from '@/components/modal-ui/UseModalUi'

import { ConteudoModalDeletarConta } from './ConteudoModalDeletarConta'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { useLogout } from '@/hooks/API/autenticação/useLogout'
import { useInstituicao } from '@/hooks/API/instituicao/useInstituicao'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { UseMenuActive } from '@/hooks/useMenuAtivo'
import { UseActiveUser } from '@/store/user.store'

interface ItemProps {
  Icon: IconType
  título: string
  descrição: string
  onClick?: React.MouseEventHandler<HTMLElement>
}

export const MeuPerfilPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Meu Perfil',
    isBotãoEsquerdo: true,
  })
  const navigate = useNavigate()
  UseMenuActive(undefined)
  const { logout } = useLogout()
  const { userActive } = UseActiveUser()
  const { data } = useInstituicao()

  const Item = ({ título, Icon, descrição, onClick, ...rest }: ItemProps) => (
    <div
      className='flex h-14 w-full cursor-pointer items-center justify-between gap-5 py-2'
      onClick={onClick}
    >
      <div className='flex items-center justify-center gap-2'>
        <span className='flex size-10 items-center justify-center rounded-full bg-esforce-green-200/10'>
          <Icon size={20} className='text-esforce-green-200' />
        </span>
        <div className='flex flex-col gap-1 font-dm-sans'>
          <p className='text-sm capitalize text-esforce-white-200'>{título}</p>
          <p className='text-xs text-[#525252]'>{descrição}</p>
        </div>
      </div>
      <button
        type='button'
        className='flex size-10 items-center justify-center rounded-full bg-esforce-black-300'
        onClick={onClick}
      >
        <GoArrowRight size={20} />
      </button>
    </div>
  )

  const { UiModal, onOpen, onClose } = UseModalUi()
  const {
    UiModal: UiModalDeletarConta,
    onOpen: onOpenDeletarConta,
    onClose: onCloseDeletarConta,
  } = UseModalWithChildrenUi()

  return (
    <ContainerAppUi>
      <Container className='items-center bg-esforce-black-100'>
        <ProfileCard />

        <div className='flex w-full flex-col gap-5'>
          <p className='font-dm-sans text-base font-semibold uppercase text-[#525252]'>
            Perfil
          </p>
          <div className='flex flex-col gap-1'>
            <Item
              Icon={TbUserPentagon}
              título='Editar perfil'
              descrição='Edite as informações da sua conta'
              onClick={() => navigate(rotasApp.editarPerfil)}
            />
            <Item
              Icon={PiLockKeyBold}
              título='Alterar senha'
              descrição='Altere sua senha de acesso atual'
              onClick={() => navigate(rotasApp.alterarSenha)}
            />
            <Item
              Icon={PiListMagnifyingGlassBold}
              título='Histórico'
              descrição='Visualize os quizzes/pesquisas realizados'
              onClick={() => navigate(rotasApp.histórico)}
            />
            <Item
              Icon={FiTrash2}
              título='Excluir Perfil'
              descrição='Exclua a sua conta do aplicativo.'
              onClick={onOpenDeletarConta}
            />
            <Item
              Icon={HiOutlineLogout}
              título='Sair'
              descrição='Realizar log out da sua conta'
              onClick={onOpen}
            />
          </div>
          <p className='font-dm-sans text-base font-semibold uppercase text-[#525252]'>
            aplicativo
          </p>
          <div className='flex flex-col gap-1'>
            {/* {!!data?.InstituicaoPeriodos?.length && (
              <Item
                Icon={TfiReceipt}
                título='Planos'
                descrição='Visualize os planos disponíveis'
                onClick={() => navigate(rotasApp.planos)}
              />
            )} */}
            <Item
              Icon={TbFileDescription}
              título='Termos de uso'
              descrição='Visualize os termos de uso'
              onClick={() => navigate(rotasApp.termosDeUso)}
            />
            <Item
              Icon={RiFileShield2Line}
              título='Privacidade'
              descrição='Visualize a política de privacidade'
              onClick={() => navigate(rotasApp.privacidade)}
            />
            <Item
              Icon={FiSmartphone}
              título='Regulamentos aplicativo'
              descrição='Visualize os regulamentos do aplicativo'
              onClick={() => navigate(rotasApp.regulamentosAplicativo)}
            />
            <Item
              Icon={RiFileShield2Line}
              título='Regulamentos'
              descrição='Visualize os regulamentos da instituição'
              onClick={() => navigate(rotasApp.regulamentosInstituicoes)}
            />
            <Item
              Icon={LuFileQuestion}
              título='Perguntas Frequentes'
              descrição='Visualize as perguntas frequentes'
              onClick={() => navigate(rotasApp.perguntasFrequentes)}
            />
            <Item
              Icon={FiInfo}
              título='Sobre'
              descrição='Informações do app'
              onClick={() => navigate(rotasApp.sobre)}
            />
          </div>
        </div>

        <UiModal
          título='Sair da conta'
          descrição='Deseja mesmo sair? Será necessário realizar um novo login para acessar sua conta novamente.'
          textoBotão='Confirmar'
          callbackBotão={logout}
        >
          <Button
            size='xl'
            className='flex w-full cursor-pointer items-center gap-2 rounded-lg bg-esforce-black-100 font-archivo text-sm font-bold text-esforce-white-100 transition-all duration-300 hover:scale-95'
            onClick={onClose}
          >
            Cancelar
          </Button>
        </UiModal>
        <UiModalDeletarConta>
          <ConteudoModalDeletarConta onClose={onCloseDeletarConta} />
        </UiModalDeletarConta>
      </Container>
    </ContainerAppUi>
  )
}
