import { cn } from '../lib/utils'
interface ContainerAppProps {
  as?: React.ElementType
  children: React.ReactNode
  className?: string
}

export const ContainerAppUi = ({
  children,
  as: Tag = 'div',
  className,
  ...rest
}: ContainerAppProps) => {
  return (
    <Tag className={cn('w-full px-5 py-3.5', className)} {...rest}>
      {children}
    </Tag>
  )
}
