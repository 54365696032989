import { useNavigate } from 'react-router-dom'

import { AlunoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { rotasApp } from '@/configurações/rotasApp'
import {
  responseError,
  responseSuccess,
} from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useAlunoAceitarTermos = () => {
  const navigate = useNavigate()
  const { mutate, isLoading, status } = useMutation(endPoint, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['me'])
      navigate(rotasApp.início)
      responseSuccess('Termos aceitos')
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  return { mutate, isLoading, status }
}

async function endPoint(): Promise<AlunoModel> {
  const result = await api().put(rotasApi.alunoAceitarTermos)
  return result.data.data
}
