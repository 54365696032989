import { useForm } from 'react-hook-form'

import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useQuizRespostaCriar = () => {
  const quizRespostasContext = useForm<Schema>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  })

  const {
    mutate: enviarRespostasQuiz,
    isLoading: isLoadingEnviarRespostasQuiz,
    status: statusEnviarRespostasQuiz,
  } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  return {
    enviarRespostasQuiz,
    isLoadingEnviarRespostasQuiz,
    quizRespostasContext,
    statusEnviarRespostasQuiz,
  }
}

async function endPoint(data: Schema): Promise<{ proximaPerguntaId: string }> {
  const result = await api().post(rotasApi.quizRespostaCriar, data)
  return result.data.data
}

const schema = z.object({
  alternativaId: z.string().optional(),
  perguntaId: z.string().optional(),
})

type Schema = z.infer<typeof schema>
