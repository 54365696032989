import { useMetricas } from '../../../hooks/API/nota/useMetricas'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { ChartContainer } from '@/components/ui/chart'
import { useCallback, useState } from 'react'
import { PieChart, Pie, Sector, Cell, Legend } from 'recharts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useListPeriod } from '../../../hooks/API/instituicao/useListPeriodo'
import { Button } from '../../../components/ui/button'
import { rotasApp } from '../../../configurações/rotasApp'
import { Loader2 } from 'lucide-react'

const paletaDeCores = ['#c2d2d2', '#cc4090', '#BBCF00', '#9747ff', '#ffd321']

export default function Metrica() {
  const { data: ListPeriodo } = useListPeriod()

  const [searchBarParams, setSearchParams] = useSearchParams()
  const PeriodID = (ListPeriodo && ListPeriodo[0].id) || ''
  const Period = searchBarParams.get('period') || 'all'

  const { metricas, isLoading } = useMetricas({
    instituicaoPeriodoId: Period && Period !== 'all' ? Period : PeriodID,
  })

  const [activeIndex, setActiveIndex] = useState<any>(0)

  const onPieEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index)
    },
    [setActiveIndex],
  )

  const data = metricas?.materias.map((item) => ({
    name: item.nome,
    value: item.media,
    media: metricas.mediaGeral,
  }))

  const RenderActiveShape = (
    props: any & { cores: string[]; index: number },
  ) => {
    const navigate = useNavigate()
    const RADIAN = Math.PI / 180
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      payload,
      percent,
      cores,
      index,
      value,
    } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + -5) * cos
    const my = cy + (outerRadius + 40) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'
    const fill = cores[index % cores.length]

    const getColor = () => {
      if (Number(payload.media) >= 7) return '#6cff00'
      if (Number(payload.media) < 5) return '#f3ff00'
      return '#ff333f'
    }
   
    return (
      <g className=''>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild className='cursor-pointer'>
              <text
                x={cx}
                y={cy}
                dy={-5}
                textAnchor='middle'
                fontSize={32}
                fill={getColor()}
                className={`font-dm-sans font-semibold`}
              >
                {payload.media}
              </text>
            </TooltipTrigger>
            <TooltipTrigger asChild>
              <text
                x={cx}
                y={cy}
                className='font-dm-sans font-normal'
                dy={24}
                textAnchor='middle'
                fontSize={22}
                fill={'#fff'}
              >
                Média geral
              </text>
            </TooltipTrigger>
            <TooltipContent>
              <p className='mb-4 w-72 text-xl'>
                A Média Geral é uma medida que representa o desempenho médio de
                um aluno em várias matérias. Ela é obtida somando os desempenhos
                individuais de cada matéria e dividindo pelo número total de
                matérias.
              </p>
              <Button
                size='sm'
                type='button'
                onClick={() => navigate(rotasApp.mediageral)}
                className='w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-300'
              >
                Ver mais
              </Button>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill='none'
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />

        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill='#fffff6'
        >
          
          Media: {payload.value}

        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={16}
          textAnchor={textAnchor}
          fill='#fffff6'
        >
          {payload.name}
        </text>
      </g>
    )
  }

  return (
    <div className=' w-full rounded-xl min-h-[450px]  bg-gradient-to-t from-esforce-green-200 to-esforce-pink-200 p-0.5'>
      <div className='background-image-full flex min-h-[450px] flex-col items-center justify-start rounded-xl p-6 gap-6'>
        <div className='w-auto min-w-56 self-end '>
          <Select
            onValueChange={(valorCampo) => {
              if (valorCampo === 'undefined') {
                setSearchParams((params) => {
                  params.delete('period')
                  return params
                })
              } else {
                setSearchParams((params) => {
                  params.set('period', valorCampo)
                  return params
                })
              }
            }}
          >
            <SelectTrigger
              className={`h-12 w-full rounded-xl border-none bg-esforce-black-350 text-sm text-esforce-white-100`}
            >
              <SelectValue placeholder='Selecione um periodo' />
            </SelectTrigger>
            <SelectContent
              className={`border-none bg-esforce-black-350 text-sm text-esforce-white-100`}
            >
              {ListPeriodo?.map((item) => (
                <SelectItem key={item.id} value={item.id || 'all'}>
                  {item?.nome}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {isLoading ? (
          <Loader2 className='animate-spin' />
        ) : (
          <ChartContainer config={{}} className='h-96 w-full'>
            <PieChart >
             
              <Pie
                activeIndex={activeIndex}
                activeShape={
                  <RenderActiveShape
                    cores={paletaDeCores}
                    index={activeIndex}
                  />
                }
                data={data}
                cx='auto'
                cy={140}
                innerRadius={80}
                outerRadius={100}
                dataKey='value'
                onMouseEnter={onPieEnter}
                className='flex w-full items-center justify-center'
              >
                {data &&
                  data.map((entry, index) => {
                    return (
                      <Cell
                        key={`cell-${entry.name}`}
                        fill={paletaDeCores[index % paletaDeCores.length]}
                        stroke='none'
                        className='flex items-center justify-center mb-80'
                      />
                    )
                  })}
              </Pie>
            <Legend
                layout='horizontal'
                align='center'
                verticalAlign='bottom'
                
              />
            </PieChart>
          </ChartContainer>
        )}
      </div>
    </div>
  )
}
