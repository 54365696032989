import { toastUi } from '../toast'

import { AxiosError } from 'axios'

export function responseError(
  err: AxiosError<any, any> | string,
  title?: string,
) {
  let messageError: string
  const duration = 5000

  if (err instanceof AxiosError) {
    messageError = err.response?.data ? err.response.data.error : ''
  } else {
    messageError = err
  }

  if (err instanceof AxiosError && err.code === 'ERR_NETWORK') {
    toastUi({
      title: `Servidor não responde!`,
      type: 'error',
      duration,
    })
  } else if (err instanceof AxiosError && err.code === 'ECONNABORTED') {
    toastUi({
      title: `Sem resposta!`,
      type: 'error',
      duration,
    })
  } else {
    toastUi({
      title,
      description: messageError,
      type: 'error',
      duration,
    })
  }
}

export const responseSuccess = (
  title: string,
  options?: { duration?: number },
) => {
  toastUi({
    title: title,
    type: 'success',
    duration: options?.duration || 3000, // Define uma duração padrão de 3000ms
  })
}
