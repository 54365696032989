import { create } from 'zustand'

export type MenuType =
  | 'Home'
  | 'Quiz'
  | 'Loja'
  | 'Ranking'
  | 'reForce'
  | undefined

interface MenuActiveProps {
  menuActive: MenuType
  setMenuActive: (menu: MenuType) => void
}

export const UseActiveMenuStore = create<MenuActiveProps>((set) => ({
  menuActive: 'Home',
  setMenuActive: (menu) => set({ menuActive: menu }),
}))
