import { useParams } from 'react-router-dom'

import {
  CardVisualizarQuizPesquisaResultadoUi,
  IQuizPerguntas,
} from '@/componentes/Card/CardVisualizarQuizPesquisaResultado/CardVisualizarQuizPesquisaResultadoUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { useQuizPesquisaVisualizar } from '@/hooks/API/quiz/useQuizPesquisaVisualizar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'

export const HistóricoQuizPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Gabarito',
    isBotãoEsquerdo: true,
  })

  const { id } = useParams()
  const { quizPesquisaVisualizar } = useQuizPesquisaVisualizar({
    quizPesquisaId: id,
  })

  return (
    <ContainerAppUi>
      {quizPesquisaVisualizar && (
        <div className='flex flex-col gap-3'>
          <h2 className='mb-3 font-dm-sans text-2xl font-semibold text-esforce-white-100'>
            {quizPesquisaVisualizar.tema}
          </h2>
          {quizPesquisaVisualizar?.Perguntas?.map((pergunta, index) => (
            <CardVisualizarQuizPesquisaResultadoUi
              key={pergunta.id}
              pergunta={pergunta.pergunta}
              alternativas={
                pergunta.Alternativas?.map((alternativa) => {
                  return {
                    id: alternativa.id,
                    selecionada: alternativa.correta,
                    texto: alternativa.alternativa,
                    errada:
                      (quizPesquisaVisualizar.Respostas?.find(
                        (resposta) => resposta.alternativaId === alternativa.id,
                      )?.correta === false &&
                        quizPesquisaVisualizar.Respostas &&
                        quizPesquisaVisualizar.Respostas?.length > 0) ||
                      false,
                  }
                }) as unknown as IQuizPerguntas['alternativas']
              }
              index={index}
            />
          ))}
        </div>
      )}
    </ContainerAppUi>
  )
}
