/* eslint-disable @typescript-eslint/no-unused-vars */
import { api } from '@/api/api'
import { rotasApiAutenticadoV5 } from '@/configurações/rotasApi/rotasApiAutenticadoV5'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

const { path } = rotasApiAutenticadoV5.buscarMatricula
export const useAuthPegarInstituicao = (condicao: Schema) => {
  const { data } = useQuery({
    queryKey: ['instituicao', condicao],
    queryFn: async () => await endPoint(condicao),
  })
  return { data, instituicoes: data?.instituicoes }
}

async function endPoint(
  condicao: Schema,
): Promise<ServiceResponseAuthBuscarMatriculaV5> {
  const result = await api().get(path(condicao.matricula))
  return result.data.data
}

const schema = z.object({
  matricula: z.string().min(1),
})

type Schema = z.infer<typeof schema>

export interface ServiceResponseAuthBuscarMatriculaV5 {
  matricula: string
  instituicoes: Array<{
    id: string
    nome: string
  }>
}
