import { useTermosDeUsoGet } from '@/hooks/API/useTermosDeUsoGet'
import { Loader2 } from 'lucide-react'

export const TermosDeUsoUi = () => {
  const { data } = useTermosDeUsoGet()
  return (
    <>
      {data ? (
        <div
          className='termAndPrivacy flex w-full flex-col gap-3'
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ) : (
        <div className='flex w-full items-center justify-center gap-3'>
          <Loader2 className='animate-spin' />
        </div>
      )}
    </>
  )
}
