import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { useRegulamentoGet } from '@/hooks/API/useRegulamentoGet'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Loader2 } from 'lucide-react'

export const RegulamentosPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Regulamentos aplicativo',
    isBotãoEsquerdo: true,
  })
  const { data } = useRegulamentoGet()

  return (
    <ContainerAppUi>
      {data ? (
        <div
          className='termAndPrivacy flex w-full flex-col gap-3'
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ) : (
        <div className='flex w-full items-center justify-center gap-3'>
          <p className='text-base'>carregando</p>
          <Loader2 className='animate-spin' />
        </div>
      )}
    </ContainerAppUi>
  )
}
