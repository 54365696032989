import { useForm } from 'react-hook-form'

import { ResponsavelModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useCodigoAcesso = () => {
  const storage = new LocalStorage()
  const {
    mutate: codigoAcesso,
    isLoading: codigoAcessoIsLoading,
    status: codigoAcessoStatus,
  } = useMutation(endPoint, {
    onSuccess: (data) => {
      storage.set('codigoAcesso', data.codigoAcesso)
      storage.set('isMenorIdade', data.isMenorIdade ? 'true' : 'false')
      storage.set('matricula', data.matricula)
      storage.set('hasResponsavel', data.responsavel ? 'true' : 'false')
      if (data.responsavel) {
        storage.set('nomeResponsável', data.responsavel.nome)
        storage.set('cpfResponsável', data.responsavel.cpf)
        storage.set('celularResponsável', data.responsavel.telefone)
        storage.set('emailResponsável', data.responsavel.email)
        storage.set('confirmEmailResponsável', data.responsavel.email)
      }
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const codigoAcessoContext = useForm<CodigoAcessoSchema>({
    resolver: zodResolver(codigoAcessoSchema),
    reValidateMode: 'onChange',
  })

  return {
    codigoAcesso,
    codigoAcessoIsLoading,
    codigoAcessoContext,
    codigoAcessoStatus,
  }
}

type ServiceResponseData = {
  codigoAcesso: string
  isMenorIdade: boolean
  matricula: string
  responsavel: ResponsavelModel
}
async function endPoint(
  data: CodigoAcessoSchema,
): Promise<ServiceResponseData> {
  const result = await api().post(rotasApi.codigoAcesso, data)
  return result.data.data
}

const codigoAcessoSchema = z.object({
  codigoAcesso: z
    .string({ message: 'Campo obrigatório' })
    .min(1, { message: 'Campo obrigatório' }),
})

export type CodigoAcessoSchema = z.infer<typeof codigoAcessoSchema>
