import { Navigate } from 'react-router-dom'

import { UsuárioAutenticadoContexto } from '@/contextos/UsuárioAutenticado'
import { SplashPágina } from '@/páginas/publicas/Splash'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useContextSelector } from 'use-context-selector'

export interface IAuthGuardProps {
  PageElement: () => JSX.Element
  redirectPathSuccess: string
}

export const AutenticaçãoCallback = ({
  PageElement,
  redirectPathSuccess,
}: IAuthGuardProps) => {
  const status = useContextSelector(
    UsuárioAutenticadoContexto,
    (value) => value.status,
  )
  const ambiente = import.meta.env.VITE_NODE_ENV
  const storage = localStorage.getItem('@Esforce-wellcome')

  const handleError = () => {
    if (ambiente === 'desenvolvimento') {
      responseError('DEBUG: página não permitida')
      return <PageElement />
    }
    if (storage === null) {
      return <Navigate to={`/wellcome`} />
    }
    return <Navigate to={`/login`} />
  }

  const renderLoadingPage = () => <SplashPágina />

  switch (status) {
    case 'error':
      return handleError()

    case 'success':
      return <PageElement />

    case 'loading':
    case undefined:
      return renderLoadingPage()

    default:
      return renderLoadingPage()
  }
}
