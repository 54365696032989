import { Outlet, useLocation } from 'react-router-dom'

import Container from '../components/container'

import { BarraMenu } from './BarraMenu/BarraMenu'
import { Cabeçalho } from './Cabeçalho/Cabeçalho'

import { UsuárioAutenticadoContexto } from '@/contextos/UsuárioAutenticado'
import { useContextSelector } from 'use-context-selector'

export const LayoutComMenu = () => {
  const isLoading = useContextSelector(
    UsuárioAutenticadoContexto,
    (value) => value.isLoading,
  )
  const location = useLocation()

  const isNotPremioPath = !location.pathname.startsWith('/premio')

  if (isLoading) return <Outlet />

  return (
    <Container className='mx-auto h-screen w-full max-w-[635px] items-center justify-center bg-esforce-black-100 p-0 pt-9'>
      <div className='flex h-full w-full flex-col items-center justify-between overflow-y-hidden'>
        <Cabeçalho />
        <div
          className={`h-full w-full ${!isNotPremioPath ? 'overflow-y-hidden' : 'overflow-y-auto'} pt-2`}
        >
          <Outlet />
        </div>
        <BarraMenu />
      </div>
    </Container>
  )
}
