import moment from 'moment'
import { _Avatar } from './Avatar'
import { ReforceComentarioModel } from '@/@types/Models'
import { DateBR } from '@/utilitários/DateBR'
import Container from '../../../../../components/container'

export const _ComentarioFoto = (props: ComentariosProps) => {
  return (
    <Container className='mb-3 h-fit justify-center gap-2.5 px-5 py-2'>
      <div className='flex items-center gap-1'>
        <_Avatar
          className='size-7 text-xs'
          nome={props.Comentario.Aluno?.nome as string}
          src={props.Comentario.Aluno?.avatarArquivoLink as string}
        />
        <div className='flex items-center gap-1'>
          <p className='text-sm font-bold italic text-esforce-white-100'>
            {props.Comentario.Aluno?.nome}
          </p>
          <p className='text-sm font-thin italic text-esforce-white-100'>
            {props.Comentario.comentario}
          </p>
        </div>
      </div>
      <span className='text-[10px] font-bold text-esforce-green-100'>
        {moment(props.Comentario.criadoEm).from(DateBR.dataNova())}
      </span>
    </Container>
  )
}

interface ComentariosProps {
  Comentario: ReforceComentarioModel
  comFoto?: boolean
}
