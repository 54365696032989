import { createBrowserRouter, RouteObject } from 'react-router-dom'

import { rotasAuth } from './rotasAutenticadas'
import { rotasPublicas } from './rotasPublicas'

const mountRoutes: RouteObject[] = [...rotasPublicas, ...rotasAuth]

export const configRouter = createBrowserRouter(mountRoutes, {
  future: {
    v7_partialHydration: true,
  },
})
