import { useContextoOutletLayoutComNavegação } from '../../../hooks/useLayoutComNavegação'
import { ContainerAppUi } from '../../../componentes/ContainerAppUi'
import Container from '../../../components/container'
import desempenho from '@/assets/desempenho.png'
import media from '@/assets/media.png'
import tabela from '@/assets/tabela.png'

export function MediaGeral() {
  useContextoOutletLayoutComNavegação({
    título: 'Média geral',
    isBotãoEsquerdo: true,
  })

  return (
    <Container className='h-full items-center bg-esforce-black-100'>
      <ContainerAppUi className='flex h-full flex-col gap-8'>
        <ul className='flex flex-col items-center justify-center gap-12 pb-4'>
          <li className='flex flex-col gap-2'>
            <h2 className='font-archivo text-2xl font-semibold'>
              1. O que é Média Geral?
            </h2>
            <p className='mt-2 text-base'>
              A media geral é uma medida que representa o desempenho médio de um
              aluno em várias matérias. Ela é obtida somando os desempenhos
              individuais de cada matéria e dividindo pelo número total de
              matérias.
            </p>
            <strong>Cores de alerta :</strong>
            <ul className='ml-8 list-disc'>
              <li>Verde: Média geral ≥ 7.</li>
              <li>Amarelo: Média geral entre 5 e 6,9</li>
              <li>{'Vermelho: Média geral < 5.'}</li>
            </ul>
          </li>
          <li className='flex flex-col gap-2'>
            <h2 className='font-archivo text-2xl font-semibold'>
              2. Como calcular o Desempenho de uma matéria?
            </h2>
            <p className='text-base'>
              Para cada matéria, calcula-se o Desempenho usando a seguinte
              fórmula:
            </p>
            <img src={desempenho} alt='desempenho' className='w-full' />
            <ul className='ml-8 list-disc'>
              <li>Nota: A nota obtida na matéria.</li>
              <li>
                Presença: A porcentagem de presença ou participação na matéria.
              </li>
              <li>
                Os valores de Nota e Presença são somados e divididos por 2 para
                obter o Desempenho.
              </li>
            </ul>
          </li>
          <li className='flex flex-col gap-2'>
            <h2 className='font-archivo text-2xl font-semibold'>
              3. Como calcular a Média Geral?
            </h2>
            <p className='text-base'>
              Depois de calcular o Desempenho para cada matéria:
            </p>
            <ul className='ml-8 list-decimal'>
              <li>Nota: A nota obtida na matéria.</li>
              <li>Divida essa soma pelo número total de matérias.</li>
            </ul>
            <img src={media} alt='desempenho' className='w-full' />
          </li>

          <li className='flex flex-col gap-2'>
            <h2 className='font-archivo text-2xl font-semibold'>
              4. Passo a Passo do Cálculo
            </h2>
            <p className='mt-2 text-base'>
              Suponha que um aluno tenha 3 matérias e os seguintes valores de
              Nota e Presença:
            </p>
            <img src={tabela} alt='tabela' className='w-full' />
            <span>Passo 1: Calcular o desempenho de cada matéria.</span>
            <ul className='ml-8 list-disc'>
              <li>Matemática: 7</li>
              <li>Português: 8.5</li>
              <li>História: 8</li>
            </ul>
            <span>Passo 2: Somar os desempenhos</span>
            <span>7 + 8.5 + 8 = 23.5</span>
            <span>Passo 3: Dividir pela quantidade de matérias:</span>
            <span>Média Geral = 23.5 / 3 = 7.83</span>
          </li>
          <li className='flex flex-col gap-2'>
            <h2 className='font-archivo text-2xl font-semibold'>
              5. Resumo Final
            </h2>
            <p className='text-base'>
              A Média Geral é obtida somando os desempenhos individuais de todas
              as matérias e dividindo pelo número total de matérias.
            </p>
            <span>
              No exemplo acima, o valor final da Média Geral foi 7.83.
            </span>
          </li>
        </ul>
      </ContainerAppUi>
    </Container>
  )
}
