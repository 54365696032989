import { HistoricoPontuacaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useHistóricosPontuação = (condição: { alunoId: string }) => {
  const { data: historicosPontuacao } = useQuery({
    queryKey: ['historicosPontuacao'],
    queryFn: async () => historicosPontuacaoListar(condição),
  })

  return { historicosPontuacao }
}

async function historicosPontuacaoListar(condição: {
  alunoId: string
}): Promise<HistoricoPontuacaoModel[]> {
  const result = await api().get(
    rotasApi.historicoPontuacaoListarPorAlunoId({
      alunoId: condição.alunoId,
    }),
  )
  return result.data.data
}
