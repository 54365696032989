import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Label } from '@/components/ui/label'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { usePrêmio } from '@/hooks/API/premio/usePrêmio'
import { usePrêmioBuscarAlunoPrêmio } from '@/hooks/API/premio/usePrêmioBuscarAlunoPrêmio'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import Container from '@/components/container'
import goldCoin from '@/assets/gold-coin.svg'
import goldPrisma from '@/assets/gold-prisma.svg'
import { Button } from '@/components/ui/button'
import Carousel from '@/components/carousel'
import { UseModalUi } from '@/components/modal-ui/UseModalUi'
import { useResgatarPremios } from '@/hooks/API/premio/useResgatarPremios'
import { Loader2 } from 'lucide-react'
import { UseActiveUser } from '../../../store/user.store'
import { Skeleton } from '../../../components/ui/skeleton'

export interface IItemCarrinhoLocalStorage {
  id: string
  quantidade: number
}

export const VisualizarPrêmioPágina = () => {
  const { id } = useParams()
  const { premio } = usePrêmio({ id: id || '-' })
  const navigate = useNavigate()
  const { userActive } = UseActiveUser()
  const saldoTotalConta = userActive?.Perfil?.Aluno?.saldoEmPontos || 0
  const {
    UiModal: UiModalResgate,
    onOpen: onOpenResgate,
    onClose: onCloseResgate,
  } = UseModalUi()
  const { UiModal: UiModalComplete, onOpen: onOpenComplete } = UseModalUi()
  const { resgatarPremios, statusResgatarPremios, isLoadingPremio } =
    useResgatarPremios()
  const { prêmioBuscarAluno } = usePrêmioBuscarAlunoPrêmio({ premioId: id })
  const phraseFormatedAsArrayOfObject = premio?.descricao
    .split(/(?<=\w\.)\s+/)
    .map((phrase) => phrase.trim())
    .filter((phrase) => phrase)
    .map((phrase) => ({ item: phrase }))

  useContextoOutletLayoutComNavegação({
    título: premio?.nome || '',
    isBotãoEsquerdo: true,
  })

  const handleResgatarPremios = () => {
    onOpenResgate()
  }

  const onSubmit = (id: string | undefined) => {
    const item = []
    item.push({ id: id || '', quantidade: 1 })
    resgatarPremios(item)
  }

  useEffect(() => {
    if (statusResgatarPremios === 'success') {
      onCloseResgate()
      onOpenComplete()
    }
  }, [statusResgatarPremios])

  return (
    <>
      <UiModalComplete
        callbackBotão={() => navigate(rotasApp.prêmio)}
        textoBotão='Entendido'
        descrição='Seu resgate foi efetuado com sucesso!'
        título='Parabéns!'
      />
      <UiModalResgate
        callbackBotão={() => onSubmit(premio?.id)}
        textoBotão={isLoadingPremio ? 'Resgatando ...' : 'Resgatar premio'}
        descrição='Tem certeza de que deseja resgatar este prêmio? Após efetuar o resgate, não será possível ressarcir os pontos!'
        título='Quase lá!'
      />
      <Container className='flex items-center justify-center gap-8 bg-esforce-black-100 '>
        {id && id?.length > 0 && premio?.id ? (
          <>
            <div className='flex w-full flex-col'>
              {!!premio?.Fotos?.length && <Carousel photos={premio.Fotos} />}
              <ContainerAppUi>
                <div className='flex w-full items-center justify-between gap-2'>
                  <div className='flex flex-1 items-center gap-2 rounded-xl bg-esforce-black-300 p-3'>
                    <div className='flex size-11 items-center justify-center gap-1 rounded-xl border-2 border-esforce-green-200 bg-esforce-green-700'>
                      <img
                        src={goldCoin}
                        alt='perfil usuario'
                        className='size-5'
                      />
                    </div>
                    <div>
                      <p className='font-dm-sans text-sm font-semibold text-white'>
                        {premio.valorEmPontos} pontos
                      </p>
                      <p className='font-dm-sans text-xs font-normal text-[#737373]'>
                        atividade
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-1 items-center gap-2 rounded-xl bg-esforce-black-300 p-3'>
                    <div className='flex size-11 items-center justify-center gap-1 rounded-xl border-2 border-esforce-green-200 bg-esforce-green-700'>
                      <img
                        src={goldPrisma}
                        alt='perfil usuario'
                        className='size-5'
                      />
                    </div>
                    <div>
                      <p className='font-dm-sans text-sm font-semibold text-white'>
                        Em estoque
                      </p>
                      <p className='font-dm-sans text-xs font-normal text-[#737373]'>
                        {premio.estoque} disponiveis
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={handleResgatarPremios}
                    disabled={
                      prêmioBuscarAluno?.quantidade ===
                        premio?.limitePorAluno ||
                      saldoTotalConta < premio.valorEmPontos
                    }
                    size='xl'
                    type='button'
                    className='mt-7 w-full cursor-pointer bg-esforce-green-200 font-dm-sans text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
                  >
                    {prêmioBuscarAluno?.quantidade === premio?.limitePorAluno
                      ? 'Você já resgatou esse prêmio'
                      : 'Resgatar premio'}
                  </Button>
                </div>
                <div className='mt-6 flex flex-col gap-2.5'>
                  <Label className='font-dm-sans text-base font-semibold text-white'>
                    Sobre
                  </Label>
                  <ul className='list-disc px-6 font-dm-sans text-sm font-normal leading-6 text-white'>
                    {phraseFormatedAsArrayOfObject?.map((phrase, index) => (
                      <li key={index}>{phrase.item}</li>
                    ))}
                  </ul>
                </div>
              </ContainerAppUi>
            </div>
          </>
        ) : (
          <VisualizarPremioSkeleton />
        )}
      </Container>
    </>
  )
}

const VisualizarPremioSkeleton = () => {
  return (
    <div className='flex w-full flex-col'>
      <div className='px-5'>
        <Skeleton className='mb-6 h-80 w-full rounded-xl bg-esforce-black-300' />
      </div>
      <ContainerAppUi>
        <div className='flex w-full items-center justify-between gap-2'>
          <Skeleton className='h-[70px] w-44 flex-1 rounded-xl bg-esforce-black-300' />
          <Skeleton className='h-[70px] w-44 flex-1 rounded-xl bg-esforce-black-300' />
        </div>
        <div>
          <Skeleton className='mt-7 h-14 w-full rounded-md bg-esforce-black-300' />
        </div>
        <div className='mt-6 flex flex-col gap-2.5'>
          <Skeleton className='h-6 w-44 rounded-md bg-esforce-black-300' />
          <ul className='flex list-disc flex-col gap-2 font-dm-sans text-sm font-normal leading-6 text-white'>
            <Skeleton className='h-6 w-full rounded-md bg-esforce-black-300' />
            <Skeleton className='h-6 w-full rounded-md bg-esforce-black-300' />
            <Skeleton className='h-6 w-full rounded-md bg-esforce-black-300' />
            <Skeleton className='h-6 w-full rounded-md bg-esforce-black-300' />
            <Skeleton className='h-6 w-full rounded-md bg-esforce-black-300' />
            <Skeleton className='h-6 w-full rounded-md bg-esforce-black-300' />
            <Skeleton className='h-6 w-full rounded-md bg-esforce-black-300' />
          </ul>
        </div>
      </ContainerAppUi>
    </div>
  )
}
