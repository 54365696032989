import { styles } from './styles'

import { IMeuTema } from '@/@types/ChakraUi'
import { extendTheme } from '@chakra-ui/react'

const tema: IMeuTema = extendTheme({
  styles,
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
})

export default tema
