import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'

function AccordionCustom() {
  const content = [
    {
      question: 'Como troco meus pontos?',
      replay:
        'Os pontos conquistados são utilizados na própria plataforma, podendo receber os prêmios em casa ou retirando nos parceiros credenciados.',
    },
    {
      question: 'Se eu reprovar eu perco meus pontos?',
      replay:
        'Sim, os pontos são trocados bimestralmente e só valem para notas acima da média. Caso seja reprovado por falta por exemplo, os pontos serão perdidos em todas as matérias.',
    },
    {
      question: 'Levei uma advertência e agora?',
      replay:
        'O ideal é que isso não aconteça, por hora não terá penalidades, mas futuramente isso pode acontecer. Então, foque nos estudos e seja um aluno acima da média e veja seus méritos ganharem prêmios.',
    },
    {
      question: 'Eu consigo dar meus pontos para alguém?',
      replay:
        'Não. A plataforma foca em você aluno que se esforça e por isso não podemos compartilhar nossos pontos.',
    },
    {
      question:
        'Meus pais precisam concordar com os termos para que eu possa acessar os prêmios?',
      replay:
        'Se você for menor de idade, sim. Após efetuar o cadastro em nosso sistema, as informações serão confirmadas no banco de dados e seus pais ou responsável receberão um e-mail e/ou sms para autorizar o uso. Isso é válido somente para menores de idade.',
    },
    {
      question: 'Fui expulso e agora?',
      replay:
        'Infelizmente os pontos serão zerados automaticamente. Nossa plataforma é uma forma de incentivo ao aluno.',
    },
    {
      question: 'Meus pontos expiram?',
      replay:
        'Sim. Os pontos são inseridos e utilizados anualmente, ao final de cada ano os pontos são zerados. Caso haja algo excepcional comunicaremos através do próprio aplicativo.',
    },
    {
      question: 'Atraso nas mensalidades cancelam minha conta?',
      replay:
        'Sim, a sua conta ficará congelada até que seja confirmado o pagamento ou negociação. Isso só vale para instituições privadas.',
    },
    {
      question: 'Como funciona o Quiz?',
      replay:
        'Para cada Quiz é atribuído pontos e prazo de validade para responder. Ao final do prazo, para os alunos que acertarem todas as questões, receberão os pontos em sua conta.',
    },
    {
      question: 'Como funciona a Pesquisa?',
      replay:
        'Para cada Pesquisa é atribuído pontos e prazo de validade para responder. Ao final do prazo, para os alunos que responderem todas as questões, receberão os pontos em sua conta.',
    },
    {
      question: 'Mudei de escola, o que faço agora?',
      replay:
        'Mudança de escola durante o ano letivo leva ao cancelamento da conta. Se a sua nova escola for parceira Esforce, pode criar uma nova conta e seguir ganhando pontos.',
    },
    {
      question: 'Posso transformar meus pontos em dinheiro?',
      replay:
        'Não. A plataforma contempla os alunos através de prêmios pré-estabelecidos por cada instituição de ensino.',
    },
  ]
  return (
    <Accordion type='single' collapsible className='w-full'>
      {content.map((item, index) => (
        <AccordionItem
          value={`item-${index}`}
          key={`item-${index}`}
          className='mb-6 border-b-esforce-black-300 font-dm-sans'
        >
          <AccordionTrigger className='flex items-center gap-1 px-2 py-0 pb-6 hover:no-underline [&>svg]:stroke-esforce-green-100'>
            <div className='flex items-center justify-center gap-4 font-medium text-esforce-white-100'>
              <p className='flex items-center text-lg font-semibold'>
                {index + 1}
              </p>
              <p className='text-left text-base font-semibold'>
                {item.question}
              </p>
            </div>
          </AccordionTrigger>
          <AccordionContent className='font-rubik text-sm font-normal text-esforce-white-100'>
            {item.replay}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
export default AccordionCustom
