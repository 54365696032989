import { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { BiSolidJoystick } from 'react-icons/bi'
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import Container from '../../../components/container'
import { Input } from '../../../components/input'
import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import { Button } from '../../../components/ui/button'
import { Form } from '../../../components/ui/form'

import { UseActiveUser } from '../../../store/user.store'

import { AvatarUsuárioAtivo } from '@/componentes/AvatarUsuárioAtivo'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import {
  AtualizarSenhaSchemaType,
  useAtualizarSenha,
} from '@/hooks/API/useAtualizarSenha'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Loader2 } from 'lucide-react'

export const AlterarSenhaPágina = () => {
  const { userActive } = UseActiveUser()
  useContextoOutletLayoutComNavegação({
    título: 'Alterar senha',
    isBotãoEsquerdo: true,
  })
  const [atualPassword, setAtualPassword] = useState<boolean>(false)
  const [seePassword, setSeePassword] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false)

  const {
    atualizarSenhaContext,
    atualizarSenha,
    atualizarSenhaStatus,
    atualizarSenhaIsLoading,
  } = useAtualizarSenha()
  const { handleSubmit, control } = atualizarSenhaContext

  const { UiModal, onOpen } = UseModalUi()
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<AtualizarSenhaSchemaType> = (data) => {
    atualizarSenha(data)
  }

  useEffect(() => {
    if (atualizarSenhaStatus === 'success') onOpen()
  }, [atualizarSenhaStatus])

  return (
    <>
      <UiModal
        título='Tudo certo!'
        descrição='Sua senha de acesso foi alterada com sucesso. Utilize-a para acessar sua conta'
        textoBotão='Entendido'
        callbackBotão={() => navigate(rotasApp.meuPerfil)}
      />

      <ContainerAppUi>
        <Container>
          <div className='before:radial-gradient-green after:radial-gradient-pink relative z-[99] my-4 flex h-80 w-full flex-col rounded-xl bg-gradient-to-b from-esforce-pink-100 to-esforce-green-200 p-0.5 before:absolute before:-bottom-4 before:-left-14 before:-z-[99] before:size-[250px] before:rounded-full before:blur-2xl before:content-[""] after:absolute after:-right-28 after:-top-32 after:-z-[99] after:size-[350px] after:rounded-full after:blur-2xl after:content-[""]'>
            <div className='background-image-full flex h-full w-full flex-col items-center justify-between rounded-xl p-8'>
              <AvatarUsuárioAtivo />
              <p className='mb-2 font-dm-sans text-xl font-semibold text-esforce-white-100'>
                {userActive?.Perfil?.Aluno?.nome}
              </p>
              <ul className='flex w-full justify-around md:justify-between'>
                <li className='flex flex-col items-center gap-0.5'>
                  <BiSolidJoystick
                    size={28}
                    className='text-esforce-green-200'
                  />
                  <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
                    Quizes feitos
                  </p>
                  <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
                    120
                  </p>
                </li>

                <li className='flex flex-col items-center gap-0.5'>
                  <BiSolidJoystick
                    size={28}
                    className='text-esforce-green-200'
                  />
                  <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
                    Quizes feitos
                  </p>
                  <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
                    120
                  </p>
                </li>
                <li className='flex flex-col items-center gap-0.5'>
                  <BiSolidJoystick
                    size={28}
                    className='text-esforce-green-200'
                  />
                  <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
                    Quizes feitos
                  </p>
                  <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
                    120
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <Form {...atualizarSenhaContext}>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
              <fieldset className='flex flex-col gap-4'>
                <div className='relative'>
                  <Input
                    control={control}
                    registerName='senhaAtual'
                    textlabel='Senha Atual'
                    placeholder='******'
                    type={!atualPassword ? 'password' : 'text'}
                  />

                  <button
                    type='button'
                    className='absolute right-5 top-11 cursor-pointer'
                    onClick={() => setAtualPassword((prev) => !prev)}
                  >
                    {!atualPassword ? (
                      <PiEyeLight size={24} />
                    ) : (
                      <PiEyeSlash size={24} />
                    )}
                  </button>
                </div>
                <div className='relative'>
                  <Input
                    control={control}
                    registerName='senhaNova'
                    textlabel='Senha Nova'
                    placeholder='******'
                    type={!seePassword ? 'password' : 'text'}
                  />

                  <button
                    type='button'
                    className='absolute right-5 top-11 cursor-pointer'
                    onClick={() => setSeePassword((prev) => !prev)}
                  >
                    {!seePassword ? (
                      <PiEyeLight size={24} />
                    ) : (
                      <PiEyeSlash size={24} />
                    )}
                  </button>
                </div>

                <div className='relative'>
                  <Input
                    control={control}
                    registerName='confirmarSenha'
                    textlabel='Confirmar senha'
                    placeholder='******'
                    type={!confirmPassword ? 'password' : 'text'}
                  />

                  <button
                    type='button'
                    className='absolute right-5 top-11 cursor-pointer'
                    onClick={() => setConfirmPassword((prev) => !prev)}
                  >
                    {!confirmPassword ? (
                      <PiEyeLight size={24} />
                    ) : (
                      <PiEyeSlash size={24} />
                    )}
                  </button>
                </div>
              </fieldset>

              <Button
                size='xl'
                type='submit'
                disabled={atualizarSenhaIsLoading}
                className='mt-7 w-full cursor-pointer bg-esforce-green-200 text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
              >
                {atualizarSenhaIsLoading ? (
                  <>
                    <Loader2 className='animate-spin' />
                  </>
                ) : (
                  'Enviar'
                )}
              </Button>
            </form>
          </Form>
        </Container>
      </ContainerAppUi>
    </>
  )
}
