import { AutenticacaoModel } from '../@types/Models'

import { create } from 'zustand'

type Store = {
  userActive: AutenticacaoModel | null
  setUserActive: (user: AutenticacaoModel | null) => void
}
export const UseActiveUser = create<Store>((set) => ({
  userActive: null,
  setUserActive: (user) => set(() => ({ userActive: user })),
}))
