import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AutenticacaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { rotasApp } from '@/configurações/rotasApp'
import { UseActiveUser } from '@/store/user.store'
import {
  responseError,
  responseSuccess,
} from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useDeletarAluno = () => {
  const { setUserActive } = UseActiveUser()
  const navigate = useNavigate()
  const storage = new LocalStorage()

  const { mutate: mutateDeletarAluno, isLoading: isLoadingDeletarAluno } =
    useMutation(DeletarAluno, {
      onSuccess: async () => {
        storage.remove('token')
        setUserActive(null)
        await queryClient.invalidateQueries(['me'])
        responseSuccess('Conta excluída com sucesso')
        navigate(rotasApp.login)
      },
      onError: (error: AxiosError) => responseError(error),
    })

  const contextDeletarAluno = useForm<DeleteAlunoSchema>({
    resolver: zodResolver(DeletarAlunoSchema),
    reValidateMode: 'onChange',
  })

  return { mutateDeletarAluno, isLoadingDeletarAluno, contextDeletarAluno }
}

async function DeletarAluno(
  data: DeleteAlunoSchema,
): Promise<AutenticacaoModel> {
  const result = await api().post(rotasApi.alunoDeletar, data)
  return result.data.data
}

const DeletarAlunoSchema = z.object({
  senha: z.string().min(1, { message: 'Campo obrigatório' }),
})

export type DeleteAlunoSchema = z.infer<typeof DeletarAlunoSchema>
