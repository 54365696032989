import { Loader2 } from 'lucide-react'

export const QuizLoadingPergunta = (props: QuizLoadingPerguntaProps) => (
  <div
    className='bg-[rgba(0, 0, 0, 0.85)] fixed left-0 top-0 flex h-screen w-screen flex-col items-center justify-center gap-3 '
    hidden={props.hidden}
  >
    <p className='text-xl'>Carregando pergunta</p>
    <Loader2 className='animate-spin' />
  </div>
)

interface QuizLoadingPerguntaProps {
  hidden?: boolean
}
