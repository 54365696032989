import { PremioModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const usePrêmio = ({ id }: { id: PremioModel['id'] }) => {
  const { data: premio } = useQuery({
    queryKey: ['premio', id],
    queryFn: async () => notaListar({ id }),
  })

  return { premio }
}

async function notaListar({
  id,
}: {
  id: PremioModel['id']
}): Promise<PremioModel> {
  const result = await api().get(
    rotasApi.premioBuscarPorId({
      premioId: id,
    }),
  )
  return result.data.data
}
