import { FiPieChart } from 'react-icons/fi'
import { HiOutlineChartBar } from 'react-icons/hi'
import { RiMapPinUserLine } from 'react-icons/ri'

import IconSelector from '../../../components/map-icon'
import { Skeleton } from '../../../components/ui/skeleton'

import { CardSujectsItemsProps } from '../pontos/Pontos.página'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { useNotas } from '@/hooks/API/nota/useNotas'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { UseActiveUser } from '@/store/user.store'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { rotasApp } from '../../../configurações/rotasApp'

export const ExtratoNotasPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Extrato de notas',
    isBotãoEsquerdo: true,
  })

  const { userActive } = UseActiveUser()

  const { notas, isLoading } = useNotas({
    alunoId: userActive?.Perfil?.alunoId ?? '-',
  })

  return (
    <ContainerAppUi>
      <div>
        <div className='from-1% w-full rounded-xl bg-gradient-to-tr from-esforce-green-200 via-transparent via-50% to-esforce-green-200 p-0.5'>
          <div className='h-full w-full rounded-xl bg-esforce-black-200'>
            <div className='flex w-full flex-col items-center justify-center gap-4 p-5'>
              {notas?.length === 0 ? (
                <SvgSemDados />
              ) : isLoading ? (
                Array.from({ length: 6 }).map((_, i) => (
                  <CardSkeleton key={`skeleton-${i}`} />
                ))
              ) : (
                notas?.map((nota) => (
                  <CardSubjectsItems nota={nota} key={nota.id} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </ContainerAppUi>
  )
}

function CardSkeleton() {
  return (
    <div className='flex h-auto w-full flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl p-2.5'>
          <Skeleton className='flex size-11 items-center justify-center rounded-xl bg-esforce-black-300' />
          <div>
            <Skeleton className='mb-1 h-4 w-24 bg-esforce-black-300' />
            <Skeleton className='h-3 w-20 bg-esforce-black-300' />
          </div>
        </div>
        <Skeleton className='h-4 w-16 bg-esforce-black-300' />
      </div>

      <ul className='flex w-full items-center justify-between gap-1 border-t border-t-esforce-black-300 p-3 font-dm-sans text-sm font-normal text-esforce-white-200'>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-4 w-20 bg-esforce-black-300' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-4 w-20 bg-esforce-black-300' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-4 w-20 bg-esforce-black-300' />
        </li>
      </ul>
    </div>
  )
}

function CardSubjectsItems({ nota }: CardSujectsItemsProps) {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => {
        navigate(rotasApp.materiaVisualizar(nota.materiaId, nota.Materia?.nome))
      }}
      className='flex h-auto w-full flex-col items-center justify-center gap-2 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'
    >
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl'>
          <div className='flex size-11 items-center justify-center rounded-xl bg-esforce-pink-100'>
            <IconSelector name={nota.Materia?.nome || ''} />
          </div>
          <div>
            <p className='font-dm-sans text-sm font-semibold text-white'>
              {nota.Materia?.nome}
            </p>
            <p className='font-dm-sans text-xs font-normal text-[#737373]'>
              atividade
            </p>
          </div>
        </div>
        <p className='font-rubik text-xs font-medium'>
          {moment(nota.criadoEm).format('DD/MM/YY')}
        </p>
      </div>

      <ul className='flex w-full items-center justify-between gap-0.5 border-t border-t-esforce-black-300 pt-2 font-dm-sans text-xs font-normal text-esforce-white-200'>
        <li className='flex items-center justify-center gap-0.5'>
          <FiPieChart size={16} />
          <span className=''> {nota.periodo}° Bimestre</span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <HiOutlineChartBar size={16} />
          <span className=''>
            Nota: <strong>{nota.nota}</strong>
          </span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <RiMapPinUserLine size={16} />
          <span className=''>
            Presença: <strong>{nota.presenca}%</strong>
          </span>
        </li>
      </ul>
    </div>
  )
}
