import { useEffect } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import Container from '@/components/container'
import { Input } from '@/components/input'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'

import { rotasApp } from '@/configurações/rotasApp'
import {
  RecuperarSenhaSchema,
  useRecuperarSenha,
} from '@/hooks/API/autenticação/useRecuperarSenha'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { Loader2 } from 'lucide-react'

export const RecuperarSenhaMatrículaPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Recuperar senha',
    isBotãoEsquerdo: true,
  })

  const navigate = useNavigate()
  const storage = new LocalStorage()
  const {
    recuperarSenha,
    recuperarSenhaContext,
    recuperarSenhaIsLoading,
    recuperarSenhaStatus,
    recuperarSenhaData,
  } = useRecuperarSenha()

  const { handleSubmit, control } = recuperarSenhaContext

  const { UiModal, onOpen } = UseModalUi()

  const onSubmit: SubmitHandler<RecuperarSenhaSchema> = (data) => {
    recuperarSenha(data)
  }

  useEffect(() => {
    if (recuperarSenhaStatus === 'success' && recuperarSenhaData) {
      onOpen()
      storage.set('email', recuperarSenhaData.email)
    }
  }, [recuperarSenhaStatus, JSON.stringify(recuperarSenhaData)])

  return (
    <>
      <UiModal
        callbackBotão={() => navigate(rotasApp.recuperarSenhaCódigo)}
        textoBotão='Entendido'
        descrição={`Enviamos um código de 6 dígitos para o e-mail ${recuperarSenhaData?.email}. Informe esse código para concluir sua alteração.`}
        título='Código de recuperação enviado com sucesso!'
      />
      <Container className='flex flex-col items-center justify-start gap-10 bg-esforce-black-200 px-5 py-8'>
        <hgroup className='max-full flex flex-col items-center justify-center gap-5'>
          <img
            alt='logo da esforce na cor verde'
            src='../logo-colour.svg'
            className='h-9 w-40'
          />
          <p className='text-start font-inter text-base font-normal leading-6'>
            Informe seu email para receber o código de recuperação da senha.
          </p>
        </hgroup>

        <Form {...recuperarSenhaContext}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <fieldset className='flex flex-col gap-4'>
              <Input
                control={control}
                registerName='email'
                textlabel='Email'
                placeholder=''
                type='text'
              />
            </fieldset>

            <Button
              size='xl'
              type='submit'
              disabled={recuperarSenhaIsLoading}
              className='mt-7 w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
            >
              {recuperarSenhaIsLoading ? (
                <>
                  <Loader2 className='animate-spin' />
                </>
              ) : (
                'Enviar'
              )}
            </Button>
          </form>
        </Form>
      </Container>
    </>
  )
}
