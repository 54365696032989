import { BiSolidJoystick } from 'react-icons/bi'

import { AvatarUsuárioAtivo } from '../../componentes/AvatarUsuárioAtivo'
import { UseActiveUser } from '../../store/user.store'

export default function ProfileCard() {
  const { userActive } = UseActiveUser()

  return (
    <div className='before:radial-gradient-green after:radial-gradient-pink relative z-[99] my-4 flex h-80 w-full flex-col rounded-xl bg-gradient-to-b from-esforce-pink-100 to-esforce-green-200 p-0.5 before:absolute before:-bottom-4 before:-left-14 before:-z-[99] before:size-[250px] before:rounded-full before:blur-2xl before:content-[""] after:absolute after:-right-28 after:-top-32 after:-z-[99] after:size-[350px] after:rounded-full after:blur-2xl after:content-[""]'>
      <div className='background-image-full flex h-full w-full flex-col items-center justify-between rounded-xl p-8'>
        <AvatarUsuárioAtivo />
        <p className='mb-2 font-dm-sans text-xl font-semibold text-esforce-white-100'>
          {userActive?.Perfil?.Aluno?.nome}
        </p>
        <ul className='grid w-full grid-cols-5 items-center justify-between'>
          <li className='flex flex-col items-center gap-0.5'>
            <BiSolidJoystick size={28} className='text-esforce-green-200' />
            <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
              Quizes
            </p>
            <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
              {userActive?.atividades.quiz}
            </p>
          </li>

          <li className='col-span-3 flex flex-col items-center gap-0.5'>
            <BiSolidJoystick size={28} className='text-esforce-green-200' />
            <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
              Atividades extras
            </p>
            <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
              {userActive?.atividades.pontoExtra}
            </p>
          </li>
          <li className='flex flex-col items-center gap-0.5'>
            <BiSolidJoystick size={28} className='text-esforce-green-200' />
            <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
              Pesquisas
            </p>
            <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
              {userActive?.atividades.pesquisa}
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}
