import { FaCheck } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'

import { Label } from '../../../components/ui/label'

export interface IQuizPerguntasAlternativas {
  id: number
  texto: string
  selecionada: boolean
  errada?: boolean
}

export interface IQuizPerguntas {
  id: number
  pergunta: string
  alternativas: IQuizPerguntasAlternativas[]
}

interface RadioUiProps {
  selecionado: boolean
  errado?: boolean
  respondendo?: boolean
}

export const CardVisualizarQuizPesquisaResultadoUi = ({
  pergunta,
  alternativas,
  index,
}: {
  pergunta: string
  alternativas: IQuizPerguntasAlternativas[]
  index: number
}) => {
  return (
    <div className='flex flex-col gap-2'>
      <Label>
        <h3 className='font-dm-sans text-xl font-semibold leading-[30px] text-esforce-white-100'>
          Pergunta {index + 1}
        </h3>
        <p className='mt-1 font-dm-sans text-base font-normal leading-[21px] text-esforce-white-100'>
          {pergunta}
        </p>
      </Label>

      <fieldset className='w-full rounded-xl bg-esforce-black-300 p-4'>
        {alternativas.map((alternativa, index) => (
          <div
            className='flex w-full items-start justify-start p-3'
            key={index}
          >
            <div
              className={`flex h-[52px] w-full items-center gap-3 ${alternativa.errada && 'border border-[#D61C1C] bg-[#D61C1C]/20'} justify-start rounded-xl ${alternativa.selecionada && 'border border-esforce-green-200 bg-esforce-green-200/20'} p-3`}
            >
              <div className='flex items-center justify-center'>
                <RadioUi
                  selecionado={alternativa.selecionada}
                  errado={alternativa.errada}
                />
              </div>
              <Label>
                <strong>{String.fromCharCode(65 + index)}.</strong>{' '}
                {alternativa.texto}
              </Label>
            </div>
          </div>
        ))}
      </fieldset>
    </div>
  )
}

export const RadioUi = ({ selecionado, errado, respondendo }: RadioUiProps) => {
  const baseClasses = 'size-4 rounded-full border  relative'
  let background = selecionado ? `bg-esforce-green-200 ` : 'bg-transparent'

  if (respondendo && selecionado && !errado) {
    background = 'bg-esforce-green-200 border-esforce-green-300/20'
  } else if (errado) {
    background = 'bg-[#E92F32]/20  border-[#E92F32] '
  }

  return (
    <div
      className={`${baseClasses} ${background} flex items-center justify-center`}
    >
      {selecionado && <FaCheck size={10} className='text-esforce-black-100' />}
      {errado && <IoClose size={12} className='text-esforce-white-200' />}
    </div>
  )
}
