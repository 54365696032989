import { useEffect } from 'react'

import { QuizPesquisaModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useQuizPesquisaVisualizar = (props: Props) => {
  const {
    data: quizPesquisaVisualizar,
    status,
    error,
  } = useQuery({
    queryKey: ['quiz-pesquisa-visualizar'],
    queryFn: async () => await endPoint(props.quizPesquisaId),
    enabled: !!props.quizPesquisaId,
  })

  useEffect(() => {
    if (status === 'error') {
      const erro = error as AxiosError
      responseError(erro)
    }
  }, [status, error, JSON.stringify(quizPesquisaVisualizar)])

  return { quizPesquisaVisualizar }
}

async function endPoint(
  id: Props['quizPesquisaId'],
): Promise<QuizPesquisaModel> {
  const result = await api().get(rotasApi.quizPesquisaVisualizar(id))
  return result.data.data
}

interface Props {
  quizPesquisaId?: string
}
