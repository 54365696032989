import { useNavigate } from 'react-router-dom'

import Container from '../../components/container'
import { Button } from '../../components/ui/button'

import { TermosDeUsoUi } from '@/componentes/TermosDeUsoUi'
import { rotasApp } from '@/configurações/rotasApp'
import { useAlunoAceitarTermos } from '@/hooks/API/aluno/useAlunoAceitarTermos'
import { Loader2 } from 'lucide-react'

export const TermosDeUsoPagina = () => {
  const { mutate, isLoading } = useAlunoAceitarTermos()
  const navigate = useNavigate()

  const handleVoltar = () => {
    localStorage.remove('token')
    navigate(rotasApp.login)
  }

  return (
    <Container className='bg-esforce-black-400 mx-auto my-0 flex h-full w-screen max-w-[550px] flex-col items-center justify-around gap-8 py-12'>
      <h1 className='text-center font-dm-sans text-2xl leading-[0] text-esforce-green-200'>
        Termos de uso
      </h1>

      <TermosDeUsoUi />

      <div className='flex w-full flex-col gap-3 px-2'>
        <Button
          size='xl'
          type='submit'
          disabled={isLoading}
          onClick={() => mutate()}
          className='w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 transition-all duration-300 hover:scale-95 hover:bg-esforce-green-100'
        >
          {isLoading ? (
            <>
              <Loader2 className='animate-spin' />
            </>
          ) : (
            'Aceitar termos de uso'
          )}
        </Button>
        <Button
          size='xl'
          onClick={handleVoltar}
          className='flex w-full cursor-pointer items-center gap-2 bg-esforce-black-350 font-archivo text-sm font-bold text-esforce-white-100 transition-all duration-300 hover:scale-95'
        >
          Voltar
        </Button>
      </div>
    </Container>
  )
}
