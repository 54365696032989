import { toast } from 'sonner'
interface ToastOptions {
  title?: string
  description?: string
  type?: 'success' | 'error' | 'info' | 'warning'
  duration?: number
}

export const toastUi = ({
  title,
  description,
  type = 'success',
  duration = 2000,
}: ToastOptions) => {
  toast[type](title, { description, duration })
}
