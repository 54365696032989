import React from 'react'
import { useNavigate } from 'react-router-dom'

import { rotasApp } from '../../configurações/rotasApp'
import { Button } from '../ui/button'

import goldPrisma from '@/assets/gold-prisma.svg'
import goldQuestion from '@/assets/gold-question.svg'
import light from '@/assets/lighting.svg'
import { useQuizStatus } from '@/hooks/API/quiz/useQuizStatus'
import { ResponseQuizPesquisaListar } from '@/páginas/autenticadas/quizPesquisas/useQuizPesquisaListar'
import { Loader2 } from 'lucide-react'

interface ModalQuizConferirPesquisaProps {
  quizPesquisaSelecionada: ResponseQuizPesquisaListar | undefined
}
export const UseModalConferirQuizPesquisa = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const navigate = useNavigate()
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const ConferirModal = ({
    quizPesquisaSelecionada,
  }: ModalQuizConferirPesquisaProps) => {
    const { quizStatus, isLoading } = useQuizStatus({
      quizId: quizPesquisaSelecionada?.id,
      enabled: isOpen,
    })

    const respostasCount = quizStatus?.respostasCount
    const totalPerguntas = quizPesquisaSelecionada?.totalPerguntas

    function handleOnClick() {
      const finalizado = respostasCount === totalPerguntas

      if (quizPesquisaSelecionada?.tipo === 'pesquisa' && !finalizado) {
        navigate(
          `${rotasApp.pesquisa}?pesquisaId=${quizPesquisaSelecionada?.id}`,
        )
      } else if (quizPesquisaSelecionada?.tipo === 'quiz' && !finalizado) {
        navigate(`${rotasApp.quiz}?quizId=${quizPesquisaSelecionada?.id}`)
      } else if (finalizado) {
        navigate(rotasApp.histórico)
      }
    }

    return (
      <div
        className={`fixed inset-0 z-[999] flex items-center justify-center ${isOpen ? 'block' : 'hidden'}`}
      >
        <div
          onClick={onClose}
          className='absolute inset-0 bg-opacity-40 backdrop-blur-lg'
        />
        <div className='relative mx-2 flex w-full max-w-[335px] flex-col rounded-xl bg-gradient-to-b from-esforce-pink-100 to-esforce-green-200 p-0.5'>
          <div className='background-modal-quiz-prequisa flex h-full w-full flex-col items-center justify-between gap-6 rounded-xl p-8'>
            <div className='flex w-full flex-col items-center gap-1'>
              <p className='font-dm-sans text-xs font-normal'>
                {quizPesquisaSelecionada?.tipo === 'quiz'
                  ? 'Novo Quiz '
                  : 'Nova Pesquisa '}
              </p>
              <h2 className='font-dm-sans text-[28px] font-normal text-esforce-white-100'>
                {quizPesquisaSelecionada?.tema}
              </h2>
            </div>
            <div className='flex w-full flex-row items-center justify-between gap-3'>
              <div className='flex h-10 flex-1 items-center justify-center gap-1 rounded-[10px] border border-esforce-green-200 bg-esforce-green-700 px-2 py-4'>
                <img
                  src={goldQuestion}
                  alt='gold coin icon'
                  className='size-4'
                />
                <p className='text-sm font-normal text-esforce-white-100'>
                  {totalPerguntas} Perguntas
                </p>
              </div>

              <div
                hidden={
                  quizPesquisaSelecionada &&
                  quizPesquisaSelecionada?.pontuacao > 0
                    ? false
                    : true
                }
                className='flex h-10 flex-1 items-center justify-center gap-1 rounded-[10px] border border-esforce-green-200 bg-esforce-green-700 px-2 py-4'
              >
                <img src={goldPrisma} alt='gold coin icon' className='size-4' />
                <p className='text-sm font-normal text-esforce-white-100'>
                  {quizPesquisaSelecionada?.pontuacao} Pontos
                </p>
              </div>
            </div>
            <div className='flex w-full flex-row items-center justify-between gap-3'>
              <div className='flex h-10 flex-1 items-center justify-center gap-1 rounded-[10px] border border-esforce-green-200 bg-esforce-green-700 px-2 py-4'>
                <img src={light} alt='light icon' className='size-4' />
                <p className='text-sm font-normal text-esforce-white-100'>
                  {quizPesquisaSelecionada?.tipo === 'quiz'
                    ? 'Min 15 segundos '
                    : 'Sem tempo limite '}
                </p>
              </div>
            </div>

            <div className='flex w-full flex-col gap-3'>
              <Button
                size='xl'
                className='w-full cursor-pointer rounded-xl bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
                onClick={handleOnClick}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 className='animate-spin' />
                ) : respostasCount === 0 ? (
                  'Iniciar Quiz'
                ) : respostasCount === totalPerguntas ? (
                  'Ver histórico'
                ) : (
                  'Continuar'
                )}
              </Button>
              <Button
                size='xl'
                onClick={onClose}
                className='text-esforce-white-50 w-full cursor-pointer rounded-xl border bg-esforce-black-200 font-archivo text-sm font-bold text-esforce-white-100 hover:bg-esforce-black-100'
              >
                Fechar
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return {
    ConferirModal,
    onOpen,
    onClose,
  }
}
