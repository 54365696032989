import { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import Container from '../../../components/container'
import { Input } from '../../../components/input'
import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import { Button } from '../../../components/ui/button'
import { Form } from '../../../components/ui/form'

import { rotasApp } from '@/configurações/rotasApp'
import {
  RedefinirSenhaSenhaSchema,
  useRedefinirSenha,
} from '@/hooks/API/autenticação/useRedefinirSenha'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { Loader2 } from 'lucide-react'

export const RecuperarSenhaNovaSenhaPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Recuperar senha' })
  const [seePassword, setSeePassword] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false)

  const {
    isLoadingRedefinirSenha,
    redefinirSenha,
    redefinirSenhaSenhaContext,
    statusRedefinirSenha,
  } = useRedefinirSenha()

  const { setValue, handleSubmit, control, watch } = redefinirSenhaSenhaContext

  const { UiModal, onOpen } = UseModalUi()
  const navigate = useNavigate()
  const storage = new LocalStorage()

  const onSubmit: SubmitHandler<RedefinirSenhaSenhaSchema> = (data) => {
    redefinirSenha(data)
  }

  useEffect(() => {
    if (statusRedefinirSenha === 'success') {
      onOpen()
    }
  }, [statusRedefinirSenha])

  useEffect(() => {
    setValue('matricula', storage.get('matricula') || ' ')
    setValue('codigoAcesso', storage.get('codigoAcesso') || ' ')
  }, [])

  return (
    <>
      <UiModal
        callbackBotão={() => navigate(rotasApp.login)}
        textoBotão='Entendido'
        descrição='Sua nova senha de acesso foi criada com sucesso. Utilize-a para acessar sua conta.'
        título='Tudo certo!'
      />
      <Container className='flex flex-col items-center justify-center gap-10 bg-esforce-black-200 px-5 py-8'>
        <hgroup className='max-full flex flex-col items-center justify-center gap-5'>
          <img
            alt='logo da esforce na cor verde'
            src='../logo-colour.svg'
            className='h-9 w-40'
          />
          <p className='font-archivo text-xl font-semibold text-esforce-green-200'>
            Nova senha de acesso
          </p>
          <p className='text-center font-inter text-base font-normal leading-6'>
            Estamos quase lá! Digite abaixo sua nova senha.
          </p>
        </hgroup>

        <Form {...redefinirSenhaSenhaContext}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <fieldset className='flex flex-col gap-4'>
              <div className='relative'>
                <Input
                  control={control}
                  registerName='senha'
                  textlabel='Senha'
                  placeholder=''
                  type={!seePassword ? 'password' : 'text'}
                />

                <button
                  type='button'
                  className='absolute right-5 top-11 cursor-pointer'
                  onClick={() => setSeePassword((prev) => !prev)}
                >
                  {!seePassword ? (
                    <PiEyeLight size={24} />
                  ) : (
                    <PiEyeSlash size={24} />
                  )}
                </button>
              </div>

              <div className='relative'>
                <Input
                  disabled={!watch('senha')}
                  control={control}
                  registerName='confirmarSenha'
                  textlabel='Confirmar senha'
                  placeholder='******'
                  type={!confirmPassword ? 'password' : 'text'}
                />

                <button
                  type='button'
                  className='absolute right-5 top-11 cursor-pointer'
                  onClick={() => setConfirmPassword((prev) => !prev)}
                  disabled={!watch('senha')}
                >
                  {!confirmPassword ? (
                    <PiEyeLight size={24} />
                  ) : (
                    <PiEyeSlash size={24} />
                  )}
                </button>
              </div>
            </fieldset>

            <Button
              size='xl'
              type='submit'
              disabled={isLoadingRedefinirSenha}
              className='mt-7 w-full cursor-pointer bg-esforce-green-200 text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
            >
              {isLoadingRedefinirSenha ? (
                <>
                  <Loader2 className='animate-spin' />
                </>
              ) : (
                'Enviar'
              )}
            </Button>
          </form>
        </Form>
      </Container>
    </>
  )
}
