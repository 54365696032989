
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import AccordionCustom from '../../../components/accordion'

export const PerguntasFrequentesPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Perguntas frequentes',
    isBotãoEsquerdo: true,
  })

  return (
    <ContainerAppUi className='px-7'>
      <AccordionCustom />
    </ContainerAppUi>
  )
}
