import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AutenticacaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { rotasApp } from '@/configurações/rotasApp'
import { UseActiveUser } from '@/store/user.store'
import {
  responseError,
  responseSuccess,
} from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { toastUi } from '@/utilitários/toast'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useLogin = () => {
  const { setUserActive } = UseActiveUser()
  const storage = new LocalStorage()
  const navigate = useNavigate()

  const { mutate: login, isLoading: isLoadingLogin } = useMutation(signIn, {
    onSuccess: async (data) => {
      if (data.acesso === 'administrador' || data.acesso === 'escola') {
        return toastUi({
          title: 'Acesso negado',
          description: 'Uma conta é necessária para acessar o sistema',
          duration: 25000,
          type: 'error',
        })
      }
      await queryClient.invalidateQueries(['me'])
      setUserActive(data)
      if (data?.token) storage.set('token', data.token)
      responseSuccess('Login realizado')
      navigate(rotasApp.início)
    },
    onError: (error: AxiosError) => responseError(error),
  })

  const loginContext = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
    reValidateMode: 'onChange',
  })

  return { login, isLoadingLogin, loginContext }
}

async function signIn(data: LoginSchema): Promise<AutenticacaoModel> {
  const result = await api().post(rotasApi.signIn, data)
  return result.data.data
}

const loginSchema = z.object({
  email: z
    .string({ message: 'Campo obrigatório' })
    .email({ message: 'E-mail inválido!' })
    .min(1, { message: 'Campo obrigatório' }),
  senha: z
    .string({ message: 'Campo obrigatório' })
    .min(1, { message: 'Campo obrigatório' }),
})

export type LoginSchema = z.infer<typeof loginSchema>
