import { useForm } from 'react-hook-form'

import { rotasApiAutenticadoV5 } from '../../../configurações/rotasApi/rotasApiAutenticadoV5'

import { AlunoModel } from '@/@types/Models'
import { api } from '@/api/api'
import {
  responseError,
  responseSuccess,
} from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

const { path: PATH } = rotasApiAutenticadoV5.completarVerificacao

export const useAlunoCadastroPrimeiroAcesso = () => {
  const {
    mutate: cadastroAluno,
    isLoading: cadastroAlunoIsLoading,
    status: cadastroAlunoStatus,
  } = useMutation(endPoint, {
    onSuccess: () => {
      responseSuccess('Cadastro realizado')
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const cadastroAlunoContext = useForm<CadastroAlunoSchema>({
    resolver: zodResolver(cadastroAlunoSchema),
    reValidateMode: 'onSubmit',
  })

  return {
    cadastroAluno,
    cadastroAlunoContext,
    cadastroAlunoIsLoading,
    cadastroAlunoStatus,
  }
}

async function endPoint(data: CadastroAlunoSchema): Promise<AlunoModel> {
  const result = await api().post(PATH, data)
  return result.data.data
}

export const cadastroAlunoSchema = z
  .object({
    matricula: z.string(),
    instituicaoId: z.string(),
    email: z
      .string({ message: 'Campo obrigatório' })
      .min(1, { message: 'Campo obrigatório' }),
    senha: z
      .string({ message: 'Campo obrigatório' })
      .min(1, { message: 'Campo obrigatório' }),
    confirmacaoSenha: z.string().optional(),
  })
  .refine((data) => data.senha === data.confirmacaoSenha, {
    message: 'Senhas não conferem',
    path: ['confirmacaoSenha'],
  })

export type CadastroAlunoSchema = z.infer<typeof cadastroAlunoSchema>
