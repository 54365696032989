import { cn } from '@/lib/utils'

interface WrapperProps {
  as?: React.ElementType
  id?: string
  className?: string
  children: React.ReactNode
}

function Container({ className, children, as: Tag = 'div', id }: WrapperProps) {
  return (
    <Tag className={cn('flex w-full flex-col gap-9', className)} id={id}>
      {children}
    </Tag>
  )
}
export default Container
