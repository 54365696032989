import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Container from '../components/container'

import { BarraMenu } from './BarraMenu/BarraMenu'
import { Cabeçalho } from './Cabeçalho/Cabeçalho'
import { ContainerAppUi } from './ContainerAppUi'
import { NavegaçãoUi } from './NavegaçãoUi'

import { UsuárioAutenticadoContexto } from '@/contextos/UsuárioAutenticado'
import { IContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Icon } from '@phosphor-icons/react'
import { useContextSelector } from 'use-context-selector'

export const LayoutComNavegação = () => {
  const [isBotãoEsquerdo, setIsBotãoEsquerdo] = useState<boolean>(false)
  const [callbackBotãoDireito, setCallbackBotãoDireito] = useState<() => void>()
  const [íconeDireita, setÍconeDireita] = useState<Icon | undefined>(undefined)
  const [título, setTítulo] = useState<string>('')
  const location = useLocation()

  const isNotPerfilPath = !location.pathname.startsWith('/meu-perfil')
  const isNotPassowordPath = !location.pathname.startsWith('/recuperar-senha')
  const isNotQuiz = !location.pathname.startsWith('/quiz')

  const isNotpasswordPath = !location.pathname.startsWith('/recuperar-senha')

  const contextoOutlet: IContextoOutletLayoutComNavegação = {
    setCallbackBotãoDireito,
    setIsBotãoEsquerdo,
    setTítulo,
    setÍconeDireita,
  }

  const isLoading = useContextSelector(
    UsuárioAutenticadoContexto,
    (value) => value.isLoading,
  )
  if (isLoading) return <Outlet />

  return (
    <Container
      className={`mx-auto my-0 h-screen max-w-[635px] items-center justify-between gap-1 overflow-hidden ${!isNotpasswordPath ? 'bg-esforce-black-200' : 'bg-esforce-black-100'} pt-9`}
    >
      {isNotPerfilPath && isNotPassowordPath && isNotQuiz && <Cabeçalho />}
      <ContainerAppUi>
        <NavegaçãoUi
          callbackBotãoDireito={callbackBotãoDireito}
          isBotãoEsquerdo={isBotãoEsquerdo}
          título={título}
          íconeDireito={íconeDireita}
        />
      </ContainerAppUi>

      <div className='h-full w-full flex-col gap-3 overflow-auto'>
        <Outlet context={contextoOutlet} />
      </div>
      {isNotPassowordPath && <BarraMenu />}
    </Container>
  )
}
