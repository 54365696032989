import { useParams, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Container from '../../../components/container'
import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import { Skeleton } from '../../../components/ui/skeleton'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import { SvgSemDados } from '../../../componentes/SvgSemDados'
import { useNotas } from '../../../hooks/API/nota/useNotas'
import { useContextoOutletLayoutComNavegação } from '../../../hooks/useLayoutComNavegação'
import { UseActiveUser } from '../../../store/user.store'
import { useQuizPesquisaListar } from '../quizPesquisas/useQuizPesquisaListar'

import goldCoin from '@/assets/gold-coin.svg'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { rotasApp } from '@/configurações/rotasApp'
import { UseMenuActive } from '@/hooks/useMenuAtivo'
import { Trophy } from '@phosphor-icons/react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts'
import { useListPeriod } from '../../../hooks/API/instituicao/useListPeriodo'
import { useEffect, useState } from 'react'

export const MateriaVisualizar = () => {
  const { data: ListPeriodo } = useListPeriod()
  UseMenuActive('Ranking')
  const params = useParams()
  const { quizPesquisaListar, isLoading } = useQuizPesquisaListar()
  const { userActive } = UseActiveUser()
  const [searchBarParams, setSearchParams] = useSearchParams()
  const Period = searchBarParams.get('period') || 'all'
  const [periodSelected, setPeriodoSelect] = useState<any>({})

  const { notas, isLoading: notasIsLoading } = useNotas({
    alunoId: userActive?.Perfil?.alunoId ?? '-',
    materiaId: params.id,
    instituicaoPeriodoId: Period && Period !== 'all' ? Period : undefined,
  })

  const averageGrade =
    notas && notas.reduce((sum, item) => sum + item.nota, 0) / notas.length
  const averagePresenca =
    notas && notas.reduce((sum, item) => sum + item.presenca, 0) / notas.length

  const chartData =
    notas &&
    notas.map((item) => ({
      period: item.InstituicaoPeriodo?.nome,
      presenca: item.presenca / 10,
      nota: item.nota,
    }))

  const chartConfig = {
    presenca: {
      label: 'Presença',
      color: 'hsl(var(--chart-1))',
    },
    nota: {
      label: 'Nota',
      color: 'hsl(var(--chart-2))',
    },
    media: {
      label: 'Média',
      color: 'hsl(var(--chart-3))',
    },
  } satisfies ChartConfig

  useContextoOutletLayoutComNavegação({
    título: params.name || '',
    isBotãoEsquerdo: true,
    íconeDireita: Trophy,
    rotaBotãoDireito: rotasApp.pontos,
  })
  const navigate = useNavigate()

  const { UiModal, onClose } = UseModalUi()


  return (
    <Container className='h-full items-center bg-esforce-black-100'>
      <UiModal
        callbackBotão={() => {
          onClose()
          navigate(rotasApp.início)
        }}
        título='Ops!'
        descrição='Você ainda não possui pontos atribuídos no sistema.'
        textoBotão='Entendido'
      />
      <ContainerAppUi className='flex flex-col gap-8'>
        <div className='mx-1.5 flex min-h-[450px] w-full flex-col rounded-xl bg-gradient-to-b from-esforce-pink-100 to-esforce-green-200 p-0.5'>
          <div className='background-image-full flex h-full w-full flex-col items-center gap-6 rounded-xl p-8'>
            <div className='min-w-36 self-end'>
              <Select
                onValueChange={(valorCampo) => {
                  if (valorCampo === 'undefined') {
                    setSearchParams((params) => {
                      params.delete('period')
                      return params
                    })
                  } else {
                    setSearchParams((params) => {
                      params.set('period', valorCampo)
                      return params
                    })
                  }
                }}
              >
                <SelectTrigger
                  className={`h-12 w-full rounded-xl border-none bg-esforce-black-350 text-sm text-esforce-white-100`}
                >
                  <SelectValue placeholder='Selecione um periodo' />
                </SelectTrigger>
                <SelectContent
                  className={`border-none bg-esforce-black-350 text-sm text-esforce-white-100`}
                >
                  <SelectItem key='undefined' value='all'>
                    Todos
                  </SelectItem>
                  {ListPeriodo?.map((item) => (
                    <SelectItem key={item.id} value={item.id || 'all'}>
                      {item?.nome}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
     
            </div>

            <div className='relative w-full bg-esforce-black-100'>
              <p className='absolute left-0 top-1/3 -rotate-90 font-inter text-base font-semibold text-esforce-white-200'>
                Nota
              </p>
              <ChartContainer config={chartConfig} className='h-[250px] w-full'>
                <BarChart accessibilityLayer data={chartData}>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey='period'
                    interval={0}
                    tickLine={true}
                    tickMargin={10}
                    axisLine={true}
                    tickFormatter={(value) => value.slice(0, 4)}
                  />
                  <YAxis ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} interval={0} />

                  <ChartTooltip
                    cursor={{ fill: '#000', opacity: 0.1 }}
                    content={<ChartTooltipContent />}
                  />
                  <Bar
                    dataKey='presenca'
                    fill='var(--color-presenca)'
                    radius={4}
                  />
                  <Bar dataKey='nota' fill='var(--color-nota)' radius={4} />
                  <ReferenceLine
                    y={7}
                    label='Média'
                    stroke='var(--color-media)'
                    strokeDasharray='3 3'
                  />
                </BarChart>
              </ChartContainer>
              <p className='pt-1 text-center font-inter text-base font-semibold text-esforce-white-200'>
                Bimestre
              </p>
            </div>

            <ul className='gap- flex w-full flex-col'>
              <li className='flex items-center justify-between'>
                <div className='flex items-center gap-1'>
                  <div className='size-3 rounded-full bg-purple-500' />
                  <p className='font-dm-sans text-xs font-semibold text-esforce-white-100'>
                    Presença
                  </p>
                </div>
                <span className='font-dm-sans text-xs font-normal text-[#525252]'>
                  <span className='text-purple-500'>
                    {' '}
                    {averagePresenca && (averagePresenca / 100) * 100}%{' '}
                  </span>
                  de 100%
                </span>
              </li>
              <li className='flex items-center justify-between'>
                <div className='flex items-center gap-1'>
                  <div className='size-3 rounded-full bg-esforce-pink-100' />
                  <p className='font-dm-sans text-xs font-semibold text-esforce-white-100'>
                    {Period === 'all' ? 'Media' : 'Nota'}
                  </p>
                </div>
                <span className='font-dm-sans text-xs font-normal text-[#525252]'>
                  <span className='text-esforce-pink-50'>
                    {averageGrade && averageGrade}{' '}
                  </span>
                  de 10
                </span>
              </li>
              {/* <li className='flex items-center justify-between'>
                <div className='flex items-center gap-1'>
                  <div className='size-3 rounded-full bg-esforce-green-200' />
                  <p className='font-dm-sans text-xs font-semibold text-esforce-white-100'>
                    Média
                  </p>
                </div>
                 <span className='font-dm-sans text-xs font-normal text-[#525252]'>
                  <span className='text-esforce-green-200'>
                    {(averageGrade && (averageGrade / 10) * 100)?.toFixed(2)}%{' '}
                  </span>
                  de 100%
                </span> 
              </li> */}
            </ul>
          </div>
        </div>
        <div className='w-full rounded-xl bg-gradient-to-tr from-esforce-green-200 via-transparent via-50% to-esforce-green-200 p-0.5'>
          <div className='h-full w-full rounded-xl bg-esforce-black-200'>
            <div className='flex w-full flex-col items-center justify-center gap-4 p-5'>
              {quizPesquisaListar?.length === 0 ||
                !quizPesquisaListar?.some(
                  (lista) =>
                    lista.materiaId === params.id &&
                    notas?.some((nota) => nota.turmaId === lista.turmaId),
                ) ? (
                  <div className='flex w-full flex-col items-center justify-center gap-4 py-10'>
                  <p className='font-rubik text-lg'>Nenhuma atividade realizada ainda !</p>
                </div>
              ) : isLoading ? (
                Array.from({ length: 5 }).map((_, i) => (
                  <CardSkeleton key={`skeleton-${i}`} />
                ))
              ) : (
                quizPesquisaListar
                  ?.filter(
                    (lista) =>
                      lista.materiaId === params.id &&
                      notas?.some((nota) => nota.turmaId === lista.turmaId),
                  )
                  .map((lista) => (
                    <CardItems
                      titulo={lista.tema}
                      key={lista.id}
                      pontos={lista.pontuacao}
                    />
                  ))
              )}
            </div>
          </div>
        </div>
      </ContainerAppUi>
      <Popup />
    </Container>
  )
}

function CardSkeleton() {
  return (
    <div className='flex h-auto w-full flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl p-2.5'>
          <div>
            <Skeleton className='mb-1 h-4 w-24 bg-esforce-black-300' />
            <Skeleton className='h-3 w-20 bg-esforce-black-300' />
          </div>
        </div>
        <Skeleton className='h-10 w-32 rounded-xl bg-esforce-black-300' />
      </div>
    </div>
  )
}

function CardItems({ titulo, pontos }: { titulo: string; pontos: number }) {
  return (
    <div className='flex h-auto w-full flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl p-2.5'>
          <div>
            <p className='font-dm-sans text-sm font-semibold capitalize text-white'>
              {titulo}
            </p>
            <p className='font-dm-sans text-xs font-normal text-[#737373]'>
              atividade
            </p>
          </div>
        </div>
        <div className='flex w-32 items-center justify-center gap-2 rounded-xl bg-esforce-black-300 py-3 font-dm-sans'>
          <img src={goldCoin} alt='gold coin icon' className='size-4' />
          <p className='text-xs font-semibold text-esforce-white-100'>
            {pontos} pts
          </p>
        </div>
      </div>
    </div>
  )
}
