import { configuraçõesApi } from '@/configurações/configuraçõesApi'
import { autorizaçãoHeader } from '@/utilitários/autorizaçãoHeader'
import axios from 'axios'

export const api = () =>
  axios.create({
    baseURL: `${configuraçõesApi.ip}${configuraçõesApi.path}`,
    timeout: configuraçõesApi.timeout,
    headers: {
      ...configuraçõesApi.headers,
      ...autorizaçãoHeader(),
    },
  })

export const apiUpload = () =>
  axios.create({
    baseURL: `${configuraçõesApi.ip}${configuraçõesApi.path}`,
    timeout: configuraçõesApi.timeout,
    headers: {
      ...autorizaçãoHeader(),
      'Content-Type': 'multipart/form-data',
    },
  })
