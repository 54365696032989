import Container from '../../../../../components/container'
import LazyImage from '../../../../../components/lazy-image'

export const _Imagem = ({ data }: ImagemProps) => (
  <Container className='relative h-0 overflow-hidden pb-[56%]'>
    <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
      <LazyImage
        src={data.premioFotoUrl ? data.premioFotoUrl : ''}
        alt={data.premioNome}
        className='h-full max-h-full w-full max-w-full object-cover'
      />
    </div>
  </Container>
)

interface ImagemProps {
  data: {
    premioNome: string
    premioFotoUrl: string
  }
}
