import { Fragment, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import Container from '../../../components/container'
import { Skeleton } from '../../../components/ui/skeleton'
import { ReforceCardPatternsUi } from './components/CardPatterns/ReforceCardPatternsUi'

import { ReforceModel } from '@/@types/Models'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { useReforceListar } from '@/hooks/API/reforce/useReforceListar'
import { UseMenuActive } from '@/hooks/useMenuAtivo'

export const ReforcePágina = () => {
  UseMenuActive('reForce')
  const { data, fetchNextPage, hasNextPage, refetch, isLoading } =
    useReforceListar({
      take: 10,
    })
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [hasNextPage, inView])

  return (
    <Container className='min-h-screen items-center gap-7 bg-esforce-black-100'>
      {/* <Cabeçalho /> */}
      <ContainerAppUi className='flex flex-col gap-10'>
        {data?.pages?.length ? (
          data.pages.map((page) =>
            page.data.map((reforce, index) => {
              const isLastItem = page.data.length === index + 1
              return isLastItem ? (
                <div key={reforce.id} ref={ref}>
                  <CardReforce reforce={reforce} />
                </div>
              ) : (
                <Fragment key={reforce.id}>
                  <CardReforce reforce={reforce} />
                  <div className='w-full border-b border-dashed border-b-esforce-black-350' />
                </Fragment>
              )
            }),
          )
        ) : isLoading ? (
          Array.from({ length: 4 }).map((_, i) => (
            <CardReforceSkeleton key={`skeleton-${i}`} />
          ))
        ) : (
          <SvgSemDados />
        )}
        {!hasNextPage && !!data?.pages[0].data.length && (
          <div className='w-full; h-git flex flex-col items-center gap-3'>
            <p
              className='cursor-pointer text-base text-esforce-green-200'
              onClick={() => refetch()}
            >
              Fim dos resultados
            </p>
          </div>
        )}
      </ContainerAppUi>
      <Popup />
    </Container>
  )
}

const CardReforce = ({ reforce }: { reforce: ReforceModel }) => {
  return (
    <>
      <ReforceCardPatternsUi.Root key={reforce.id}>
        <ReforceCardPatternsUi.Header
          data={{
            alunoNome: reforce.Aluno?.nome as string,
            alunoAvatarUrl: reforce.Aluno?.avatarArquivoLink as string,
            instituicaoNome:
              `${reforce.Instituicao?.nome} - ${reforce.Aluno?.Turma?.nome}` as string,
            criadoEm: reforce?.criadoEm,
          }}
        />
        <ReforceCardPatternsUi.Imagem
          data={{
            premioNome: reforce.Premio?.nome as string,
            premioFotoUrl: reforce.Premio?.Fotos?.[0]?.arquivoLink as string,
          }}
        />
        <ReforceCardPatternsUi.BarraInferior
          data={{
            isCurtida: reforce.usuarioAtivoCurtiu || false,
            reforceFavoritos: reforce.ReforceFavoritos?.length || 0,
            reforceId: reforce.id,
          }}
        />

        <p className='px-3 text-sm font-normal text-esforce-green-200'>
          {reforce.descricao}
        </p>
        <div className='flex flex-col gap-0 px-4'>
          {!!reforce.ReforceComentarios?.length && (
            <ReforceCardPatternsUi.Comentario
              Comentario={reforce.ReforceComentarios[0]}
            />
          )}
          <ReforceCardPatternsUi.MensagemComentario reforce={reforce} />
        </div>
        <ReforceCardPatternsUi.InputEmoji data={{ reforceId: reforce.id }} />
      </ReforceCardPatternsUi.Root>
    </>
  )
}

export const CardReforceSkeleton = () => {
  return (
    <div className='flex h-fit w-full flex-col gap-3'>
      <div
        className='flex w-full items-center justify-between px-3.5'
        id='header'
      >
        <div className='flex items-center gap-1'>
          <Skeleton className='size-12 rounded-full bg-esforce-black-350' />
          <div>
            <Skeleton className='h-3 w-56 bg-esforce-black-350' />
            <Skeleton className='mt-2 h-3 w-16 bg-esforce-black-350' />
          </div>
        </div>
        <Skeleton className='h-3 w-14 bg-esforce-black-350' />
      </div>
      <Skeleton className='h-[340px] w-full bg-esforce-black-350' />
      <div className='flex flex-col gap-3'>
        <div className='flex items-center gap-3 px-3.5'>
          <Skeleton className='size-6 bg-esforce-black-350' />
          <Skeleton className='h-3 w-36 bg-esforce-black-350' />
        </div>
        <Skeleton className='h-5 w-full bg-esforce-black-350' />
      </div>
      <div className='flex flex-col gap-2'>
        <Skeleton className='mt-3 h-10 w-full rounded-xl bg-esforce-black-350' />
      </div>
    </div>
  )
}
