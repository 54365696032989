import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { TermosDeUsoUi } from '@/componentes/TermosDeUsoUi'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'

export const TermosDeUsoPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Termos de uso',
    isBotãoEsquerdo: true,
  })

  return (
    <ContainerAppUi>
      <TermosDeUsoUi />
    </ContainerAppUi>
  )
}
