import { useEffect } from 'react'

import { UseActiveMenuStore } from '../store/menu-type-store'

type IMenuAtivoType =
  | 'Home'
  | 'reForce'
  | 'Quiz'
  | 'Ranking'
  | 'Loja'
  | undefined

export const UseMenuActive = (menu: IMenuAtivoType) => {
  const setMenuActive = UseActiveMenuStore((state) => state.setMenuActive)

  useEffect(() => {
    setMenuActive(menu)
  }, [menu, setMenuActive])
}
