import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { rotasApp } from '../../configurações/rotasApp'
import { useAlunoBuscarPorId } from '../../hooks/API/aluno/useAlunoBuscarPorId'
import { UseActiveUser } from '../../store/user.store'
import { Button } from '../ui/button'

import goldPrisma from '@/assets/gold-prisma.svg'
import goldQuestion from '@/assets/gold-question.svg'

export const UseAlertFillProfile = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false)
  const { userActive } = UseActiveUser()
  const navigate = useNavigate()
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const { aluno } = useAlunoBuscarPorId({
    alunoId: userActive?.Perfil?.alunoId,
  })

  const handleClosePopup = () => {
    setIsPopupVisible(false)
    localStorage.setItem(
      `@Esforce:PopupFillProfile`,
      new Date().getTime().toString(),
    )
    onClose()
  }
  const goToProfileEdit = () => {
    setIsPopupVisible(false)
    localStorage.setItem(
      `@Esforce:PopupFillProfile`,
      new Date().getTime().toString(),
    )
    onClose()
    navigate(rotasApp.editarPerfil)
  }

  useEffect(() => {
    if (aluno && aluno.idade < 18) {
      const responsavelHasRequiredInfo =
        aluno.Responsavel === undefined ||
        aluno.Responsavel?.cpf === '' ||
        aluno.Responsavel?.nome === '' ||
        aluno.Responsavel?.telefone === '' ||
        aluno.Responsavel?.email === ''
      if (userActive && responsavelHasRequiredInfo && isPopupVisible) {
        onOpen()
      } else {
        onClose()
      }
    }
  })

  useEffect(() => {
    const lastClosedTime = localStorage.getItem(`@Esforce:PopupFillProfile`)
    if (lastClosedTime) {
      const currentTime = new Date().getTime()
      const timeDifference = currentTime - parseInt(lastClosedTime, 10)
      if (timeDifference > 60 * 60 * 1000) {
        setIsPopupVisible(true)
      }
    } else {
      setIsPopupVisible(true)
    }
  }, [])

  const ModalAlert = () => (
    <div
      className={`fixed inset-0 z-[999] flex items-center justify-center ${isOpen ? 'block' : 'hidden'}`}
    >
      <div className='absolute inset-0 bg-opacity-40 backdrop-blur-lg' />
      <div className='relative mx-1.5 flex w-full max-w-[335px] flex-col rounded-xl bg-gradient-to-b from-esforce-pink-100 to-esforce-green-200 p-0.5'>
        <div className='background-image-full flex h-full w-full flex-col items-center justify-between gap-6 rounded-xl p-8'>
          <h2 className='font-dm-sans text-[28px] font-normal text-esforce-white-100'>
            Finalizar seu Cadastro
          </h2>
          <div className='flex w-full items-center justify-between gap-3'>
            <div className='flex h-10 flex-1 items-center justify-center gap-1 rounded-[10px] border border-esforce-green-200 bg-esforce-green-700 px-2 py-4'>
              <img src={goldQuestion} alt='gold coin icon' className='size-5' />
              <p className='text-sm font-normal text-esforce-white-100'>
                4 Campos
              </p>
            </div>
            <div className='flex h-10 flex-1 items-center justify-center gap-1 rounded-[10px] border border-esforce-green-200 bg-esforce-green-700 px-2 py-4'>
              <img src={goldPrisma} alt='gold coin icon' className='size-5' />
              <p className='text-sm font-normal text-esforce-white-100'>
                10 Pontos
              </p>
            </div>
          </div>
          <div className='flex w-full flex-col gap-3'>
            <Button
              size='xl'
              onClick={goToProfileEdit}
              className='w-full cursor-pointer rounded-xl bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
            >
              Finalizar cadastro
            </Button>
            <Button
              size='xl'
              onClick={handleClosePopup}
              className='text-esforce-white-50 w-full cursor-pointer rounded-xl bg-esforce-pink-200 font-archivo text-sm font-bold hover:bg-esforce-pink-100'
            >
              Fechar
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  return { ModalAlert }
}
