import { useParams } from 'react-router-dom'

import {
  CardVisualizarQuizPesquisaResultadoUi,
  IQuizPerguntas,
} from '@/componentes/Card/CardVisualizarQuizPesquisaResultado/CardVisualizarQuizPesquisaResultadoUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { useQuizPesquisaVisualizar } from '@/hooks/API/quiz/useQuizPesquisaVisualizar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { X } from '@phosphor-icons/react'

export const HistóricoPesquisaPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Histórico Pesquisa',
    isBotãoEsquerdo: true,
    íconeDireita: X,
  })
  const { id } = useParams()
  const { quizPesquisaVisualizar } = useQuizPesquisaVisualizar({
    quizPesquisaId: id,
  })

  return (
    <ContainerAppUi>
      <div className='flex flex-col gap-3'>
        {quizPesquisaVisualizar?.Perguntas?.map((pergunta, index) => (
          <CardVisualizarQuizPesquisaResultadoUi
            key={pergunta.id}
            pergunta={pergunta.pergunta}
            alternativas={
              pergunta.Alternativas?.map((alternativa) => {
                return {
                  id: alternativa.id,
                  selecionada: quizPesquisaVisualizar.Respostas?.find(
                    (resposta) => resposta.alternativaId === alternativa.id,
                  ),
                  texto: alternativa.alternativa,
                }
              }) as unknown as IQuizPerguntas['alternativas']
            }
            index={index}
          />
        ))}
      </div>
    </ContainerAppUi>
  )
}
