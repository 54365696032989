import { FiPieChart } from 'react-icons/fi'
import { GoArrowRight } from 'react-icons/go'
import { HiOutlineChartBar } from 'react-icons/hi'
import { RiMapPinUserLine } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'

import Container from '../../../components/container'
import IconSelector from '../../../components/map-icon'
import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import ProfileCard from '../../../components/profile-card'
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from '../../../components/ui/avatar'
import { Skeleton } from '../../../components/ui/skeleton'

import { NotaModel } from '../../../@types/Models'
import { SvgSemDados } from '../../../componentes/SvgSemDados'
import { useContextoOutletLayoutComNavegação } from '../../../hooks/useLayoutComNavegação'

import imgPerfil from '@/assets/header_picutre.png'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { rotasApp } from '@/configurações/rotasApp'
import { useNotas } from '@/hooks/API/nota/useNotas'
import { useRanking } from '@/hooks/API/ranking/useRanking'
import { UseMenuActive } from '@/hooks/useMenuAtivo'
import { UseActiveUser } from '@/store/user.store'

export interface CardSujectsItemsProps {
  nota: NotaModel
}

export const PontosPágina = () => {
  UseMenuActive('Ranking')
  useContextoOutletLayoutComNavegação({
    título: 'Ranking',
    isBotãoEsquerdo: true,
  })
  const navigate = useNavigate()
  const { UiModal, onClose } = UseModalUi()

  const { userActive } = UseActiveUser()

  const { notas, isLoading } = useNotas({
    alunoId: userActive?.Perfil?.alunoId ?? '-',
  })

  const { rankingAluno } = useRanking()

  return (
    <Container className='h-full items-center bg-esforce-black-100'>
      <UiModal
        callbackBotão={() => {
          onClose()
          navigate(rotasApp.início)
        }}
        título='Ops!'
        descrição='Você ainda não possui pontos atribuídos no sistema.'
        textoBotão='Entendido'
      />
      <ContainerAppUi className='flex flex-col gap-8'>
        <ProfileCard />
        <div>
          <h2 className='mb-4 font-dm-sans text-base font-semibold text-esforce-white-100'>
            Posição no Ranking
          </h2>
          <ul className='flex flex-col items-center justify-center gap-4'>
            {(rankingAluno?.turmaPosicao ||
              Number.isNaN(rankingAluno?.turmaPosicao)) && (
              <li className='flex h-12 w-full items-center justify-between rounded-xl bg-esforce-green-200 p-2'>
                <div className='flex items-center justify-center gap-2'>
                  <Avatar className='size-8'>
                    <AvatarImage src='' alt='perfil usuario' />
                    <AvatarFallback className='bg-black'>
                      {userActive?.Perfil?.Aluno?.nome?.substring(0, 2)}
                    </AvatarFallback>
                  </Avatar>
                  <p className='font-dm-sans text-sm font-semibold text-esforce-black-200'>
                    Turma
                  </p>
                </div>
                <span className='font-dm-sans text-sm font-normal text-esforce-black-200'>
                  #{Number(rankingAluno?.turmaPosicao)}
                </span>
              </li>
            )}
            {(rankingAluno?.escolaPosicao !== undefined ||
              Number.isNaN(rankingAluno?.escolaPosicao)) && (
              <li className='flex h-12 w-full items-center justify-between rounded-xl bg-esforce-green-200 p-2'>
                <div className='flex items-center justify-center gap-2'>
                  <Avatar className='size-8'>
                    <AvatarImage src='' alt='perfil usuario' />
                    <AvatarFallback className='bg-black'>
                      {userActive?.Perfil?.Aluno?.nome?.substring(0, 2)}
                    </AvatarFallback>
                  </Avatar>
                  <p className='font-dm-sans text-sm font-semibold text-esforce-black-200'>
                    Escola
                  </p>
                </div>
                <span className='font-dm-sans text-sm font-normal text-esforce-black-200'>
                  #{Number(rankingAluno?.escolaPosicao)}
                </span>
              </li>
            )}
            {(rankingAluno?.cidadePosicao ||
              Number.isNaN(rankingAluno?.cidadePosicao)) && (
              <li className='flex h-12 w-full items-center justify-between rounded-xl bg-esforce-green-200 p-2'>
                <div className='flex items-center justify-center gap-2'>
                  <Avatar className='size-8'>
                    <AvatarImage src='' alt='perfil usuario' />
                    <AvatarFallback className='bg-black'>
                      {userActive?.Perfil?.Aluno?.nome?.substring(0, 2)}
                    </AvatarFallback>
                  </Avatar>
                  <p className='font-dm-sans text-sm font-semibold text-esforce-black-200'>
                    Cidade
                  </p>
                </div>
                <span className='font-dm-sans text-sm font-normal text-esforce-black-200'>
                  #{Number(rankingAluno?.cidadePosicao)}
                </span>
              </li>
            )}
            {(rankingAluno?.todosAlunosPosicao !== undefined ||
              Number.isNaN(rankingAluno?.todosAlunosPosicao)) && (
              <li className='flex h-12 w-full items-center justify-between rounded-xl bg-esforce-green-200 p-2'>
                <div className='flex items-center justify-center gap-2'>
                  <Avatar className='size-8'>
                    <AvatarImage src='' alt='perfil usuario' />
                    <AvatarFallback className='bg-black'>
                      {userActive?.Perfil?.Aluno?.nome?.substring(0, 2)}
                    </AvatarFallback>
                  </Avatar>
                  <p className='font-dm-sans text-sm font-semibold text-esforce-black-200'>
                    Todos os alunos
                  </p>
                </div>
                <span className='font-dm-sans text-sm font-normal text-esforce-black-200'>
                  # {Number(rankingAluno?.todosAlunosPosicao)}
                </span>
              </li>
            )}
          </ul>
        </div>
        <div>
          <hgroup className='mb-4 flex w-full items-center justify-between'>
            <h2 className='font-dm-sans text-base font-semibold text-esforce-white-100'>
              Notas disponíveis
            </h2>
            <Link
              to={rotasApp.extratoNotas}
              className='text-sm font-normal text-esforce-green-200'
            >
              Ver todos
            </Link>
          </hgroup>
          <div className='from-1% w-full rounded-xl bg-gradient-to-tr from-esforce-green-200 via-transparent via-50% to-esforce-green-200 p-0.5'>
            <div className='h-full w-full rounded-xl bg-esforce-black-200'>
              <div className='flex w-full flex-col items-center justify-center gap-4 p-5'>
                {notas?.length === 0 ? (
                  <SvgSemDados />
                ) : isLoading ? (
                  Array.from({ length: 4 }).map((_, i) => (
                    <CardSkeleton key={`skeleton-${i}`} />
                  ))
                ) : (
                  notas
                    ?.slice(0, 4)
                    .map((nota) => (
                      <CardSubjectsItems nota={nota} key={nota.id} />
                    ))
                )}
              </div>
            </div>
          </div>
        </div>
      </ContainerAppUi>
      <Popup />
    </Container>
  )
}

function CardSkeleton() {
  return (
    <div className='flex h-auto w-full flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl p-2.5'>
          <Skeleton className='flex size-11 items-center justify-center rounded-xl bg-esforce-black-300' />
          <div>
            <Skeleton className='mb-1 h-4 w-24 bg-esforce-black-300' />
            <Skeleton className='h-3 w-20 bg-esforce-black-300' />
          </div>
        </div>
        <div className='flex items-end justify-end gap-2 rounded-xl p-3'>
          <Skeleton className='size-10 rounded-full bg-esforce-black-300' />
        </div>
      </div>

      <ul className='flex w-full items-center justify-between gap-0.5 border-t border-t-esforce-black-300 p-3 font-dm-sans text-sm font-normal text-esforce-white-200'>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-4 w-20 bg-esforce-black-300' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-4 w-20 bg-esforce-black-300' />
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <Skeleton className='h-4 w-20 bg-esforce-black-300' />
        </li>
      </ul>
    </div>
  )
}

function CardSubjectsItems({ nota }: CardSujectsItemsProps) {
  const navigate = useNavigate()
  return (
    <div className='flex h-auto w-full flex-col items-center justify-center gap-2 rounded-xl bg-esforce-black-100 p-3 font-dm-sans'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div
          className='flex flex-1 cursor-pointer items-center gap-2 rounded-xl'
          onClick={() => {
            navigate(
              rotasApp.materiaVisualizar(nota.materiaId, nota.Materia?.nome),
            )
          }}
        >
          <div className='flex size-11 items-center justify-center rounded-xl bg-esforce-pink-100'>
            <IconSelector name={nota.Materia?.nome || ''} />
          </div>
          <div>
            <p className='font-dm-sans text-sm font-semibold text-white'>
              {nota.Materia?.nome}
            </p>
            <p className='font-dm-sans text-xs font-normal text-[#737373]'>
              atividade
            </p>
          </div>
        </div>
        <div className='flex items-end justify-end gap-2 rounded-xl'>
          <button
            type='button'
            className='flex size-10 items-center justify-center rounded-full bg-[#262626]'
            onClick={() => {
              navigate(
                rotasApp.materiaVisualizar(nota.materiaId, nota.Materia?.nome),
              )
            }}
          >
            <GoArrowRight size={20} />
          </button>
        </div>
      </div>

      <ul className='flex w-full items-center justify-between gap-1 border-t border-t-esforce-black-300 pt-2 font-dm-sans text-xs font-normal text-esforce-white-200'>
        <li className='flex items-center justify-center gap-0.5'>
          <FiPieChart size={16} />
          <span className=''> {nota.periodo}° Bimestre</span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <HiOutlineChartBar size={16} />
          <span className=''>
            Nota: <strong>{nota.nota}</strong>
          </span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <RiMapPinUserLine size={16} />
          <span className=''>
            Presença: <strong>{nota.presenca}%</strong>
          </span>
        </li>
      </ul>
    </div>
  )
}
