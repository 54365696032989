import { HistoricoPontuacaoModel } from '@/@types/Models'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { useHistóricosPontuação } from '@/hooks/API/históricoPontuação/useHistóricosPontuação'
import { UseActiveUser } from '@/store/user.store'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import moment from 'moment'

export const ExtratoPontosPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Extrato de pontos',
    isBotãoEsquerdo: true,
  })

  const { userActive } = UseActiveUser()

  const { historicosPontuacao } = useHistóricosPontuação({
    alunoId: userActive?.Perfil?.Aluno?.id || '-',
  })

  const RenderNomePonto = ({
    historicoPontuacaoModel,
  }: {
    historicoPontuacaoModel: HistoricoPontuacaoModel
  }) => {
    const { origem, descricao } = historicoPontuacaoModel

    const renderTexto = () => {
      switch (origem) {
        case 'ponteza':
          return 'Potenza'
        case 'ponto-extra':
          return descricao
        default:
          return origem
      }
    }

    return (
      <p className='trucante font-dm-sans text-sm font-semibold capitalize text-esforce-white-100'>
        {renderTexto()}
      </p>
    )
  }

  return (
    <ContainerAppUi className='bg-esforce-black-100'>
      <div className='flex flex-col gap-2'>
        {historicosPontuacao?.length === 0 && <SvgSemDados />}
        {historicosPontuacao?.map((historico, i) => {
          return (
            <div
              key={i}
              className='flex h-auto w-full flex-col items-center justify-center gap-5 rounded-xl bg-gradient-to-r from-esforce-pink-100 to-esforce-green-200 p-0.5 font-dm-sans'
            >
              <div className='flex w-full items-center justify-between rounded-xl bg-esforce-black-300 p-3'>
                <div className='flex flex-1 items-center gap-2 rounded-xl p-2.5'>
                  <div className='flex flex-col gap-2'>
                    <p className='font-dm-sans text-sm font-semibold text-white'>
                      <RenderNomePonto historicoPontuacaoModel={historico} />
                    </p>
                    <p className='font-dm-sans text-sm font-normal text-esforce-white-200'>
                      {historico.pontuacao} ponto
                      {historico.pontuacao > 1 && 's'}
                    </p>
                  </div>
                </div>
                <p className='font-rubik text-xs font-medium'>
                  {moment(historico.criadoEm).format('DD/MM/YY')}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </ContainerAppUi>
  )
}
