/* cSpell:disable */

const autenticacao = {
  login: '/login',
  recuperarSenhaMatrícula: '/recuperar-senha/matricula',
  recuperarSenhaCódigo: '/recuperar-senha/codigo',
  recuperarSenhaNovaSenha: '/recuperar-senha/nova-senha',
}

const meuPerfil = {
  meuPerfil: '/meu-perfil',
  termosDeUso: '/meu-perfil/termos-de-uso',
  privacidade: '/meu-perfil/privacidade',
  regulamentosAplicativo: '/meu-perfil/regulamentos-aplicativo',
  regulamentosInstituicoes: '/meu-perfil/regulamentos-instituicoes',
  sobre: '/meu-perfil/sobre',
  perguntasFrequentes: '/meu-perfil/perguntas-frequentes',
  planos: '/meu-perfil/planos',
  planoVisualizar: (id: string) => `/meu-perfil/planos/visualizar/${id}`,
  editarPerfil: '/meu-perfil/editar',
  alterarSenha: '/meu-perfil/alterar-senha',
  histórico: '/meu-perfil/historico',
  visualizarQuiz: '/meu-perfil/visualizar-quiz-pesquisa',
  historicoPesquisa: (param: string) =>
    `/meu-perfil/historico-pesquisa/${param}`,
  historicoQuiz: (param: string) => `/meu-perfil/historico-quiz/${param}`,
  //carrinho: '/meu-perfil/carrinho',
  sair: '/meu-perfil/sair',
}

const pontos = {
  pontos: '/pontos',
  materias: '/pontos/materias',
  materiaVisualizar: (id: string, name: any) => `/pontos/materia/${id}/${name}`,
  extratoPontos: '/pontos/extrato',
  extratoNotas: '/pontos/notas/extrato',
  mediageral: '/pontos/media-geral',
}

const quizPesquisa = {
  quizPesquisas: '/quiz-pesquisas',
  pesquisa: `/pesquisa`,
  quiz: `/quiz`,
}

const premio = {
  prêmio: '/premio',
  visualizarPrêmio: (param: string) => `/premio/visualizar/${param}`,
}

export const rotasApp = {
  início: '/',
  boasVindas: '/bem-vindo',
  notificação: '/notificacao',
  ouvidoria: '/ouvidoria',
  reforce: '/reForce',
  excluirConta: '/excluir-conta',
  reforceVisualizar: (param: string) => `/reForce/visualizar/${param}`,
  ...meuPerfil,
  ...autenticacao,
  ...pontos,
  ...quizPesquisa,
  ...premio,
}
