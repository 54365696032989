import { useEffect } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Container from '../../../components/container'
import { Input } from '../../../components/input'
import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import { Button } from '../../../components/ui/button'
import { Form } from '../../../components/ui/form'

import { rotasApp } from '@/configurações/rotasApp'
import {
  CodigoAcessoSchema,
  useCodigoAcesso,
} from '@/hooks/API/autenticação/useCódigoAcesso'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { Loader2 } from 'lucide-react'

export const RecuperarSenhaCódigoPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Recuperar senha',
    isBotãoEsquerdo: true,
  })

  const {
    codigoAcesso,
    codigoAcessoContext,
    codigoAcessoIsLoading,
    codigoAcessoStatus,
  } = useCodigoAcesso()
  const { UiModal, onOpen } = UseModalUi()

  const { handleSubmit, control } = codigoAcessoContext

  const navigate = useNavigate()
  const storage = new LocalStorage()

  const onSubmit: SubmitHandler<CodigoAcessoSchema> = (data) => {
    codigoAcesso(data)
  }
  useEffect(() => {
    if (codigoAcessoStatus === 'success') onOpen()
  }, [codigoAcessoStatus])

  return (
    <>
      <Container className='flex flex-col items-center justify-center gap-10 bg-esforce-black-200 px-5 py-8'>
        <hgroup className='max-full flex flex-col items-center justify-center gap-5'>
          <img
            alt='logo da esforce na cor verde'
            src='../logo-colour.svg'
            className='h-9 w-40'
          />
          <p className='font-archivo text-xl font-semibold text-esforce-green-200'>
            Código de verificação
          </p>
          <p className='text-start font-inter text-base font-normal leading-6'>
            Informe o código de recuperação de senha que enviamos para{' '}
            {storage.get('email')}
          </p>
        </hgroup>

        <Form {...codigoAcessoContext}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <fieldset className='flex flex-col gap-4'>
              <Input
                control={control}
                registerName='codigoAcesso'
                textlabel='Código de verificação'
                placeholder='informe o codigo '
                type='text'
              />
            </fieldset>

            <Button
              size='xl'
              type='submit'
              disabled={codigoAcessoIsLoading}
              className='mt-7 w-full cursor-pointer bg-esforce-green-200 text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
            >
              {codigoAcessoIsLoading ? (
                <>
                  <Loader2 className='animate-spin' />
                </>
              ) : (
                'Enviar'
              )}
            </Button>
          </form>
        </Form>
      </Container>

      <UiModal
        callbackBotão={() => navigate(rotasApp.recuperarSenhaNovaSenha)}
        descrição='Você será redirecionado para redefinir sua senha.'
        título='Código de acesso verificado'
        textoBotão='Entendido'
      />
    </>
  )
}
