export const rotasApiAutenticadoV5 = {
  buscarMatricula: {
    path: (matricula: string) => `/auth/buscar-matricula/${matricula}`,
    key: '@Auth/BuscarMatricula',
  },
  completarVerificacao: {
    path: '/auth/completar-verificacao',
    key: '@Auth/CompletarVerificacao',
  },
}
