import { useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import Carousel from './_components/carousel'
import { gallery } from './_components/data'
import Container from '@/components/container'
import { Button } from '@/components/ui/button'

import { primeiroAcessoRotasApp } from '../primeiroAcesso/primeiroAcesso.routes'

import { rotasApp } from '@/configurações/rotasApp'

export const Wellcome = () => {
  const navigate = useNavigate()
  const swiperRef = useRef<any>(null)
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const storage = localStorage.getItem('@Esforce-wellcome')

  const text = [
    {
      title: 'Bem-vindo(a) à Esforce/SE',
      sub: 'Transformando o aprendizado em uma jornada cheia de conquistas!',
    },
    {
      title: 'Responda pesquisas, ganhe pontos e troque por prêmios!',
      sub: 'Aprender nunca foi tão divertido e recompensador.',
    },
    {
      title: 'Vamos começar?',
      sub: 'Prepare-se para uma experiência inovadora que une educação e diversão. Se precisar de mais ajustes, é só falar! ',
    },
  ]

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  const handleEnter = () => {
    localStorage.setItem('@Esforce-wellcome', JSON.stringify(true))
    navigate(rotasApp.login)
  }

  const handleRegister = () => {
    localStorage.setItem('@Esforce-wellcome', JSON.stringify(true))
    navigate(primeiroAcessoRotasApp.buscarMatricula)
  }

  if (storage !== null) {
    return <Navigate to={`/login`} />
  }
  return (
    <Container className='wellcome-background mx-auto my-0 flex h-full min-h-svh w-screen max-w-[635px] flex-col items-center justify-around gap-2.5 bg-esforce-black-100 py-12'>
      <Carousel
        onSlideChange={(index) => setCurrentSlide(index)}
        ref={swiperRef}
      />
      <div className='flex w-full flex-1 flex-col items-center justify-between px-4'>
        <hgroup className='flex flex-col items-center justify-center gap-2 text-center'>
          <h2 className='font-dm-sans text-2xl font-bold text-esforce-white-100'>
            {text[currentSlide]?.title}
          </h2>
          <p className='font-dm-sans text-sm font-normal text-esforce-white-100'>
            {text[currentSlide]?.sub}
          </p>
        </hgroup>
        {currentSlide === gallery.length - 1 ? (
          <div className='mt-7 flex flex-col-reverse items-end justify-end gap-4'>
            <Button
              size='xl'
              type='button'
              onClick={handleEnter}
              className='w-72 cursor-pointer bg-esforce-black-350 font-archivo text-sm font-bold text-esforce-white-100'
            >
              Entrar
            </Button>
            <Button
              size='xl'
              type='button'
              onClick={handleRegister}
              className='w-72 cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
            >
              Cadastrar
            </Button>
          </div>
        ) : (
          <Button
            size='xl'
            type='button'
            onClick={handleNext}
            className='mt-2 w-72 cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
          >
            Próximo
          </Button>
        )}
      </div>
    </Container>
  )
}
