import { AlunoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useAlunoBuscarPorId = ({ alunoId }: { alunoId?: string }) => {
  const { data: aluno } = useQuery({
    queryKey: [`aluno-id_${alunoId}`, alunoId],
    queryFn: async () => endPoint(alunoId),
  })

  return { aluno }
}

async function endPoint(id?: string): Promise<AlunoModel> {
  const result = await api().get(
    rotasApi.alunoBuscarPorId({ alunoId: id || '' }),
  )
  return result.data.data
}
