import { ReforceModel } from '@/@types/Models'
import { PaginacaoCursorResponse } from '@/@types/paginacaoResponse'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useInfiniteQuery } from '@tanstack/react-query'

export const useReforceListar = (condicao?: Atributos) => {
  const { data, hasNextPage, fetchNextPage, refetch, isLoading } =
    useInfiniteQuery({
      queryFn: async ({ pageParam = '' }) => {
        return await endPoint({ take: condicao?.take, cursorAtual: pageParam })
      },
      queryKey: ['reForce'],
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage ? lastPage.ultimoCursor : undefined,
    })

  return { data, hasNextPage, fetchNextPage, refetch, isLoading }
}

async function endPoint(condição?: Atributos): Promise<EndPointResponse> {
  const result = await api().get(rotasApi.reforceListar, {
    params: { ...condição },
  })
  return result.data.data
}

interface Atributos {
  cursorAtual?: string
  take?: number
}

type EndPointResponse = PaginacaoCursorResponse<ReforceModel, string>
