import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi'

import { Input } from '../../../../components/input'
import { Button } from '../../../../components/ui/button'
import { Form } from '../../../../components/ui/form'

import {
  DeleteAlunoSchema,
  useDeletarAluno,
} from '@/hooks/API/aluno/useDeletarAluno'
import { Loader2 } from 'lucide-react'

export const ConteudoModalDeletarConta = (
  props: IConteudoModalDeletarContaProps,
) => {
  const [seePassword, setSeePassword] = useState<boolean>(false)
  const { contextDeletarAluno, mutateDeletarAluno, isLoadingDeletarAluno } =
    useDeletarAluno()
  const { control, handleSubmit } = contextDeletarAluno

  const onSubmit: SubmitHandler<DeleteAlunoSchema> = (data) => {
    mutateDeletarAluno(data)
  }

  return (
    <div className='flex h-auto flex-col items-center justify-center gap-3 bg-esforce-black-350'>
      <p className='text-xl text-esforce-green-200'>Excluir Perfil</p>
      <p className='font-archivo text-sm font-normal'>
        Tem certeza de que deseja excluir sua conta? Isso resultará na perda de
        todo o seu progresso e pontuação na plataforma, e não será possível
        fazer login novamente.
      </p>
      <p className='text-lg'>Esta ação é irreversível!</p>

      <Form {...contextDeletarAluno}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex w-full flex-col gap-4'
        >
          <div className='relative text-start'>
            <Input
              control={control}
              registerName='senha'
              textlabel='Senha'
              placeholder='Digite sua senha'
              type={!seePassword ? 'password' : 'text'}
            />

            <button
              type='button'
              className='absolute right-5 top-11 cursor-pointer'
              onClick={() => setSeePassword((prev) => !prev)}
            >
              {!seePassword ? (
                <PiEyeLight size={24} />
              ) : (
                <PiEyeSlash size={24} />
              )}
            </button>
          </div>

          <div>
            <Button
              size='xl'
              type='submit'
              disabled={isLoadingDeletarAluno}
              className='w-full cursor-pointer bg-esforce-green-200 text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
            >
              {isLoadingDeletarAluno ? (
                <>
                  <Loader2 className='animate-spin' />
                </>
              ) : (
                'Deletar conta'
              )}
            </Button>
            <Button
              size='xl'
              type='button'
              onClick={props.onClose}
              className='mt-1.5 w-full cursor-pointer bg-esforce-black-200 text-sm font-bold text-esforce-white-100 hover:bg-esforce-black-100'
            >
              {isLoadingDeletarAluno ? (
                <>
                  <Loader2 className='animate-spin' />
                </>
              ) : (
                'Cancelar'
              )}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}

interface IConteudoModalDeletarContaProps {
  onClose: () => void
}
