import { FaChevronRight } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

import { cn } from '@/lib/utils'

interface Props {
  title: string
  children?: React.ReactNode
  link?: string
  className?: string
}
export function CardHome({ className, children, link = '/', title }: Props) {
  const navigation = useNavigate()

  const func = (text: string) => {
    return text === 'Ranking' ? () => navigation(link) : () => {}
  }

  return (
    <div className='w-full cursor-pointer px-5' onClick={func(title)}>
      <Card className='flex min-h-52 w-full flex-col justify-between gap-4 border border-none bg-transparent'>
        <CardHeader className='flex flex-row items-start justify-between p-0 text-esforce-white-100'>
          <CardTitle className='line-clamp-[30px] font-dm-sans text-xl font-semibold text-esforce-white-100'>
            {title}
          </CardTitle>

          <div>
            <Link
              to={link}
              className='flex items-center justify-center gap-1 text-sm font-normal text-esforce-white-100 *:font-dm-sans'
            >
              Ver mais
              <FaChevronRight size={12} />
            </Link>
          </div>
        </CardHeader>
        <CardContent
          className={cn(
            'min-h-40 rounded-xl bg-esforce-green-200 p-0',
            className,
          )}
        >
          {children}
        </CardContent>
      </Card>
    </div>
  )
}
