import { useEffect } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { UseModalFinishQuiz } from '../../../../components/modal-ui/UseModalFinishQuiz'
import { Button } from '../../../../components/ui/button'
import { Label } from '../../../../components/ui/label'
import { Progress } from '../../../../components/ui/progress'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

import { QuizLoadingPergunta } from '../responderQuiz/QuizLoadingPergunta'
import { useQuizPesquisaListar } from '../useQuizPesquisaListar'

import goldCoin from '@/assets/gold-coin.png'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { usePesquisaProximaPergunta } from '@/hooks/API/quiz-pesquisa/usePesquisaProximaPergunta'
import {
  SchemaPesquisa,
  usePesquisaRespostaCriar,
} from '@/hooks/API/quizResposta/usePesquisaRespostaCriar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { X } from '@phosphor-icons/react'
import { Loader2 } from 'lucide-react'

export const PesquisaPágina = () => {
  const [searchBarParams] = useSearchParams()
  const pesquisaId = searchBarParams.get('pesquisaId') || undefined
  const { quizPesquisaListar } = useQuizPesquisaListar()
  const filtered = quizPesquisaListar?.filter((quiz) => quiz.id === pesquisaId)

  useContextoOutletLayoutComNavegação({
    título: (filtered && filtered[0]?.tema) || '',
    isBotãoEsquerdo: true,
    íconeDireita: X,
  })

  const { UiModal, onOpen } = UseModalFinishQuiz()
  const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const navigate = useNavigate()

  const { pesquisaProximaPergunta } = usePesquisaProximaPergunta({
    pesquisaId,
  })

  const {
    enviarRespostasPesquisa,
    isLoadingEnviarRespostasPesquisa,
    pesquisaRespostasContext,
    statusEnviarRespostasPesquisa,
  } = usePesquisaRespostaCriar()

  const { setValue, control, handleSubmit } = pesquisaRespostasContext

  const onSubmit: SubmitHandler<SchemaPesquisa> = (data) => {
    enviarRespostasPesquisa(data)
  }

  useEffect(() => {
    if (!pesquisaProximaPergunta) return
    setValue('perguntaId', pesquisaProximaPergunta.id)
  }, [JSON.stringify(pesquisaProximaPergunta)])

  useEffect(() => {
    if (statusEnviarRespostasPesquisa === 'success') {
      if (pesquisaProximaPergunta?.indice === pesquisaProximaPergunta?.total) {
        onOpen()
      } else {
        navigate(0)
      }
    }
  }, [statusEnviarRespostasPesquisa])

  if (!pesquisaProximaPergunta) return <QuizLoadingPergunta hidden={false} />

  // const lidaComClickAlternativa = (alternativaId: string) => {
  //   const selectedAlternativas = watch('alternativasIds') || []
  //   const isSelected = selectedAlternativas.includes(alternativaId)

  //   if (isSelected) {
  //     setValue(
  //       'alternativasIds',
  //       selectedAlternativas.filter((id) => id !== alternativaId),
  //     )
  //   } else {
  //     setValue('alternativasIds', [...selectedAlternativas, alternativaId])
  //   }
  // }

  return (
    <ContainerAppUi>
      <UiModal callbackBotão={() => navigate(rotasApp.início)}>
        <hgroup className='flex w-full items-center justify-between text-center'>
          <h2 className='font-dm-sans text-xl font-semibold text-esforce-white-100'>
            Você ganhou
          </h2>

          <div className='flex h-9 w-32 items-center justify-center gap-2 rounded-xl border border-esforce-green-200 bg-esforce-green-700 font-dm-sans'>
            <img src={goldCoin} alt='gold coin icon' className='size-4' />
            <p className='text-xs font-semibold text-esforce-white-100'>
              {filtered && filtered[0]?.pontuacao} pontos
            </p>
          </div>
        </hgroup>
      </UiModal>

      <QuizLoadingPergunta hidden={true} />
      <div className='flex flex-col items-start gap-4'>
        <hgroup className='flex w-full flex-col gap-2.5'>
          <div className='flex w-full items-center justify-between'>
            <p className='font-dm-sans text-xs font-normal text-esforce-white-100'>{`${pesquisaProximaPergunta.indice}/${pesquisaProximaPergunta.total}`}</p>
          </div>
          <Progress
            value={
              (pesquisaProximaPergunta.indice / pesquisaProximaPergunta.total) *
              100
            }
            className='h-2.5 w-full'
          />
        </hgroup>
        <Form {...pesquisaRespostasContext}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex w-full flex-col gap-6'
          >
            <Label>
              <h2 className='font-dm-sans text-xl font-semibold leading-[30px] text-esforce-white-100'>
                Pergunta 0{pesquisaProximaPergunta.indice}
              </h2>
              <p className='mt-1 font-dm-sans text-sm font-normal leading-[21px] text-esforce-white-100'>
                {pesquisaProximaPergunta.pergunta}
              </p>
            </Label>

            <fieldset className={`w-full rounded-xl bg-esforce-black-300 p-4`}>
              <FormField
                control={control}
                name='alternativasIds'
                render={({ field }) => (
                  <FormItem className=''>
                    <FormControl>
                      <RadioGroup
                        onValueChange={(value) => field.onChange([value])}
                        defaultValue={
                          Array.isArray(field.value)
                            ? field.value[0]
                            : field.value
                        }
                        className=''
                      >
                        {pesquisaProximaPergunta.alternativas
                          .sort()
                          .map((alternativa, index) => (
                            <FormItem
                              className='flex w-full items-start justify-start gap-1.5 rounded-xl p-3'
                              key={alternativa.id}
                            >
                              <FormControl className='relative'>
                                <RadioGroupItem
                                  value={alternativa.id}
                                  className='size-5 border border-esforce-white-100 data-[state=checked]:bg-esforce-green-200 data-[state=checked]:text-esforce-green-200 [&_svg]:fill-esforce-green-200'
                                />
                              </FormControl>
                              <FormLabel className='!mt-0 font-dm-sans text-sm font-normal capitalize text-esforce-white-100'>
                                <strong>{letras.charAt(index)}.</strong>{' '}
                                {alternativa.alternativa}
                              </FormLabel>
                            </FormItem>
                          ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </fieldset>
            <Button
              size='xl'
              type='submit'
              disabled={
                isLoadingEnviarRespostasPesquisa ||
                statusEnviarRespostasPesquisa === 'success'
              }
              className='w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-350 hover:bg-esforce-green-100'
            >
              {isLoadingEnviarRespostasPesquisa ? (
                <>
                  <Loader2 className='animate-spin' />
                </>
              ) : pesquisaProximaPergunta.indice ===
                pesquisaProximaPergunta.total ? (
                'Finalizar'
              ) : (
                'Continuar'
              )}
            </Button>
          </form>
        </Form>
      </div>
    </ContainerAppUi>
  )
}
