import { useNavigate } from 'react-router-dom'

import { ReforceModel } from '@/@types/Models'
import { rotasApp } from '@/configurações/rotasApp'

export const _MensagemComentario = (props: MensagemComentarioProps) => {
  const comentarios = props.reforce.ReforceComentarios || []
  const navigate = useNavigate()

  return (
    <>
      {comentarios.length === 0 && (
        <p className='px-4 text-sm font-thin italic text-esforce-white-100'>
          Nenhum comentário ainda!!
        </p>
      )}
      {comentarios.length === 1 && (
        <p
          className='cursor-pointer px-4 text-sm font-thin italic text-esforce-white-100'
          onClick={() => navigate(rotasApp.reforceVisualizar(props.reforce.id))}
        >
          Ver 1 comentário
        </p>
      )}
      {comentarios.length > 1 && (
        <p
          className='cursor-pointer px-4 text-sm font-thin italic text-esforce-white-100'
          onClick={() => navigate(rotasApp.reforceVisualizar(props.reforce.id))}
        >
          Ver todos os {comentarios.length} comentários
        </p>
      )}
    </>
  )
}

interface MensagemComentarioProps {
  reforce: ReforceModel
}
