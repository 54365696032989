
import { PiPaperPlaneRightLight } from 'react-icons/pi'
import {
  CriarComentatioSchema,
  useReforceCriarComentario,
} from '@/hooks/API/reforce/useReforceCriarComentario'

import { SubmitHandler } from 'react-hook-form'
import { Form } from '../../../../../components/ui/form'
import { Input } from '../../../../../components/input'

export const _InputEmoji = (props: InputEmojiProps) => {
  const { mutate, criarComentarioContext } = useReforceCriarComentario()

  const { control, handleSubmit } = criarComentarioContext

  const onSubmit: SubmitHandler<CriarComentatioSchema> = (data) => {
    mutate({ reforceId: props.data.reforceId, comentario: data.comentario })
  }

  return (
    <>
      <div className='relative flex px-4'>
        <Form {...criarComentarioContext}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <div className='relative'>
              <Input
                control={control}
                registerName='comentario'
                placeholder='comente aqui'
                type='text'
              />

              <button
                type='submit'
                className='absolute right-3 top-1.5 z-[99] flex cursor-pointer items-center justify-center rounded-full bg-esforce-black-200 p-2 transition-all duration-300 hover:scale-105 hover:bg-esforce-black-100'
              >
                <PiPaperPlaneRightLight
                  size={20}
                  className='text-esforce-green-200'
                />
              </button>
            </div>
          </form>
        </Form>
      </div>
    </>
  )
}

interface InputEmojiProps {
  data: {
    reforceId: string
  }
}
