/* eslint-disable @typescript-eslint/no-explicit-any */
import { Control } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'

import { cn } from '../../lib/utils'
import { Input as InputShandcn } from '../ui/input'

import InputMask from '@mona-health/react-input-mask'

const MASK: any = {
  DATE: '99/99/9999',
  CPF: '999.999.999-99',
  CNPJ: '99.999.999/9999-99',
  CEP: '9999-999',
  PHONE: '(99) 99999-9999',
}

interface MaskedInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  control: Control<any>
  className?: string
  disabled?: boolean
  registerName: string
  textlabel?: string
  placeholder?: string
  type: string
  mask?: 'DATE' | 'CPF' | 'CNPJ' | 'CEP' | 'PHONE' | 'NONE'
}

export const Input = ({
  disabled = false,
  className,
  registerName,
  textlabel,
  control,
  mask = 'NONE',
  ...props
}: MaskedInputProps) => {
  return (
    <FormField
      control={control}
      name={registerName}
      render={({ field: { onChange, value, onBlur, ...rest } }) => (
        <FormItem className='font-archivo'>
          <FormLabel className='mb-2 text-sm font-normal leading-5 text-esforce-white-100'>
            {textlabel}
          </FormLabel>
          <FormControl>
            <InputMask
              disabled={disabled}
              mask={MASK[mask]}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              className={cn(
                'bg-esforce-black-30 h-12 w-full rounded-xl bg-esforce-black-350 text-sm text-esforce-white-100',
                className,
              )}
              {...rest}
            >
              <InputShandcn {...props} />
            </InputMask>
          </FormControl>
          <FormMessage className='text-sm font-normal italic text-red-400' />
        </FormItem>
      )}
    />
  )
}
