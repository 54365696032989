import { _Avatar } from './Avatar'


import { DateBR } from '@/utilitários/DateBR'
import moment from 'moment'
import Container from '../../../../../components/container'

export const _Header = (props: HeaderProps) => (
  <Container className='flex-row items-center justify-between px-4'>
    <div className='flex items-center gap-1'>
      <_Avatar
        nome={props.data.alunoNome as string}
        src={props.data.alunoAvatarUrl as string}
      />
      <div className='flex flex-col gap-1'>
        <p className='text-sm font-semibold italic text-esforce-green-200'>
          {props.data.alunoNome}
        </p>
        <p className='text-xs font-thin italic text-esforce-green-200'>
          {props.data.instituicaoNome}
        </p>
      </div>
    </div>

    <span className='text-[12px] font-bold text-esforce-green-100'>
      {moment(props.data.criadoEm).from(DateBR.dataNova())}
    </span>
  </Container>
)

interface HeaderProps {
  data: {
    alunoNome: string
    alunoAvatarUrl: string
    instituicaoNome: string
    criadoEm?: Date
  }
}
