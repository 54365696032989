import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Form } from '@/components/ui/form'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'

import { rotasApp } from '@/configurações/rotasApp'
import { useAlunoBuscarPorId } from '@/hooks/API/aluno/useAlunoBuscarPorId'
import {
  AlunoEditarSchema,
  useAlunoEditar,
} from '@/hooks/API/aluno/useAlunoEditar'
import { UseActiveUser } from '@/store/user.store'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { UseModalUi } from '@/components/modal-ui/UseModalUi'
import Container from '@/components/container'

import { Label } from '@/components/ui/label'
import { Input } from '@/components/input'


import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-react'
import ProfileCard from '../../../components/profile-card'

export const EditarPerfilPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Editar perfil',
    isBotãoEsquerdo: true,
  })

  const { UiModal, onOpen } = UseModalUi()
  const { userActive } = UseActiveUser()
  const {
    alunoEditarContext,
    alunoEditar,
    alunoEditarIsLoading,
    alunoEditarStatus,
  } = useAlunoEditar()

  const { setValue, watch, control, handleSubmit } = alunoEditarContext

  const navigate = useNavigate()

  const { aluno } = useAlunoBuscarPorId({
    alunoId: userActive?.Perfil?.alunoId,
  })
  const [isEdição, setIsEdição] = useState<boolean>(false)

  // useEffect(() => {
  //   const ediçãoEmail = watch('email') === aluno?.email
  //   const ediçãoCelular = watch('celular') === aluno?.celular
  //   const updateFoto = watch('updateFoto')
  //   if (ediçãoEmail && ediçãoCelular && !updateFoto) setIsEdição(false)
  //   else setIsEdição(true)
  // }, [watch()])

  useEffect(() => {
    const ediçãoEmail = watch('email') === aluno?.email
    const ediçãoCelular = watch('celular') === aluno?.celular
    const ediçãoNomeResponsavel =
      watch('nomeResponsavel') === aluno?.Responsavel?.nome
    const ediçãoEmailREsponsavel =
      watch('emailResponsavel') === aluno?.Responsavel?.email
    const ediçãoCPFResponsavel =
      watch('cpfResponsavel') === aluno?.Responsavel?.cpf
    const ediçãoTelefoneResponsavel =
      watch('telefoneResponsavel') === aluno?.Responsavel?.telefone

    const updateFoto = watch('updateFoto')
    if (
      ediçãoEmail &&
      ediçãoCelular &&
      !updateFoto &&
      ediçãoNomeResponsavel &&
      ediçãoEmailREsponsavel &&
      ediçãoCPFResponsavel &&
      ediçãoTelefoneResponsavel
    )
      setIsEdição(false)
    else setIsEdição(true)
  }, [watch()])

  // const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
  //   const reader = new FileReader()
  //   const file = event.target.files?.[0]
  //   setValue('avatar', file)
  //   setValue('deletarFoto', false)
  //   setValue('updateFoto', true)
  //   reader.onloadend = () => {
  //     setValue('avatarUrl', reader.result as string)
  //   }

  //   if (file) {
  //     reader.readAsDataURL(file)
  //   }
  // }

  // const lidaComRemoverFoto = () => {
  //   setValue('avatarUrl', '')
  //   setValue('avatar', '')
  //   setValue('deletarFoto', true)
  //   setValue('updateFoto', true)
  // }

  // const avatarWatch = watch('avatarUrl')
  // const AvatarRender = useCallback(() => {
  //   const src = avatarWatch?.includes('base64')
  //     ? avatarWatch
  //     : avatarWatch
  //       ? configuraçõesApi.baseArquivo(avatarWatch)
  //       : ''

  //   return <AvatarUsuárioAtivo src={src} className='cursor-pointer' />
  // }, [avatarWatch])

  useEffect(() => {
    if (!aluno) return
    setValue('id', aluno.id)
    setValue('matricula', aluno.matricula)
    setValue('nome', aluno.nome)
    setValue('cpf', aluno.cpf)
    setValue('email', aluno.email ? aluno.email : '')
    setValue('avatarUrl', aluno.avatarUrl)
    setValue('celular', aluno.celular ? aluno.celular : '')
    setValue('idade', aluno.idade)

    if (!aluno.Responsavel) return
    setValue('nomeResponsavel', aluno.Responsavel.nome || '')
    setValue('emailResponsavel', aluno.Responsavel.email || '')
    setValue('cpfResponsavel', aluno.Responsavel.cpf || '')
    setValue('telefoneResponsavel', aluno.Responsavel.telefone || '')
  }, [JSON.stringify(aluno)])

  const onSubmit: SubmitHandler<AlunoEditarSchema> = (data) => {
 
    alunoEditar(data)
  }

  useEffect(() => {
    if (alunoEditarStatus === 'success') onOpen()
  }, [alunoEditarStatus])

  return (
    <>
      <UiModal
        callbackBotão={() => navigate(rotasApp.meuPerfil)}
        textoBotão='Entendido'
        descrição='Todos os ajustes foram feitos com sucesso!'
        título='Alterações salvas com sucesso!'
      />
      <ContainerAppUi>
        <Container>
          <ProfileCard />

          {/* <Box
            alignSelf='center'
            marginTop={marginUi.xl}
            position='relative'
            {...((avatarWatch === '' || avatarWatch === null) && {
              as: 'label',
              tmlFor: 'avatar',
            })}
          >
            <AvatarRender />
            <Box
              position='absolute'
              bottom={0}
              right={0}
              display='flex'
              alignItems='center'
              justifyContent='center'
              cursor='pointer'
              borderRadius='full'
              background={corFundoUi.primaria}
              border={`5px solid ${corFundoUi.primaria}`}
              onClick={lidaComRemoverFoto}
            >
              <Icon
                as={
                  avatarWatch === '' || avatarWatch === null
                    ? ImageSquare
                    : Trash
                }
                color={
                  avatarWatch === '' || avatarWatch === null
                    ? corPrimariaAtivoUi
                    : corIconesUi['lixeira-foto']
                }
              />
            </Box>
          </Box> */}

          <Form {...alunoEditarContext}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='flex w-full flex-col gap-5'
            >
              <Label className='font-dm-sans text-xl font-semibold text-esforce-white-100'>
                Meus Dados
              </Label>
              <fieldset className='my-2 flex flex-col gap-4'>
                <div hidden>
                  <Input
                    disabled
                    control={control}
                    registerName='idade'
                    textlabel='Idade'
                    placeholder=''
                    type='text'
                  />
                </div>
                <Input
                  disabled
                  control={control}
                  registerName='nome'
                  textlabel='Nome'
                  placeholder=''
                  type='text'
                />
                <Input
                  disabled
                  control={control}
                  registerName='matricula'
                  textlabel='Matrícula'
                  placeholder=''
                  type='text'
                />
                <Input
                  control={control}
                  registerName='email'
                  textlabel='E-mail'
                  placeholder=''
                  type='text'
                />
                <Input
                  disabled
                  control={control}
                  registerName='cpf'
                  textlabel='CPF'
                  placeholder=''
                  mask='CPF'
                  type='text'
                />
                <Input
                  control={control}
                  registerName='celular'
                  textlabel='Celular'
                  placeholder=''
                  mask='PHONE'
                  type='text'
                />
              </fieldset>
              {aluno && aluno.idade < 18 && (
                <>
                  <Label className='mb-4 font-dm-sans text-xl font-semibold text-esforce-white-100'>
                    Dados do responsável
                  </Label>

                  <fieldset className='my-2 flex flex-col gap-4'>
                    <Input
                      control={control}
                      registerName='nomeResponsavel'
                      textlabel='Nome do Responsável *'
                      placeholder=''
                      type='text'
                    />
                    <Input
                      control={control}
                      registerName='emailResponsavel'
                      textlabel='Email do Responsável *'
                      placeholder=''
                      type='text'
                    />
                    <Input
                      control={control}
                      registerName='cpfResponsavel'
                      textlabel='CPF do Responsável *'
                      placeholder=''
                      mask='CPF'
                      type='text'
                    />
                    <Input
                      control={control}
                      registerName='telefoneResponsavel'
                      textlabel='Celular do Responsável *'
                      placeholder=''
                      mask='PHONE'
                      type='text'
                    />
                  </fieldset>
                </>
              )}
              <Button
                size='xl'
                type='submit'
                disabled={alunoEditarIsLoading}
                className='w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
              >
                {alunoEditarIsLoading ? (
                  <>
                    <Loader2 className='animate-spin' />
                  </>
                ) : (
                  'Atualizar Dados'
                )}
              </Button>
            </form>
          </Form>
        </Container>
      </ContainerAppUi>
    </>
  )
}
