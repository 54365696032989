import { Button } from '@/components/ui/button'
import { useNavigate } from 'react-router-dom'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { usePlanoListar } from '@/hooks/API/plano/usePlanoListar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import producWithoutImage from '@/assets/produto-sem-imagem.webp'
import { SvgSemDados } from '../../../componentes/SvgSemDados'
import { Loader2 } from 'lucide-react'

export const PlanosPagina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Planos',
    isBotãoEsquerdo: true,
  })
  const { data, isLoading } = usePlanoListar()

  return (
    <ContainerAppUi className='w-full flex-col gap-3'>
      {isLoading ? (
        <div className='w-full flex items-center justify-center'>
          <Loader2 className='animate-spin' />
        </div>
      ) : data?.length ? (
        <div className='grid w-full grid-cols-[repeat(auto-fill,minmax(150px,1fr))] justify-between gap-3'>
          {data.map(({ id, nome, fotoUrl, valor }) => (
            <PlanRender
              key={id}
              nome={nome}
              urlFoto={fotoUrl}
              id={id}
              valor={valor}
            />
          ))}
        </div>
      ) : (
        <SvgSemDados />
      )}
    </ContainerAppUi>
  )
}

const PlanRender = (props: PlanRenderProps) => {
  const navigate = useNavigate()
  return (
    <div className='flex w-full flex-col gap-2'>
      <div className='relative h-auto w-full overflow-hidden pt-[75%]'>
        <img
          src={props.urlFoto === '' ? producWithoutImage : props.urlFoto}
          className='rounded-xxl absolute left-0 top-0 h-full w-full rounded-lg object-cover object-center'
          alt='imagem do plano'
        />
      </div>

      <div className='flex flex-col gap-2'>
        <p className='text-sm font-normal text-esforce-white-100'>
          {props.nome}
        </p>
        <div className='flex items-center gap-1'>
          <p className='text-base text-esforce-white-100'>R$</p>
          <div className='flex items-start'>
            <p className='text-xl font-bold text-esforce-white-100'>
              {props.valor.toFixed(2).split('.')[0]}
            </p>
            <p className='text-xl font-bold text-esforce-white-100'>
              ,{props.valor.toFixed(2).split('.')[1]}
            </p>
          </div>
        </div>
      </div>

      <Button
        size='default'
        type='submit'
        onClick={() => navigate(rotasApp.planoVisualizar(props.id))}
        className='w-full cursor-pointer rounded-xl bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100 '
      >
        Ver detalhes
      </Button>
    </div>
  )
}

interface PlanRenderProps {
  nome: string
  urlFoto?: string
  id: string
  valor: number
}
