import { useEffect, useState } from 'react'

import { UseModalConferirQuizPesquisa } from '../../../../components/modal-ui/UseModalConferirQuizPesquisa'
import { CardHome } from '@/components/card'
import { UseModalQuizPesquisa } from '@/components/modal-ui/UseModalQuizPesquisa'
import { Button } from '@/components/ui/button'

import { Autoplay, Pagination } from 'swiper/modules'

import { rotasApp } from '@/configurações/rotasApp'
import {
  ResponseBaseQuizPesquisaRecentes,
  useQuizPesquisaRecentes,
} from '@/hooks/API/quiz-pesquisa/useQuizPesquisaRecentes'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './styles.css'

export const QuizPesquisas = () => {
  const { dataQuizzesPesquisasRecentes } = useQuizPesquisaRecentes()
  const [quizPesquisaSelecionada, setQuizPesquisaSelecionada] = useState<
    ResponseBaseQuizPesquisaRecentes | undefined
  >()
  const { ConferirModal, onOpen } = UseModalConferirQuizPesquisa()
  const { AlertModal } = UseModalQuizPesquisa()

  useEffect(() => {
    if (dataQuizzesPesquisasRecentes) {
      setQuizPesquisaSelecionada(dataQuizzesPesquisasRecentes[0])
    }
  }, [dataQuizzesPesquisasRecentes])

  return (
    <>
      <ConferirModal quizPesquisaSelecionada={quizPesquisaSelecionada} />
      <AlertModal quizPesquisaSelecionada={quizPesquisaSelecionada} />
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className='quiz-visualization-carousel'
      >
        {dataQuizzesPesquisasRecentes?.map((quizPesquisa) => {
          return (
            <SwiperSlide key={quizPesquisa.id}>
              <CardHome
                link={rotasApp.quizPesquisas}
                title={quizPesquisa.tipo === 'quiz' ? 'Quiz' : 'Pesquisa'}
                className={` ${quizPesquisa.tipo === 'quiz' ? 'background-banner-quiz justify-between' : 'background-banner-pesquisa'} items-top flex items-center p-4`}
              >
                <div className='flex max-w-48 flex-col gap-1 font-dm-sans sm:gap-4'>
                  <p className='text-3xl font-bold capitalize leading-7 text-esforce-black-200'>
                    {quizPesquisa.tema}
                  </p>
                  <p className='text-base font-semibold leading-7 text-esforce-black-200'>
                    {quizPesquisa.tipo === 'quiz'
                      ? 'Novo quiz'
                      : 'Nova pesquisa'}
                  </p>

                  <Button
                    onClick={() => {
                      setQuizPesquisaSelecionada(quizPesquisa)
                      onOpen()
                    }}
                    size='lg'
                    className='mt-2 flex w-32 items-center gap-2 bg-esforce-black-900 text-sm font-semibold text-esforce-white-100 sm:w-60 md:w-44'
                  >
                    Responder
                  </Button>
                </div>
                <div
                  className='h-full'
                  hidden={quizPesquisa.tipo !== 'quiz' ? true : false}
                >
                  <div className='flex h-9 w-14 items-center justify-center rounded-lg bg-esforce-black-200/80 font-dm-sans text-xs font-medium text-esforce-white-200'>
                    +4 pts
                  </div>
                </div>
              </CardHome>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}
