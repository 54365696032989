export const styles = {
  global: () => ({
    'html, body': {
      bg: '#0a0a0a',
      color: '#fff',
      height: '100vh',
      width: '100vw',
    },

    '::-webkit-scrollbar': {
      display: 'none',
      width: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '999px',
      display: 'none',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
      display: 'none',
    },

    '& .btn-react-query-dev-tools': {
      position: 'absolute !important',
      left: '-10px !important',
      bottom: '-20px !important',
      width: '20px !important',
      opacity: '0.4 !important',
    },
  }),
}
