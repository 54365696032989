import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useMetricas = (condição?: Headers) => {
  const { data: metricas, isLoading } = useQuery({
    queryKey: ['metricas', condição],
    queryFn: async () => metricaAluno(condição),
  })

  return { metricas, isLoading }
}

async function metricaAluno(condição?: Headers): Promise<Dados> {
  const result = await api().get(rotasApi.metrica, {
    headers: { where: JSON.stringify(condição) || '{}' },
  })
  return result.data.data
}

type Materia = {
  nome: string
  media: number
}

type Dados = {
  periodo: string
  materias: Materia[]
  mediaGeral: number
}

type Headers = {
  instituicaoPeriodoId: string | undefined
}
