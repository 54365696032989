import { QuizPesquisaModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useQuizPesquisaListar = () => {
  const { data: quizPesquisaListar, isLoading } = useQuery({
    queryKey: ['quizPesquisaListar'],
    queryFn: endPoint,
  })

  return { quizPesquisaListar, isLoading }
}

async function endPoint(): Promise<ResponseQuizPesquisaListar[]> {
  const result = await api().get(rotasApi.quizPesquisaListar)
  return result.data.data
}

export interface ResponseQuizPesquisaListar extends QuizPesquisaModel {
  totalPerguntas?: number
}
