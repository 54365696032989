import React from 'react'

import { Button } from '../ui/button'

interface IModalUiProps {
  título: string
  descrição: string
  textoBotão: string
  callbackBotão: () => void
  children?: React.ReactNode
}

interface IModalUiReturn {
  UiModal: React.FC<IModalUiProps>
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const UseModalUi = (): IModalUiReturn => {
  const [isOpen, setIsOpen] = React.useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const UiModal: React.FC<IModalUiProps> = (props) => (
    <div
      className={`fixed inset-0 z-[999] flex items-center justify-center ${isOpen ? 'block' : 'hidden'}`}
    >
      <div
        className='absolute inset-0 bg-black bg-opacity-40 backdrop-blur-sm'
        onClick={onClose}
      />
      <div className='relative w-80 rounded-lg bg-esforce-black-350 p-5 text-center shadow-md'>
        <div className='flex h-full flex-col items-center justify-evenly gap-4'>
          <h2 className='font-dm-sans text-xl font-bold text-esforce-green-100'>
            {props.título}
          </h2>
          <p className='font-inter text-sm leading-6 text-esforce-white-100'>
            {props.descrição}
          </p>
          <div className='w-full'>
            <Button
              size='lg'
              className='mb-1.5 w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
              onClick={props.callbackBotão}
            >
              {props.textoBotão}
            </Button>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )

  return { UiModal, isOpen, onOpen, onClose }
}
